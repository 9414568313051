import React from 'react'; 

const TextHeading = ({ children }) => {
  return (
    <p className="
      font-medium
      sm:text-lg 
      md:text-xl
			lg:text-2xl" 
    >
      {children}
    </p>
  );
};

export default TextHeading;
