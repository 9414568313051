import React, { useContext } from 'react';
import appContext from 'contexts/AppContext';
import MenuLink from 'components/common/MenuLink';

const RegistrationMenuItem = ({ onClick }) => {
  const { currentUser } = useContext(appContext);
  const menuItem = currentUser ? (
    <></>
  ) : (
    <MenuLink name="Sign Up" to="/users/new" onClick={onClick}>Sign Up</MenuLink>
  );
  return menuItem;
};

export default RegistrationMenuItem;
