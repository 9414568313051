
const readableDate = (date) => {
  const formattedDate = new Date(date).toLocaleDateString(
    'en-gb',
    {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }
  );

  const formattedTime = new Date(date).toLocaleTimeString(
    'en-us',
    {
      hour12: true,
      hour: '2-digit',
      minute: '2-digit'
    }
  );

  return `${formattedDate}, ${formattedTime}`;
};

const readableDay = (date) => {
  const formattedDate = new Date(date).toLocaleDateString(
    'en-gb',
    {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }
  );
  return `${formattedDate}`;
};

const readableTime = (date) => {
  const formattedTime = new Date(date).toLocaleTimeString(
    'en-us',
    {
      hour12: true,
      hour: '2-digit',
      minute: '2-digit'
    }
  );

  return `${formattedTime}`;
};

const base64UrlToUint8Array = (base64UrlData) => {
  const padding = '='.repeat((4 - base64UrlData.length % 4) % 4);
  const base64 = (base64UrlData + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  const rawData = atob(base64);
  const buffer = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    buffer[i] = rawData.charCodeAt(i);
  }

  return buffer;
};

export {
  readableDate,
  readableDay,
  readableTime,
  base64UrlToUint8Array
};
