import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import appContext from 'contexts/AppContext';
import ChatButton from 'components/Chat/ChatButton';
import PaymentPopup from 'screens/bookings/PaymentPopup';
import BookingRating from 'screens/bookings/BookingRating';
import LeaveRecommendationsSegment from 'components/Profile/LeaveRecommendationsSegment';
import AddCharge from 'components/Booking/AddCharge';
import ConfirmService from 'screens/bookings//ConfirmService';
import { cancelBooking } from 'requests/bookings';
import Button from 'components/common/Button/ButtonPrimaryOutline';
import ApproveDecline from './ApproveDecline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import HelperBookingModal from 'components/Profile/HelperProfile/HelperBookingModal';

const helperRequested = (booking, helper) => {
  for (let serviceRequest of booking.service_requests) {
    if (serviceRequest.helper.id == helper.id) {
      return true;
    }
  }
  return false;
};

const BookingActions = ({ booking, updateBookingInfo }) => {
  const { currentUser, online } = useContext(appContext);
  const history = useHistory();
  const past = new Date(booking.date) < new Date();
  var TWO_HOURS = 2 * 60 * 60 * 1000; /* ms */
  const cancellable = (new Date(booking.date) - new Date()) > TWO_HOURS;

  const handleCancel = async () => {
    cancelBooking(booking.id)
      .then(() => history.push('/bookings'));
  };

  const requestIs = (type, statusOptions, requestsAllowed = false) => {
    if (!booking || !booking.status) return false;
    if(type === 'seeker' && booking.seeker_user_id !== currentUser.id) return false;
    if(type === 'helper' && (booking.user_id !== currentUser.id && (!requestsAllowed || !helperRequested(booking, currentUser)))) return false;
    if (statusOptions && !statusOptions.includes(booking.status.toLowerCase())) return false;
    return true;
  };

  return (
    <div className="flex flex-col gap-3 flex-wrap items-start mt-3">
      {requestIs('helper', ['pending'], true) && 
        <ApproveDecline booking={booking}/>
      }
      {requestIs('seeker', ['approved', 'pending', 'payment-ready']) && cancellable &&
          <Button
            small
            onClick={handleCancel}
            disabled={!online}
          >
            Cancel Request
          </Button>
      }
      {requestIs('helper', ['approved', 'payment-ready']) && cancellable &&
          <Button
            small
            onClick={handleCancel}
            disabled={!online}
          >
            Cancel Request
          </Button>
      }
      {requestIs('seeker', ['approved', 'payment-declined']) &&
          <PaymentPopup booking={booking}/>
      }
      {requestIs('seeker', ['paid', 'payment-declined']) && past && online &&
          <BookingRating updateBookingInfo={updateBookingInfo} booking={booking}/>
      }
      {requestIs('helper', ['paid']) && past &&
          <AddCharge booking={booking} updateBooking={updateBookingInfo} />
      }
      {requestIs('helper', ['payment-ready', 'paid', 'incomplete', 'payment-declined']) && past &&
          <ConfirmService updateBookingInfo={updateBookingInfo} booking={booking}/>
      }
      {booking.user_id &&
        <HelperBookingModal 
          seekerInfo={booking.seeker_user_id} 
          helperInfo={booking.user_id} 
          booking={booking}
          outlineButton
          asHelper={currentUser.id === booking.user_id}
        />
      }
      {requestIs('helper') &&
        <a href={`tel:${booking.phone_number}`}>
          <Button small>
            <FontAwesomeIcon icon={faPhone}/>Call {booking.first_name_of_seeker}
          </Button>
        </a>
      }
      {requestIs('seeker', ['paid', 'payment-declined']) && past &&
          <LeaveRecommendationsSegment id={booking.user_id} />
      }
      {(requestIs('helper') || booking.user_id) && <ChatButton person1_id={booking.user_id || currentUser.id} person2_id={booking.seeker_user_id} />}
    </div>
  );
};



export default BookingActions;
