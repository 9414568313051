import React from 'react';
import useCurrentUser from 'hooks/useCurrentUser';
import AppContext from 'contexts/AppContext';
import useOfflineTest from 'hooks/useOfflineTest';

const AppProvider = ({ children }) => {
  const [currentUser, setCurrentUser, updateImpersonation, impersonating] = useCurrentUser();
  const online = useOfflineTest();
  return (
    <AppContext.Provider value={{ online, setCurrentUser, currentUser, updateImpersonation, impersonating }} >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
