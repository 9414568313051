import React from 'react';
import HeadingThree from 'components/common/Textbox/HeadingThree';
import { Link } from 'react-router-dom';
import 'animate.css';
import ScrollAnimation from 'react-animate-on-scroll';
import useGraphRequest from 'hooks/useGraphRequest';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const query =`
{
	aboutPage(id: "4QU22ehE4qDA98VyvPcLyi")
	{ 
    title1,
    content1 {
      json
    },
    title2,
    content2 {
      json
    },
    image1 {
      url
    },
    title3,
    content3 {
      json
    },
    image2 {
      url
    },
    title4,
    content4 {
      json
    },
    image3 {
      url
    }
  }
}`;

const AboutInfo = () => {
  const { response } = useGraphRequest(query);
  const aboutPage = response ? response.aboutPage : {};
  return (
    <div className="flex flex-col text-lg">
      <div className="flex flex-col items-center gap-2 p-14">
        <HeadingThree>{aboutPage.title1}</HeadingThree>
        <p className="text-center max-w-2xl">
          {aboutPage.content1 && documentToReactComponents(aboutPage.content1.json)}
        </p>
      </div>
      <div className="bg-btn-secondary">
        <div className="max-w-5xl flex sm:flex-col items-center gap-10 m-auto p-16">
          <ScrollAnimation offset={10} animateIn="fadeInUp" className="flex flex-col gap-2 w-1/2 sm:w-full">
            <HeadingThree>{aboutPage.title2}</HeadingThree>
            <div className="flex flex-col gap-2">
              {aboutPage.content1 && documentToReactComponents(aboutPage.content2.json)}
            </div>
          </ScrollAnimation>
          <ScrollAnimation duration={2} offset={10} animateIn="fadeIn" className="w-1/2 sm:w-full">
            <img src={aboutPage.image1 && aboutPage.image1.url} className="w-full" />
          </ScrollAnimation>
        </div>
      </div>
      <div className="">
        <div className="max-w-5xl flex sm:flex-col items-center gap-10 m-auto p-16">
          <ScrollAnimation duration={2} offset={10} animateIn="fadeIn" className="w-1/2 sm:order-2">
            <img src={aboutPage.image1 && aboutPage.image2.url} className="w-full" />
          </ScrollAnimation>
          <ScrollAnimation className="flex flex-col gap-2 w-1/2 sm:w-full sm:order-1" offset={10} animateIn="fadeIn">
            <HeadingThree>{aboutPage.title3}</HeadingThree>
            <div className="flex flex-col gap-2">
              {aboutPage.content1 && documentToReactComponents(aboutPage.content3.json)}
            </div>
          </ScrollAnimation>
        </div>
      </div>
      <div>
        <div className="max-w-5xl flex sm:flex-col items-center gap-16 m-auto p-16">
          <div className="flex flex-col gap-2">
            <ScrollAnimation duration={2} offset={10} animateIn="fadeIn">
              <HeadingThree>{aboutPage.title4}</HeadingThree>
            </ScrollAnimation>
            <ScrollAnimation duration={2} offset={10} animateIn="fadeIn">
              <p className="">
                {aboutPage.content1 && documentToReactComponents(aboutPage.content4.json)}
              </p>
            </ScrollAnimation>
          </div>
          <ScrollAnimation duration={2} offset={10} animateIn="fadeIn" className="w-full flex justify-center">
            <img src={aboutPage.image1 && aboutPage.image3.url}/>
          </ScrollAnimation>
        </div>
      </div>
      <div className="bg-ui-gray-20">
        <div className="max-w-5xl flex flex-col items-center gap-4 m-auto p-16">
          <HeadingThree>Get Started Today!</HeadingThree>
          <div className="flex gap-7 w-full sm:flex-col items-center">
            <a href="tel:6047743708" className="flex-grow">
              <button className="border border-ui-gray-80 rounded-md bg-white px-12 py-4 font-medium w-full">Call us at (604) 774-3708</button>
            </a>
              or
            <Link to="/helpers" className="flex-grow">
              <button className="border border-ui-gray-80 rounded-md bg-white px-12 py-4 font-medium w-full">Click to find helpers!</button>
            </Link>
          </div>
        </div>
      </div>
      <div className="">
        <p className="text-center max-w-2xl p-16 m-auto font-medium">
          Thanks for stopping by! We look forward to welcoming you to our community. Call us at (604) 774-3708 or email us at support@choro.ca to learn more - we’d love to hear from you.
        </p>
      </div>
    </div>
  );
};

export default AboutInfo;
