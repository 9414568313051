import React from 'react';
import { Link } from 'react-router-dom';
import { HeadingThree, HeadingSix, TextBodyLg } from 'components/common/Textbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';

import { ButtonPrimaryRound } from 'components/common/Button';
import { faCheck, faLanguage, faHome, faSmile } from '@fortawesome/free-solid-svg-icons';

const ListGroup = ({ items, type, search }) => {
  if (type === 'helpers') {
    items = items.filter(item => item.charges_enabled);
  }

  const summarizeText = (text, bool = false) => {
    if (text && text.length > 100) {
      return bool ? true : text.slice(0, 100) + '...';
    }
    return bool ? false : text;
  };

  return (
    <ul className="flex flex-col">
      {items.length < 1 &&
        <div className="flex gap-2 text-base content-between items-center border border-ui-gray-40 rounded-sm p-4">
          <FontAwesomeIcon className="text-4xl" icon={faTimesCircle}/>
          We could not find anyone with that search.
        </div>
      }
      {items.map(item => (
        <li key={item.id + item.lat} className="list-group-item gap-2 flex flex-col p-4 border border-ui-gray-40 rounded-sm xs:items-center">
          <Link to={`./${type}/${item.id}`}>
            <HeadingThree>{item.first_name}</HeadingThree>
          </Link>
          <div className="flex gap-4 xs:flex-col">
            <div className="flex flex-col gap-4 w-1/3 xs:w-full items-center" >
              <img
                src={item.image}
                className="object-scale-down rounded"
                alt="..."
              />
              <Link to={{ pathname: `./${type}/${item.id}`, state: { helpers: true, search } }} >
                <ButtonPrimaryRound small={true}>
                  View My Profile
                </ButtonPrimaryRound>
              </Link>
            </div>
            <div className="flex flex-col gap-4 w-2/3 xs:w-full">
              <div className="border border-btn-primary font-medium text-xs self-start text-btn-primary border rounded-sm p-0.5 flex items-center gap-1">
                {(item.visits && item.visits > 0) ?
                  <>
                    <FontAwesomeIcon className="text-md" icon={faHome}/>
                    {item.visits} VISITS
                  </>
                  :
                  <>
                    <FontAwesomeIcon className="text-md" icon={faSmile}/>
                    NEW HELPER
                  </>
                }
              </div>
              <div className="flex gap-4 xs:flex-col">
                <div className="overflow-hidden">
                  <HeadingSix>About Me </HeadingSix>
                  <TextBodyLg>{summarizeText(item.about)}</TextBodyLg>
                  {summarizeText(item.how_can_i_help, true) &&
                    <Link to={{ pathname: `./${type}/${item.id}`, state: { helpers: true } }} className="font-medium text-btn-primary">Read More</Link>
                  }
                </div>
                <div className="overflow-hidden">
                  <HeadingSix>How Can I Help? </HeadingSix>
                  <TextBodyLg>{summarizeText(item.how_can_i_help)}</TextBodyLg>
                  {summarizeText(item.how_can_i_help, true) &&
                    <Link to={{ pathname: `./${type}/${item.id}`, state: { helpers: true } }} className="font-medium text-btn-primary">Read More</Link>
                  }
                </div>
              </div>
              {item.services && item.services.length > 0 &&
                <div className="flex gap-2 flex-wrap">
                  {item.services.map(service => (
                    <div
                      key={service.id}
                      className="flex items-center gap-1"
                    >
                      <FontAwesomeIcon className="text-btn-primary" icon={faCheck}/>
                      {service.name}
                    </div>
                  ))}
                </div>
              }
              <div className="flex items-center gap-1">
                <FontAwesomeIcon className="text-btn-primary text-xl" icon={faLanguage}/>
                English {item.languages}
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default ListGroup;
