import React from 'react';
import styled from 'styled-components';
import { Dropdown } from 'semantic-ui-react';
 
// Override styling properties from Semantic UI
const FormInput = styled.div`
  input, .dropdown {
    font-size: 1.25rem !important;
    font-weight: 400;
    line-height: 1.75rem;
    margin: none;
    &::placeholder {
      opacity: 60%;
    }
  }
`;

const SubtleInput = ({ first, label, placeholder, options, multiple, onChange, value, forwardRef }) => {
  return (
    <div first={first && first.toString()} className="flex flex-col w-1/1">
      <p className="text-xl font-medium mb-1">{label}</p>
      <FormInput>
        { options ?  
          <Dropdown 
            selection 
            placeholder={placeholder} 
            multiple={multiple} 
            options={options}
            onChange={onChange}
            value={value}
            ref={forwardRef}
            fluid
          /> :
          <input
            className="w-1/1 border text-xl border-gray-300 rounded-md px-3 py-2"
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            ref={forwardRef}
          /> }
      </FormInput>
    </div>
  );
};

export default SubtleInput;
