import React from 'react';
import styled, { css } from 'styled-components';

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 500;
  min-width: 200px;
  h2 {
    font-size: 20px;
    opacity: 85%;
    marign: 0;
    line-height: 18px;
    font-weight: 600;
  }
  p {
    line-height: 20px;
    text-align: center;
    font-size: 20px;
    opacity: 60%;
  }
  svg {
    font-size: 40px;
    margin: 20px;
    color: #33c18f;
  }
  ${(props) => props.width && css`
    width: ${props.width};
  `}
  ${(props) => props.left && css`
    align-items: flex-start;
    p {
      text-align: left;
    }
  `}
`;

const Icon = styled.img`
  height: 80px;
  width: auto;
  max-height: 100px;
  margin-bottom: 20px;
`;


const featurette = ({ icon, width, left, title, text, children }) => {
  return (
    <Flex left={left} width={width}>
      {icon && <Icon src={icon}/>}
      {children}
      <h2>{title}</h2>
      <p>{text}</p>
    </Flex>
  );
};

export default featurette;
