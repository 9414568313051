import React, { useState, useEffect, useContext } from 'react';
import { Form, Dropdown, Button, Popup, Icon, Input } from 'semantic-ui-react';
import { getUser } from 'requests/users';
import { getServices } from 'requests/services';
import { createSeekerProfile } from 'requests/seekerProfiles';
import appContext from 'contexts/AppContext';
import Content from 'components/common/Content';

const JoinSeekerForm = props => {
  const { setCurrentUser, currentUser } = useContext(appContext);
  const [data, setData] = useState({
    availability: '',
    seeking_services: []
  });
  const [services, setServices] = useState([]);
  //fetching all available service
  useEffect(() => {
    getServices()
      .then(services => {
        services = services.map(item => {
          return { key: item.id, text: item.name, value: item.id };
        });
        setServices(services);
      });
  }, []);

  const handleChange = (e, { name, value }) => {
    setData({ ...data, [name]: value });
  };

  const handleSubmit = async () => {
    const { availability, seeking_services } = data;
    createSeekerProfile({
      user_id: currentUser.id,
      availability,
      seeking_services
    })
      .then(() =>{
        return getUser(currentUser.id);
      })
      .then((user) => {
        setCurrentUser({ ...currentUser, ...user });
        props.history.push(`/seekers/${currentUser.id}`);
      });
  };

  return (
    <Content padding>
      <Form onSubmit={handleSubmit}>
        <h1>Get Help!</h1>
        <h3 className="text-muted"> Post your needs here</h3>
        <Form.Field required>
          <label>Type of help you need</label>
          <Dropdown
            name="seeking_services"
            placeholder="Type of Help"
            fluid
            multiple
            search
            selection
            value={data.seeking_services}
            onChange={handleChange}
            options={services}
          />
        </Form.Field>

        <Form.Field required>
          <label>
            Availability
            <Popup
              content="Write down when's a good time for you"
              trigger={<Icon color="grey" name="question circle outline" />}
            />
          </label>
          <Input
            name="availability"
            placeholder="Availability"
            value={data.availability}
            onChange={handleChange}
            required
          />
        </Form.Field>
        <Button primary type="submit">
          Submit
        </Button>
      </Form>
    </Content>
  );
};

export default JoinSeekerForm;
