import React from 'react';
import Content from 'components/common/Content';
import Carousel from 'components/common/Carousel';
import Container from 'components/Landing/Container';
import { ButtonPrimarySquare, ButtonPrimaryRound } from 'components/common/Button';
import { PrimaryHeading, SubHeading } from 'components/common/Textbox';
import Featurette from 'components/common/Featurette';
import signup from 'assets/images/joinhelpers/HIW-1.png';
import interview from 'assets/images/joinhelpers/HIW-2.png';
import training from 'assets/images/joinhelpers/HIW-3.png';
import schedule from 'assets/images/joinhelpers/HIW-4.png';
import helping from 'assets/images/joinhelpers/HIW-5.png';
import tasks1 from 'assets/images/joinhelpers/tasks-1.png';
import tasks2 from 'assets/images/joinhelpers/tasks-2.png';
import tasks3 from 'assets/images/joinhelpers/tasks-3.png';
import tasks4 from 'assets/images/joinhelpers/tasks-4.png';
import tasks5 from 'assets/images/joinhelpers/tasks-5.png';
import JoinInfo from './JoinInfo';
import banner from 'assets/images/joinhelpers/Banner.png';
import calendar from 'assets/images/joinhelpers/Calendar.png';
import house from 'assets/images/joinhelpers/House.png';
import heart from 'assets/images/joinhelpers/Heart.png';

const JoinHelpersInfo = () => {
  return (
    <>
      <div className="bg-background-secondary">
        <div className="flex relative sm:flex-col md:flex-col lg:flex-row items-center">
          <Container>
            <div className="flex flex-col text-center items-center lg:items-start lg:text-left max-w-4xl sm:w-1/1 md:w-1/1 lg:w-4/5">
              <PrimaryHeading >Imagine the difference you could make...</PrimaryHeading>
              <div className="sm:py-6 md:py-6 sm:w-1/1 md:w-1/1 lg:w-1/2">
                <SubHeading>
                  ...in your own community! At Choro, we strive to improve the lives of those who need help with daily tasks, build support networks, and share life stories.  Join our growing community of empathetic ambassadors now!
                </SubHeading>
              </div>
              <a href="#join-helpers">
                <ButtonPrimarySquare className="grow-0">Get Started Today</ButtonPrimarySquare>
              </a>
            </div>
          </Container>
          <img className="lg:absolute max-w-4xl sm:w-3/4 md:w-3/4 lg:w-1/2 2xl:w-2/5 left-2/5 2xl:left-1/2 bottom-0" src={banner} alt="banner" />
        </div>
      </div>
      <Content>
        <div className='flex flex-col divide-y'>
          <JoinInfo
            img={calendar}
            title="Flexible"
            subtitle="Work on your own time, doing what you want to do, make your own schedule!"
            quote={{
              text: 'I have a great experience communicating with Choro team. They are so understanding, and considerate, always ready to offer me support...',
              author: 'Xuemei',
              title: 'Choro Helper'
            }}
          />
          <JoinInfo
            right
            img={house}
            title="Local"
            subtitle="Make a difference without having to go the distance!"
            quote={{
              text: 'I didn\'t know how much I would come to love this job and the value it brought to my life that I\'ll take with me in my future endeavours...',
              author: 'Emilia',
              title: 'Choro Helper'
            }}
          />
          <JoinInfo
            img={heart}
            title="Rewarding"
            subtitle="Build goodwill, relationships, experiences – the benefits of volunteering, while receiving a recognition remuneration."
            quote={{
              text: 'When [my helper] came he was so humble, caring and kind and I just couldn\'t believe it... He renewed my faith in human kind.',
              author: 'Leila',
              title: 'Choro Client'
            }}
          />
          <div></div>
        </div>
        <div className="flex flex-col py-20 items-center">
          <PrimaryHeading>Task Opportunities</PrimaryHeading>
          <SubHeading className="max-w-lg text-center">Here are the ways you can make a difference for people in your community!</SubHeading>
          <br></br>
          <br></br>
          <div className="max-w-md border-2 p-10 border-ui-gray-40">
            <Carousel>
              <Featurette title="Companionship" icon={tasks1} text="Game of cards, conversation & sharing stories, going for a walk"/>
              <Featurette title="Errands" icon={tasks2} text="Quick grocery and prescription pickups"/>
              <Featurette title="Home Tasks" icon={tasks3} text="Laundry, meal prep, tidying, decluttering"/>
              <Featurette title="Technology" icon={tasks4} text="Help with computers, iPads, TV Remotes"/>
              <Featurette title="Odd Jobs" icon={tasks5}  text="Help with paperwork or bills, accessing government benefits online"/>
            </Carousel>
          </div>
        </div>
      </Content>
      <div className="bg-ui-gray-20">
        <Content>
          <div className="flex flex-col py-20 items-center">
            <PrimaryHeading>How It Works</PrimaryHeading>
            <div className="bg-white py-12 mt-12 flex flex-col justify-center items-center">
              <div className="flex flex-wrap justify-center gap-12">
                <Featurette title="1. Sign up" text="Fill in as much information as you can and hit submit" width={'calc(30% - 40px)'} icon={signup} />
                <Featurette title="2. Interview" text="Our staff will contact you to arrange for an online interview" width={'calc(30% - 40px)'} icon={interview} />
                <Featurette title="3. Onboard Training" text="Onboarding and training package will be forwarded to you" width={'calc(30% - 40px)'} icon={training} />
                <Featurette title="4. Set Your Schedule" text="Set up your availability and profile" width={'calc(30% - 40px)'} icon={schedule} />
                <Featurette title="5. Start Helping!" text="Receive calls from your neighbors who need help" width={'calc(30% - 40px)'} icon={helping}/>
              </div>
              <br/>
              <br/>
              <a href="#join-helpers">
                <ButtonPrimaryRound href="#join-helpers">Join Helpers Here</ButtonPrimaryRound>
              </a>
            </div>
          </div>
        </Content>
      </div>
    </>
  );
};

export default JoinHelpersInfo;
