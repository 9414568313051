import React, { useState, useEffect, useCallback, useRef } from 'react';
import { ButtonPrimarySquare } from 'components/common/Button';
import StaticSubtleInput from 'components/common/StaticSubtleInput';
import locationAutocomplete from 'services/locationAutocomplete';
import { getServices } from 'requests/services';

const SearchBar = ({ allowToggle, setToggleSmall }) => {
  const [helpOptions, setHelpOptions] = useState([]);
  const [search, setSearch] = useState({
    help: []
  });
  const locationSearchRef = useRef();
  const measuredRef = useCallback(node => {
    if (node !== null) {
      locationAutocomplete(node);
      locationSearchRef.current = node;
    }
  }, []);

  useEffect(() => {
    getServices()
      .then((services) => {
        services = services.map(item => {
          return { key: item.id, text: item.name, value: Number(item.id) };
        });
        setHelpOptions(services);
      });
  }, []);

  const setHelp= (e, { value }) => {
    if (3 < value.length) {
      value.shift();
    }
    setSearch(search => ({ ...search, help: value }));
  };

  const submitSearch = () => {
    window.location.href = `/helpers?location=${locationSearchRef.current.value}&help=${search.help}`;
  };

  useEffect(() => {
    setToggleSmall(allowToggle);
  }, [allowToggle, setToggleSmall]);
  return (
    <>
      <div className="sm:hidden bg-white flex flex-col p-8 rounded-sm border border-gray-300">
        <div className="flex-auto pb-4">
          <StaticSubtleInput label="Types of help" placeholder="What do you want help with?" options={helpOptions} large multiple value={search.help} onChange={setHelp}/>
        </div>
        <div className="flex items-end gap-8">
          <StaticSubtleInput forwardRef={measuredRef} label="Location" placeholder="Where are you?" first/>
          <ButtonPrimarySquare onClick={submitSearch}>Find&nbsp;Help&nbsp;Now!</ButtonPrimarySquare>
        </div>
      </div>
      <div className="md:hidden flex justify-center">
        <ButtonPrimarySquare onClick={submitSearch}>Find&nbsp;Help&nbsp;Now!</ButtonPrimarySquare>
      </div>
    </>
  );
};

export default SearchBar;
