import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import HeadingFour from 'components/common/Textbox/HeadingFour';
import HeadingSix from 'components/common/Textbox/HeadingSix';
import { getLinkPreview } from 'requests/links';

const Image = styled.div`
  background-image: url(${props => props.image});
  display: flex;
  background-size: cover;
  background-repeat: no-repeat;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  h2 {
    width: 250px;
  }
`;

const UpperInfo = styled.div`
  padding: 0.5rem 1rem;
  background-color: rgba(0,0,0,0.2);
  color: white;
  h4 {
    color: white;
  }
`;

const LowerInfo = styled.div`
  background-color: #CAEDE7;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 0px;
  p {
    line-height: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 0.6rem;
    color: #464646;
    font-weight: bold;
  }
`;

const Container = styled.div`
  display: inline-block;
  position: relative;
  width: 100%
`;

const Summary = styled.div`
  width: 48%;
  max-width: 500px;
  min-width: 250px;
  flex-shrink: 1;
  overflow: hidden;
  border-radius: 0.2rem;
  background-color: #CAEDE7;
  ${props => props.blank && css`
    height: 0px;
  `}
  @media only screen and (max-width: 733px) {
    width: 100%;
  }
`;

const Dummy = styled.div`
  margin-top: 50%;
`;

export default function MediaPost({ info, blank }) {
  const [linkPreview, setLinkPreview] = useState({});
  useEffect(() => {
    // pass the link directly
    if (info && info.link) {
      getLinkPreview(info.link).then((data) =>
        setLinkPreview(data)
      );
    }
  }, [info]);

  return (
    <Summary blank={blank}>
      <Container>
        <Dummy/>
        <Image image={ linkPreview.images && linkPreview.images[0].src || ''}>
          <UpperInfo>
            <a href={info && info.link}>
              <HeadingFour>{linkPreview.title && linkPreview.title.slice(0, 50)} </HeadingFour>
              <div className="xs:hidden">
                <HeadingSix>{linkPreview.description && linkPreview.description.slice(0, 150)}</HeadingSix>
              </div>
            </a>
          </UpperInfo>
          <LowerInfo>
            <p></p>
          </LowerInfo>
        </Image>
      </Container>
    </Summary>
  );
}
