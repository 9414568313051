import React from 'react';
import styled, { css } from 'styled-components';
import HeadingFour from 'components/common/Textbox/HeadingFour';
import HeadingSix from 'components/common/Textbox/HeadingSix';

const Image = styled.div`
  background-image: url(${props => props.image});
  display: flex;
  background-size: cover;
  background-repeat: no-repeat;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  h2 {
    width: 250px;
  }
  &:hover {
    transform: scale(1.1);
    div {
      padding: 0.75rem 1.5rem 0.5rem 1.5rem;
      height: auto;
    }
  }
`;

const UpperInfo = styled.div`
  padding: 0.5rem 1rem;
  background-color: rgba(0,0,0,0.2);
  color: white;
  h4 {
    color: white;
  }
`;

const LowerInfo = styled.div`
  background-color: #CAEDE7;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 0px;
  p {
    line-height: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 0.6rem;
    color: #464646;
    font-weight: bold;
  }
`;

const Container = styled.div`
  display: inline-block;
  position: relative;
  width: 100%
`;

const Summary = styled.div`
  min-width: 210px;
  flex-grow: 1;
  overflow: hidden;
  border-radius: 0.2rem;
  background-color: #CAEDE7;
  ${props => props.blank && css`
    height: 0px;
  `}
`;

const Dummy = styled.div`
  margin-top: 110%;
`;

export default function TeamMember({ info, blank }) {
  return (
    <Summary blank={blank}>
      <Container>
        <Dummy/>
        <Image image={info && info.image.url || 'https://neighborli-jc.s3.ca-central-1.amazonaws.com/development%2F60942d26-e3d8-4a54-b297-21d41bade0d7%2Fblank-avatar-300x300.png'}>
          <UpperInfo>
            <HeadingFour>{info && info.firstName} {info && info.lastName}</HeadingFour>
            <HeadingSix>{info && info.position.toUpperCase()}</HeadingSix>
          </UpperInfo>
          <LowerInfo>
            <p>{info && info.blurb}</p>
          </LowerInfo>
        </Image>
      </Container>
    </Summary>
  );
}
