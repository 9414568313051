import React, { useState, useContext } from 'react';
import { addExtraCharge } from 'requests/bookings';
import { getServices } from 'requests/services';
import { Form, Button, Card, Confirm, Message, Input, Label } from 'semantic-ui-react';
import ServiceSelector from 'components/common/ServiceSelector';
import appContext from 'contexts/AppContext';

export default function AddCharge({ booking, updateBooking }) {
  const [step, setStep] = useState(false);
  const [extraServices, setExtraServices] = useState();
  const [services, setServices] = useState();
  const [millage, setMillage] = useState();
  const [description, setDescription] = useState();
  const [showConfirm, setShowConfirm] = useState(false);
  const [error, setError] = useState();
  const [submitted, setSubmitted] = useState(false);
  const { online } = useContext(appContext);

  const start = () => {
    if (booking.services.includes('Driving')) {
      setStep('select');
    } else {
      setupServices();
    }
  };

  const setupServices = () => {
    if (!services) {
      getServices()
        .then(services => {
          setServices(services.map(item => {
            return { key: item.id, text: item.name, value: item.id };
          }));
          setStep('service');
        });
    } else {
      setStep('service');
    }
  };

  const charge = () => {
    setShowConfirm(false);
    setSubmitted(true);
    addExtraCharge(
      booking.id, 
      extraServices, 
      description, 
      millage ? 'Gas' : null,
      millage
    )
      .then(extraCharge => {
        setExtraServices();
        setStep();
        setMillage();
        updateBooking({ extra_charges: [...booking.extra_charges, extraCharge] });
        setSubmitted(false);
      })
      .catch(() => {
        setExtraServices();
        setStep();
        setMillage();
        setError('Could not create an extra charge at this time.');
        setSubmitted(false);
      });
  };

  const cancel = () => {
    setExtraServices();
    setStep();
    setMillage();
    setShowConfirm();
    setError();
  };

  const setService = (value) => {
    setMillage();
    setExtraServices(value);
  };
  return (
    <>
      { !step ? 
        <Button disabled={!online} onClick={start}>Add Extra Charge</Button>
        :
        <Card className="w-full">
          {step == 'select' &&
            <Card.Content>
              <Card.Header className="text-sm"> Is this charge for gas or an extra service?</Card.Header>
            </Card.Content>
          }
          {step == 'gas' &&
            <Card.Content>
              <Card.Header className="text-sm"> How many km did you drive?</Card.Header>
              <small>You will be compensated at 50c/km</small>
              <div className="flex justify-between mt-3">
                <Input 
                  labelPosition='right' 
                  type='number' 
                  placeholder='0'
                  value={millage}
                  onChange={(e) => setMillage(e.target.value)}
                >
                  <input />
                  <Label>km</Label>
                </Input>
              </div>
            </Card.Content>
          }
          {step == 'service' &&
            <Card.Content>
              <Card.Header className="text-sm"> What Extra Service did you provide?</Card.Header>
              <small>* Only add an extra service if the booking exeeded the orginal booking time of {booking.services.split(',').length * 0.5} hours</small>
              <div className="mt-3">
                <Form.Field >
                  <label>Select Services</label>
                  <ServiceSelector
                    name="offered_services"
                    onChange={setService} 
                    options={services} 
                  />
                </Form.Field>
              </div>
            </Card.Content>
          }
          { step == 'description' && 
            <Card.Content>
              <Card.Header className="text-sm">{ millage ? 'What was the driving for?' : 'Why is this extra charge being applied?'}</Card.Header>
              <textarea
                className="w-full mt-2 p-2" 
                name="description"
                placeholder=' ...'
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              /> 
            </Card.Content>
          }
          <Card.Content extra className="justify-end flex">
            {((step == 'gas' && millage) || (step == 'service' && (extraServices && extraServices.length > 0))) && 
              <Button onClick={() => setStep('description')} size="mini" basic color="blue">Next</Button>
            }
            {(millage || (extraServices && extraServices.length > 0)) && step === 'description' && 
              <Button onClick={() => setShowConfirm(true)} size="mini" disabled={submitted || !description || (!millage && !services)} basic color="green" className="mr-0">Charge</Button> 
            }
            {step == 'select' &&
              <>
                <Button size="mini" basic color="green" onClick={() => setStep('gas')}>Gas</Button>
                <Button size="mini" basic color="blue" onClick={setupServices}>Extra Service</Button>
              </>
            }
            <Button onClick={cancel} className="ml-2" size="mini" basic color="red">Cancel</Button>
          </Card.Content>
          <Confirm
            open={showConfirm}
            header="Add extra charge?"
            content={`${booking.name_of_client} will be charged and notified.`}
            onCancel={cancel}
            onConfirm={charge}
          />
          {error && 
            <Message negative>
              <Message.Header>{error}</Message.Header>
            </Message>
          }
        </Card>
      }
    </>
  );
}
