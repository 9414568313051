import React, { useMemo, Fragment } from 'react';
import { Accordion, Icon } from 'semantic-ui-react';
import Interweave from 'interweave';
import useAccordionIndex from 'hooks/useAccordionIndex';
import 'styles/Accordion.css';

const DynamicAccordion = ({ contentList }) => {
  const [accordionIndex, proposeAccordionIndex] = useAccordionIndex();

  const accordionContent = useMemo(() => contentList.map((content, i) => (
    <Fragment
      key={`dynamic-accordion-${content.title}-${i}`}
    >
      <Accordion.Title
        active={accordionIndex === i} 
        key={`dynamic-accordion-${content.title}-${i}`}
        onClick={() => { proposeAccordionIndex(i); }}
      >
        <div className="n-accordion-flex">
          <Icon name="dropdown" />
          <span className="n-accordion">
            {content.title}
          </span>
        </div>
      </Accordion.Title>
      <Accordion.Content 
        active={accordionIndex === i}
      >
        <Interweave content={content.text} />
      </Accordion.Content>
    </Fragment>
  )), [contentList, accordionIndex]);

  return (
    <Accordion styled fluid>
      {accordionContent}
    </Accordion>
  );
};

export default DynamicAccordion;
