import axios from 'axios';
import { saveHeaders } from 'helpers/headerHelpers';
import { handleRequestError } from './requestHandlers';

export const adminGetUsers = (application = null, filters) => {
  return axios.get('/admin/users', { params: { application, ...filters } })
    .then((res) => {
      return res.data;
    })
    .catch(res => handleRequestError(res, 'adminGetUsers'));
};

export const adminGetHelperApplications = (id) => {
  return axios.get(`/admin/helper_applications/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch(res => handleRequestError(res, 'adminGetHelperApplications'));
};

export const adminApproveHelperApplication = (id, user_id) => {
  return axios.post(`/admin/helper_applications/${id}/approve_application`, { user_id })
    .catch(res => handleRequestError(res, 'adminApproveHelperApplication'));
};

export const adminDeclineHelperApplication = (id, user_id) => {
  return axios.post(`/admin/helper_applications/${id}/decline_application`, { user_id })
    .catch(res => handleRequestError(res, 'adminDeclineHelperApplication'));
};

export const adminImpersonate = (user) => {
  return axios.post(`/admin/users/${user.id}/impersonate`,user)
    .then((res) => {
      saveHeaders(res.headers);
      return res.data;
    })
    .catch(res => handleRequestError(res, 'adminImpersonate'));
};
