import React from 'react';

const CarouselButton = ({ children, onClick, isLeft }) => {
  const baseClass = 'absolute text-4xl bg-opacity-50 bg-gray-50 rounded-full w-12 h-12 z-50 inset-y-5/11';

  return (
    <button 
      onClick={onClick}
      className={ isLeft ? baseClass + ' left-4' : baseClass + ' right-4'}
    >
      {children}
    </button>
  );
};

export default CarouselButton;
