import { useState, useEffect } from 'react';
import { getUser } from 'requests/users';
import { getHelperProfile } from 'requests/helperProfiles';

/**
 * Reusable Hook to fetch helper profile data
 *  
 * @param {*} helperId the id of the helper to fetch
 * @returns the helper profile state
 */
const useHelperProfile = (helperId) => {
  const baseHelperProfile = {
    id: '',
    image: '',
    first_name: '',
    last_name: '',
    lng: null,
    lat: null,
    address: '',
    services: [],
    availability: '',
    about: '',
    how_can_i_help: '',
    hobbies: '',
    languages: ''
  };

  const [helperProfile, setHelperProfile] = useState(baseHelperProfile);
  
  useEffect(() => {
    getUser(helperId)
      .then(userRes => {
        setHelperProfile({
          id: userRes.id,
          image: userRes.image, 
          first_name: userRes.first_name, 
          last_name: userRes.last_name, 
          lat: userRes.lat, 
          lng: userRes.lng,
          reviews: userRes.reviews,
          address: userRes.address
        });
        return getHelperProfile(helperId);
      })
      .then(helperRes => {
        setHelperProfile(helperProfile => {
          return {
            ...helperProfile, 
            services: helperRes.services, 
            availability: helperRes.availability, 
            about: helperRes.about, 
            how_can_i_help: helperRes.how_can_i_help, 
            hobbies: helperRes.hobbies, 
            languages: helperRes.languages,
            visits: helperRes.visits
          };
        });
      })
      .catch(() => {
        setHelperProfile(baseHelperProfile);
      });
  }, []);

  return [helperProfile];
};

export default useHelperProfile;
