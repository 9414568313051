import React from 'react';
import { Route } from 'react-router-dom';
import EditProfileForm from 'components/forms/EditProfileForm';
import Profile from 'components/Profile';
import UserRoute from 'components/Routes/UserRoute';
import HelperProfile from 'components/Profile/HelperProfile';

const routes = [     
  <Route key="user-profile" path="/users/:id/edit" ><EditProfileForm/></Route>,
  <Route key="helper-profile" path={'/helpers/:id'} goBack ><HelperProfile/></Route>,
  <UserRoute key="helper-profile" path={'/seekers/:id'} goBack ><Profile/></UserRoute>,
  <UserRoute key="helper-profile" path={'/users/:id'} goBack><Profile/></UserRoute>
];

export default routes;
