import React from 'react';
import { SecondaryHeading } from 'components/common/Textbox';
import Container from 'components/Landing/Container';
import Feature from 'components/Landing/Feature';

import trusted from 'assets/icons/trusted-icon.png';
import local from 'assets/icons/local-icon.png';
import affordable from 'assets/icons/affordable-icon.png';

const Why = () => {
  return (
    <Container>
      {/* TODO refactor to CenteredCallout */}
      <div className="text-center sm:my-12 md:my-24">
        <SecondaryHeading>
          Why Choro?
        </SecondaryHeading>
      </div>
      <div className="flex justify-between mx-auto sm:flex-col sm:w-1/1 md:gap-16 lg:gap-24 xl:gap-32">
        <Feature 
          icon={trusted}
          title="Trusted" 
          text={<span>Our Helpers undergo <b>thorough background checks, interviews, and training</b>. All Helpers are onboarded with your care in mind.</span>}
          isLeftMobile={true}
        />
        <div className="sm:my-4 md:hidden" />
        <Feature
          icon={local}
          title="Local" 
          text={<span>We are your neighbours! Have peace of mind knowing you and your loved ones have a <b>compassionate support network</b> nearby.</span>}
          isLeftMobile={false}
        />
        <div className="sm:my-4 md:hidden" />
        <Feature
          icon={affordable}
          title="Affordable" 
          text={<span>To keep living at home affordable, and possible, we offer <b>value-based pricing</b>. Pay only for the tasks you need starting at <b>$25.99</b> per visit.</span>}
          isLeftMobile={true}
        />
      </div>
    </Container>
  );
};

export default Why;
