import React, { useState, useEffect, useContext } from 'react';
import appContext from 'contexts/AppContext';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { Grid, Image, Segment } from 'semantic-ui-react';
import ServicesSegment from './ServicesSegment';
import ProfileCard from './ProfileCard';
import RecommendationsSegment from './RecommendationsSegment';
import AvailabilitySegment from './AvailabilitySegment';
import Content from 'components/common/Content';
import ChatButton from 'components/Chat/ChatButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import SubTitleCard from 'components/common/SubTitleCard';
import { getUser } from 'requests/users';
import Impersonate from 'components/Admin/Impersonate';
import { getHelperProfile } from 'requests/helperProfiles';
import { getSeekerProfile } from 'requests/seekerProfiles';
import { ButtonPrimarySquare } from 'components/common/Button';
import HelperBookingModal from './HelperProfile/HelperBookingModal';

const Profile = () => {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const { currentUser } = useContext(appContext);
  const initialData = {
    id: '',
    image: '',
    first_name: '',
    last_name: '',
    description: '',
    lng: null,
    lat: null,
    address: '',
    reviews: [],
    services: [],
    availability: ''
  };
  const { id } = params;
  const [data, setData] = useState(initialData);
  let type = location.pathname.slice(1, 7);
  if (type !== 'helper' && type !== 'seeker') {
    type = 'user';
  }
  const key = process.env.REACT_APP_GOOGLE_MAP_KEY;

  //on page load, fetch user data
  useEffect(() => {
    getUser(id)
      .then(user => {
        setData(user);
        if (type === 'helper' || user.is_helper) {
          return getHelperProfile(id);
        } else if (type === 'seeker') {
          return getSeekerProfile(id);
        }
        return {};
      })
      .then(profile => {
        const { services, availability, hide_profile, about, how_can_i_help, hobbies, languages } = profile;
        setData(data => {
          return { ...data, services, availability, hide_profile, about, how_can_i_help, hobbies, languages };
        });
      });
  }, [id, type]);

  return (
    <Content padding>
      {location.state && location.state.helpers && 
        <div>
          <ButtonPrimarySquare 
            onClick={() =>  history.goBack()}
            icon="left"
          >
            Back to Helpers
          </ButtonPrimarySquare>
        </div>
      }
      <SubTitleCard>{data.first_name}</SubTitleCard>
      <Grid stackable columns={2}>
        <Grid.Row>
          <Grid.Column width={6}>
            <ProfileCard
              data={data}
              type={type}
            />
          </Grid.Column>
          <Grid.Column width={6}>
            <Segment>
              <h6>{`${data.first_name}'s Neighborhood:`}</h6>
              <Image
                src={`https://maps.googleapis.com/maps/api/staticmap?center=${data.lat},${data.lng}&zoom=15&size=900x900&maptype=roadmap&key=${key}`}
                alt="Location"
              />
            </Segment>
            <AvailabilitySegment availability={data.availability} type={type} />
            {parseInt(currentUser && currentUser.id) !== parseInt(id) && 
              <Segment style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                {data.helper_profile &&
                  <HelperBookingModal helperInfo={data} seekerInfo={currentUser} /> 
                }
                <ChatButton person1_id={currentUser && currentUser.id} person2_id={data.id} />
                {currentUser && currentUser.type === 'Admin' &&
                  <Impersonate user={data} />
                }
              </Segment>
            }
            {data.admin_notes && 
              <Segment>
                <h6>Notes:</h6>
                {data.admin_notes}
              </Segment>
            }
            {data.hide_profile && 
              <Segment>
                <h6>Profile Hidden <FontAwesomeIcon icon={faEyeSlash}/></h6>
              </Segment>
            }
          </Grid.Column>
          <Grid.Column width={4}>
            <ServicesSegment services={data.services} type={type} />
            <RecommendationsSegment data={data} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Content>
  );
};

export default Profile;
