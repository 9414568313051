import React from 'react';
import { Form, Button, Input } from 'semantic-ui-react';

const VolunteerExpForm = props => {
  return (
    <Form.Field>
      <label htmlFor="">Volunteer Experience</label>
      <Form.Group>
        <Input
          name="organization"
          placeholder="Organization"
          value={props.experience.organization}
          onChange={(e) => {props.handleExpChange(e, 'volunteer_experience', props.num);}}
        >
        </Input>
        <Input
          name="length_of_time"
          placeholder="Length of time volunteering here"
          value={props.experience.length_of_time}
          onChange={(e) => {props.handleExpChange(e, 'volunteer_experience', props.num);}}
        >
        </Input>
        {props.length - 1 === props.num && 
        <Button 
          onClick={() => {
            props.handleAddClick('volunteer');
          }}>
          Add
        </Button>}
        {props.length > 1 &&
        <Button 
          onClick={() => {
            props.handleRemoveClick('volunteer');
          }}>
          Remove
        </Button>}
      </Form.Group>
    </Form.Field>
  );
};

export default VolunteerExpForm;
