import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Segment, Form } from 'semantic-ui-react';
import appContext from 'contexts/AppContext';
import { createUserReview, getUser } from 'requests/users';

const LeaveRecommendationsSegment = ({ id }) => {
  const { currentUser, online } = useContext(appContext);
  const [review, setReview] = useState('');
  const [data, setData] = useState({ reviews: [] });
  const [reviewSubmitted, setReviewSubmitted] = useState(false);

  useEffect(() => {
    getUser(id)
      .then(setData);
  }, [id]);

  const submitReview = e => {
    e.preventDefault();
    createUserReview(id, review)
      .then(() => {
        setReviewSubmitted(true);  
      });
  };
  const content = !reviewSubmitted ? (
    <>
      <h6>Leave a Recommendation?</h6>
      <Form style={{ height: '100%' }} onSubmit={submitReview}>
        <Form.TextArea
          required
          onChange={e => setReview(e.target.value)}
          placeholder={`Tell us more about how you know ${data.first_name}...`}
        />
        <Form.Button disabled={!online}>Submit</Form.Button>
      </Form>
    </>
  ) : (
    <h6>Thank you for submitting your review!</h6>
  );

  const segment =
    currentUser &&
    !data.reviews.map(r => r.reviewer_id).includes(currentUser.id) && currentUser.id !== Number(id) ? (
        <Segment >{content}</Segment>
      ) : (
        <span>You have previously reviewed {data.first_name}, check out your review on their <Link to={`/users/${data.id}`}>profile</Link>.</span>
      );
  return segment;
};

export default LeaveRecommendationsSegment;
