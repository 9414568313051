import React, { useState } from 'react';
import { Form } from 'semantic-ui-react';
import { checkCoupon } from 'requests/payments';

export default function PromotionCode({ setCoupon }) {
  const [couponCode, setCouponCode] = useState('');
  const [error, setError] = useState('');

  const testCoupon = () => {
    if (couponCode) {
      checkCoupon(couponCode)
        .then(promoCode => {
          if (promoCode) {
            setCoupon(promoCode.coupon);
          } else {
            setCoupon(null);
            setError('Invalid Promo Code');
          }
        })
        .catch(() => {
          setError('Invalid Promo Code');
        });
    }
  };

  const handleChange = (value) => {
    setError('');
    setCouponCode(value);
  };

  return (
    <Form.Field>
      <label>
          Promo Code
      </label>
      {error && <p className="text-red text-sm">{error}</p>}
      <Form.Input 
        name="promo_code"
        value={couponCode}
        onChange={(e, { value }) => handleChange(value)}
        onBlur={testCoupon}
      />
    </Form.Field>
  );
}
