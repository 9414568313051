import React, { useState, useEffect, useContext } from 'react';
import { Modal, Button } from 'semantic-ui-react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import StripeCardSetupForm from './StripeCardSetupForm';
import { initializePaymentMethod, setPaymentMethod } from 'requests/payments';
import { useHistory } from 'react-router';
import appContext from 'contexts/AppContext';

const PopupStyle = {
  height: 'auto',
  overflowY: 'scroll',
  maxHeight: '90vh',
  position: 'absolute',
  display: 'flex',
  top: '50%',
  left: '50%',
  'margin-right': '-50%',
  transform: 'translate(-50%, -50%)',
  overflow: 'visible'
};

function PaymentPopup({ booking }) {
  const [open, setOpen] = useState(false);
  const [stripePromise, setStripePromise] = useState();
  const [clientSecret, setClientSecret] = useState({});
  const [paymentMethods, setPaymentMethods] = useState();
  const history = useHistory();
  const { online } = useContext(appContext);

  useEffect(() => {
    setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_KEY));
  }, []);
  
  useEffect(() => {
    if (open) {
      initializePaymentMethod(booking.seeker_user_id)
        .then(data => {
          setClientSecret(data.client_secret);
          if (data.payment_methods && Array.isArray(data.payment_methods) && data.payment_methods.length > 0) {
            setPaymentMethods(data.payment_methods);
          }
        });
    }
  }, [open, booking.id]);

  const handleSuccess = (paymentMethod) => {
    setPaymentMethod(booking.id, paymentMethod)
      .then(() => {
        setTimeout(() => {
          history.push(`/bookings/${booking.id}/paid`);
        }, 1000);
      });
  };

  return (
    <Modal
      closeIcon
      style={PopupStyle}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={  (
        <Button primary basic disabled={!online}>
          Pay now
        </Button>
      )}
    >
      <Elements stripe={stripePromise}>
        <StripeCardSetupForm booking={booking} paymentMethods={paymentMethods} setPaymentMethods={setPaymentMethods} clientSecret={clientSecret} handleSuccess={handleSuccess}/>
      </Elements>
    </Modal>
  );
}

export default PaymentPopup;

