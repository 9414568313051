import React, { useState, useEffect, useContext, useCallback, useRef, useMemo } from 'react';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Content from 'components/common/Content';
import queryString from 'query-string';
import { getServices } from 'requests/services';
import { searchHelperProfiles } from 'requests/helperProfiles';
import locationAutocomplete from 'services/locationAutocomplete';
import { Dropdown, Form, Input, Ref } from 'semantic-ui-react';
import ListGroup from 'components/common/listGroup';
import useFetchLatLng from 'hooks/useFetchLatLng.jsx';
import appContext from 'contexts/AppContext';
import SubTitleCard from 'components/common/SubTitleCard.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldAlt } from '@fortawesome/free-solid-svg-icons';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import OfflineNotice from 'components/common/OfflineNotice';

const Filters = styled.div`
  top: 6.5rem;
  background-color: white;
`;

const FindHelpers = () => {
  const { currentUser, online } = useContext(appContext);
  const location = useLocation();
  const history = useHistory();
  const search = useMemo(() => queryString.parse(location.search, { arrayFormat: 'comma' }), [location.search]);
  //state data setup
  const initialData = {
    helpers: [],
    filtered_helpers: [],
    center: [49.28, -123.12],
    services: [],
    address: search.location,
    name: search.name,
    offered_services: [],
    radius: 3
  };

  const [data, setData] = useState(initialData);
  const locationSearchRef = useRef();
  const { lat, lng } = useFetchLatLng(data.address);
  const isMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const measuredRef = useCallback(node => {
    if (node !== null) {
      const input = node.querySelector('input');
      locationAutocomplete(input);
      locationSearchRef.current = input;
    }
  }, []);

  //fetch data once on page load
  useEffect(() => {
    //get list of services available
    getServices()
      .then(services => {
        services = services.map(item => {
          return { key: item.id, text: item.name, value: Number(item.id) };
        });
        const offered_services = search.help ? services.reduce((acc, service) => search.help.includes(service.text) || search.help.includes(service.value.toString()) ? [...acc, service.value] : acc, []) : [];
        setData(data => {
          return {
            ...data,
            services,
            offered_services
          };
        });
      });
  }, [search.help]);

  //set default map center to user location
  useEffect(() => {
    currentUser && !data.address &&
      setData(data => {
        return {
          ...data,
          address: currentUser.address.includes(',') ? currentUser.address.split(',')[1] : currentUser.address,
          center: [currentUser.lat, currentUser.lng]
        };
      });
  }, [currentUser, data.address]);

  //sends search request to backend whenever lat/lng/radius/services updates
  useEffect(() => {
    //only send search request if there's an address or selected services
    if (lat || data.offered_services.length) {
      searchHelperProfiles({
        lat,
        lng,
        radius: data.radius,
        offered_services: data.offered_services,
        name: data.name
      })
        .then(helperProfiles => {
          // ** CHANGE to selector
          helperProfiles.map((user) => {
            user.services = user.helper_profile.services;
            user.availability = user.helper_profile.availability;
            user.about = user.helper_profile.about;
            user.how_can_i_help = user.helper_profile.how_can_i_help;
            user.hobbies = user.helper_profile.hobbies;
            user.languages = user.helper_profile.languages;
            user.visits = user.helper_profile.visits;
            return user;
          });
          setData(data => {
            return {
              ...data,
              filtered_helpers: helperProfiles,
              center: lat ? [lat, lng] : data.center
            };
          });
        });
    }
  }, [lat, lng, data.radius, data.offered_services, data.name]);

  //reset list if no address or offered_services
  useEffect(() => {
    if (!lat && !data.offered_services.length) {
      setData(data => {
        return {
          ...data,
          filtered_helpers: data.helpers
        };
      });
    }
  }, [lat, data.offered_services.length]);

  const setAddress = () => {
    setTimeout(() => {
      updateSearchParams({ address: locationSearchRef.current.value });
      setData(data => {
        return { ...data, address: locationSearchRef.current.value };
      });
    }, 10);
  };

  //callback function for form changes
  const handleChange = (e, { name, value }) => {
    updateSearchParams({ [name]: value });
    setData(data => {
      return { ...data, [name]: value };
    });
  };

  const setHelp = (e, { value }) => {
    updateSearchParams({ help: value });
    setData({ ...data, offered_services: value });
  };

  const updateSearchParams = (update) => {
    const newParams = { location: data.address, help: data.offered_services };
    history.push({
      pathname: '/helpers',
      search: '?' + new URLSearchParams({ ...newParams, ...update }).toString()
    });
  };

  // const filterByName = () => {
  //   if (data.name) {
  //     return data.filtered_helpers.filter((helper) => {
  //       return helper.first_name.toLowerCase().includes(data.name.toLowerCase());
  //     });
  //   }
  //   return data.filtered_helpers;
  // };

  return (
    <Content padding>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Choro - Find helpers nearby to assist with chores and cheer</title>
        <meta
          name="description"
          content="Find helpers nearby to assist with chores and cheer"
        />
      </Helmet>
      <h1 className="font-bold text-2xl mb-10">Find helpers nearby to assist with chores and cheer</h1>
      <div className="flex gap-4 relative flex-col lg:flex-row">
        <div className="flex flex-col gap-4">
          <div className="flex flex-col lg:order-3 gap-4">
            {!online &&
              <OfflineNotice message="You need to be online to search for helpers."/>
            }
            <FAQLink/>
            {isMobile &&
              <BGCheckNotice/>
            }
          </div>
          <Filters className="p-4 pt-0 lg:sticky border border-ui-gray-40 rounded-sm z-50 lg:order-1">
            <Form className="flex flex-col">
              <SubTitleCard>Search Filters</SubTitleCard>
              <label>What Service do you need?</label>
              <Form.Field >
                <Dropdown
                  name="offered_services"
                  placeholder="Filter by type of help"
                  fluid
                  multiple
                  search
                  selection
                  disabled={!online}
                  value={data.offered_services}
                  onChange={setHelp}
                  options={data.services}
                />
              </Form.Field>
              <label>What&apos;s your address?</label>
              <Ref innerRef={measuredRef}>
                <Form.Input
                  className="pr-4"
                  action={isMobile ? null :
                    <Dropdown text={`${data.radius} KM`} button basic floating>
                      <Dropdown.Menu>
                        <Input
                          name="radius"
                          className="search-radius"
                          onChange={handleChange}
                          type="range"
                          disabled={!online}
                          min={1}
                          max={10}
                          step={1}
                          defaultValue={3}
                        />
                      </Dropdown.Menu>
                    </Dropdown>
                  }
                  name="address"
                  placeholder={search.location || 'Enter your location to search within'}
                  onChange={setAddress}
                  onBlur={setAddress}
                  icon="search"
                  disabled={!online}
                  iconPosition="left"
                />
              </Ref>
            </Form>
          </Filters>
          <Form className="flex w-full flex-col p-4 border border-ui-gray-40 rounded-sm lg:order-2">
            <label>Search Helpers by first name</label>
            <Form.Field >
              <Input
                name="name"
                onChange={handleChange}
                icon="search"
                iconPosition="left"
                disabled={!online}
                placeholder={search.name || 'Looking for someone specific?'}
              />
            </Form.Field>
          </Form>
        </div>
        <div className="flex flex-col gap-4">
          {isMobile || 
            <div className="flex gap-4 items-start xs:flex-col">
              <BGCheckNotice/>
            </div>
          }
          <ListGroup
            items={data.filtered_helpers}
            search={ { help: data.offered_services, location: locationSearchRef.current &&locationSearchRef.current.value }}
            type="helpers"
          />
        </div>
      </div>
    </Content>
  );
};

const FAQLink = () => {
  return (
    <div className="flex w-full gap-4 justify-center items-center text-base p-4 border border-ui-gray-40 rounded-sm">
      <Link to="/faqs"><FontAwesomeIcon className="text-4xl" icon={faQuestionCircle}/></Link>
      <p>Need help navigating this page? Check out our FAQ Page!</p>
    </div>
  );
};

const BGCheckNotice = () => {
  return (
    <div className="flex w-full gap-4 justify-center items-center text-base p-4 border border-ui-gray-40 rounded-sm">
      <FontAwesomeIcon className="text-4xl" icon={faShieldAlt}/>
      <p>Always have peace of mind.  All Helpers undergo a vetting process, background checks, vulnerable sector checks, and safety training.</p>
    </div>
  );
};

export default FindHelpers;
