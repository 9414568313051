import React, { useState, useContext } from 'react';
import { Button, Card } from 'semantic-ui-react';
import { readableDate } from 'helpers/general';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit as farEdit } from '@fortawesome/free-regular-svg-icons';
import { confirmBookingService } from 'requests/bookings';
import appContext from 'contexts/AppContext';

export default function BookingRating({ booking, updateBookingInfo }) {
  const [showQuestion, setShowQuestion] = useState(booking.status === 'payment-ready');
  const { online } = useContext(appContext);

  const confirmService = (complete) => {
    confirmBookingService(booking.id, complete)
      .then(status => {
        setShowQuestion(false);
        updateBookingInfo({ status });
      });
  };

  return (
    <div>
      {
        showQuestion ?
          <Card >
            <Card.Content>
              <Card.Header>
                Did you assist {booking.first_name_of_seeker}?
              </Card.Header>
            </Card.Content>
            <Card.Content extra>
              <div className='ui two buttons'>
                <Button 
                  basic 
                  disabled={!online}
                  color="green"
                  onClick={() => confirmService(true)}
                >
                  Yes
                </Button>
                <Button 
                  basic 
                  disabled={!online}
                  color='red'
                  onClick={() => confirmService(false)}
                >
                  No
                </Button>
              </div>
            </Card.Content>
          </Card>
          :
          <h5>
            You 
            {booking.status === 'incomplete' ? ' did not assist ' : ' assisted '}
            {booking.name_of_client}
              &nbsp;on {readableDate(booking.date)}
            { booking.status === 'incomplete' && <FontAwesomeIcon 
              icon={farEdit}
              size="sm"
              style={{ color: '#2185d0', cursor: 'pointer', marginLeft: '6px' }}
              onClick={() => setShowQuestion(true)}
            />}
          </h5>
      }
    </div>
  );
}
