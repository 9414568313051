import React from 'react';

const Container = ({ children }) => {
  return (
    <div className="
      sm:px-6 
      sm:my-12
      md:px-12 
      md:my-12 
      lg:px-16 
      lg:my-16 
      xl:px-24 
      xl:my-24 
      2xl:max-w-8xl 
      2xl:mx-auto"
    >
      {children}
    </div>
  );
};

export default Container;
