import React, { useEffect } from 'react';

import './styles/App.css';

import AppProvider from 'providers/AppProvider';
import NavBar from './components/NavBar';
import Footer from './components/footer';
import ScrollToTop from 'components/common/ScrollToTop';
import useMinimumContentHeight from 'hooks/usePageContentHeight';


// routes
import Screens from 'screens';

const App = () => {
  const [contentHeight, headerRef, footerRef] = useMinimumContentHeight();

  useEffect(() => {
    if ('Notification' in window) {
      Notification.requestPermission();
    }
  } , []);

  return (
    <AppProvider>
      <NavBar childRef={headerRef} />
      <div style={{ minHeight: `${contentHeight}px` }}>
        <Screens/>
      </div>
      <Footer childRef={footerRef} />
      <ScrollToTop/>
    </AppProvider>
  );
};

export default App;
