import React, { useEffect, useState, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import nlogo from '../full-logo.png';
import EditProfileDropdown from './EditProfileDropdown';
import LogInOutMenuItem from './LogInOutMenuItem';
import RegistrationMenuItem from './RegistrationMenuItem';
import MenuLink from 'components/common/MenuLink';
import appContext from 'contexts/AppContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { getUnread } from 'requests/chat';
import { Label } from 'semantic-ui-react';

const NotificationBanner = styled.div`
  width: 100%;
  text-align: center;
  color: #ffffff;
  z-index: 20;
`;

const MainNav = styled.nav`
  position: fixed;
  height: 85px;
  width: 100%;
  background-color: #ffffff;
  border-bottom: 1px solid #33c18f;
  display: flex;
  align-items:center;
  padding: 0 20px 0 20px;
  top: 0;
  z-index: 100;
`;

const Logo = styled.img`
  width: 200px;
  height: auto;
  margin-right: 20px;
  @media only screen and (max-width: 600px) {
    width: 30vw;
  }
`;

const Menu = styled.div`
  display: flex;
  width: 100%;
  @media only screen and (max-width: 800px) {
    border-bottom: 1px;
    border-style: solid;
    flex-direction: column;
    position: absolute;
    top: 85px;
    left: 0px;
    background-color: white;
    overflow: hidden;
    ${props => props.hide && css`
      height: 0;
    `}
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  ${props => (props.right ? css`margin-left: auto;` : '')}
  & > * {
    padding-left: 20px;
    padding-right: 20px
  }
  transition: all 0.3s ease-in-out 0s;
  ${props => props.hide && css`
    opacity: 0;
  `}
  @media only screen and (max-width: 800px) {
    flex-direction: column;
    margin: auto;
    opacity: 1;
  }
`;

const MenuToggle = styled.div`
  margin-left: auto;
  display: none;
  font-size: 30px;
  cursor: pointer;
  @media only screen and (max-width: 800px) {
    display: flex;
  }
`;

const LogoContainer = styled.div`
  position: relative;
`;


const NavBar = ({ childRef }) => {
  const location = useLocation();
  const { currentUser, online } = useContext(appContext);
  const [top, setTop] = useState(true);
  const [data, setData] = useState({});
  const [toggleMenu, setToggleMenu] = useState(true);
  useEffect(() => {
    if (location.pathname === '/') {
      const handleScroll = () => {
        var scrollTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
        setTop(scrollTop < 200);
      };
      window.addEventListener('scroll', handleScroll);
      return (() => window.removeEventListener('scroll', handleScroll));
    } else {
      setTop(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    //get list of conversations
    currentUser && getUnread()
      .then(isUnreadMessages => {
        setData(data => {
          return {
            ...data,
            isUnreadMessages
          };
        });
      }).catch(() => {
        console.log('could not fetch unread count');
      });
  }, [location, currentUser]);

  return (
    <div ref={childRef}> 
      <NotificationBanner className="bg-primary">
        {!online && <span><FontAwesomeIcon icon={faExclamationCircle}/> Offline</span>}
      </NotificationBanner>
      <MainNav className="border-b border-primary">
        <Link to="/"><LogoContainer><Logo src={nlogo}/></LogoContainer></Link>
        <MenuToggle className="text-primary">
          <FontAwesomeIcon onClick={()=>setToggleMenu(!toggleMenu)} icon={faBars}/>
        </MenuToggle>
        <Menu className="border-primary" hide={toggleMenu}>
          <Flex hide={!top}>
            <MenuLink to="/helpers" onClick={()=>setToggleMenu(true)}>Find Helpers</MenuLink>
            {currentUser && !data.isUnreadMessages &&
            <MenuLink to="/chat" onClick={()=>setToggleMenu(true)}>My Messages</MenuLink>}
            {currentUser && data.isUnreadMessages &&
              <MenuLink notification="true" to="/chat" onClick={()=>setToggleMenu(true)}>My Messages
                <Label className="bg-logo text-white" size={'mini'} floating>
                    !
                </Label>
              </MenuLink>
            }
            {!currentUser &&
            <>
              <MenuLink to="/about" onClick={()=>setToggleMenu(true)}>About Us</MenuLink>
              <MenuLink to="/contact" onClick={()=>setToggleMenu(true)}>Contact Us</MenuLink>
            </>}
          </Flex>
          <Flex right>
            <LogInOutMenuItem onClick={()=>setToggleMenu(true)} />
            <RegistrationMenuItem onClick={()=>setToggleMenu(true)} />
            <EditProfileDropdown onClick={()=>setToggleMenu(true)} />
          </Flex>
        </Menu>
      </MainNav>
    </div>
  );
};

export default NavBar;
