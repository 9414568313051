import React, { useState, useContext, useRef, useEffect, useCallback } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Content from 'components/common/Content';
import {
  Form,
  Message,
  Button,
  Popup,
  Icon,
  Input,
  Image,
  Ref
} from 'semantic-ui-react';
import {
  saveCurrentUser
} from 'helpers/storageHelpers';
import useFetchLatLng from 'hooks/useFetchLatLng.jsx';
import appContext from 'contexts/AppContext';
import styled from 'styled-components';
import locationAutocomplete from 'services/locationAutocomplete';
import { uploadImage } from 'requests/aws';
import { createUser } from 'requests/users';
import OfflineNotice from 'components/common/OfflineNotice';

const Title = styled.p`
  margin-top: 0;
  font-size: 40px;
  font-weight: 800;
`;

const formatAddress = (address, address2) => {
  return address2 ? `${address2}, ${address}` : address;
};

const RegistrationForm = props => {
  const { setCurrentUser, online } = useContext(appContext);
  let initialData = {
    email: '',
    password: '',
    password_confirmation: '',
    first_name: '',
    last_name: '',
    description: '',
    services: [],
    offered_services: [],
    address: '',
    terms: false,
    recieve_notifications: false,
    preview: ''
  };
  const [data, setData] = useState(initialData);
  const { lat, lng } = useFetchLatLng(data.address);
  const history = useHistory();
  const location = useLocation();
  const locationSearchRef = useRef();
  const scrollTarget = useRef();
  const measuredRef = useCallback(node => {
    if (node !== null) {
      const input = node.querySelector('input');
      locationAutocomplete(input);
      locationSearchRef.current = input;
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (location.state && location.state.scroll == 'joinHelpers') {
        scrollTarget.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 500);  
  }, [location.state]);

  const handleChange = (e, { name, value }) => {
    setData({ ...data, [name]: value });
  };

  const handleAddressChange = () => {
    setTimeout(() => {
      setData(data => {
        return { ...data, address: locationSearchRef.current.value };
      });
    }, 10);
  };

  const handleUpload = async (e, { name }) => {
    const file = e.target.files[0];
    setData({ ...data, [name]: file });
  };

  const handleSubmit = async () => {
    const {
      email,
      password,
      password_confirmation,
      first_name,
      last_name,
      offered_services,
      address,
      address2,
      preview,
      recieve_notifications
    } = data;
    //if selected file, upload to S3 and get new image url, else default image url:
    let image =
      'https://neighborli-jc.s3.ca-central-1.amazonaws.com/development%2F60942d26-e3d8-4a54-b297-21d41bade0d7%2Fblank-avatar-300x300.png';
    if (data.preview) {
      const res = await uploadImage(preview);
      const parser = new DOMParser();
      const parsed = parser.parseFromString(res, 'text/xml');
      image = parsed.getElementsByTagName('Location')[0].childNodes[0]
        .nodeValue;
    }

    //Make post request
    createUser(
      {
        email,
        password,
        password_confirmation,
        first_name,
        last_name,
        image,
        address: formatAddress(address, address2),
        recieve_notifications,
        lat,
        lng
      },
      offered_services
    )
      .then(user => {
        saveCurrentUser(user);
        setCurrentUser(user);
        if (props.joinHelpers) {
          history.push({
            pathname: '/helpers/new',
            state: {
              scroll: 'joinHelpers'
            }
          });
        } else if (location.state && location.state.from) {
          history.push(location.state.from);
        } else if (!props.noRedirect) {
          history.push('/helpers');
        }
      })
      .catch(function(error) {
        console.log(error.response);
        setData({ ...data, error: error.response.data });
      });
  };

  return (
    <Content ref={scrollTarget} padding={!props.embedded} paddingBottom={props.embedded}>
      <Form onSubmit={handleSubmit} error={data.error ? true : false}>
        {!online && <OfflineNotice message="You need to be ! to sign up."/>}
        <Title>Sign up</Title>
        {props.joinHelpers &&
          <p>After signing up as a user you will have to register as a helper.</p>
        }
        <Form.Input
          label="E-mail"
          name="email"
          disabled={!online}
          type="email"
          placeholder="E-mail"
          value={data.email}
          onChange={handleChange}
          required
        />
        <Form.Input
          label="Password"
          name="password"
          placeholder="Password"
          value={data.password}
          disabled={!online}
          type="password"
          onChange={handleChange}
          required
        />
        <Form.Input
          label="Password Confirmation"
          name="password_confirmation"
          placeholder="Password Confirmation"
          value={data.password_confirmation}
          disabled={!online}
          type="password"
          onChange={handleChange}
          required
        />
        <Form.Input
          label="First Name"
          name="first_name"
          disabled={!online}
          placeholder="First name"
          value={data.first_name}
          onChange={handleChange}
          required
        />
        <Form.Input
          label="Last Name"
          name="last_name"
          disabled={!online}
          placeholder="Last name"
          value={data.last_name}
          onChange={handleChange}
          required
        />
        <Form.Field>
          <label>Profile Image</label>
          {data.preview && (
            <Image
              src={URL.createObjectURL(data.preview)}
              size="medium"
              alt="image to upload"
            />
          )}
          <Input
            name="preview"
            disabled={!online}
            type="file"
            accept="image/*"
            onChange={handleUpload}
          />
        </Form.Field>
        <Ref innerRef={measuredRef}>
          <Form.Input
            label="Address"
            name="address"
            diabled={!online}
            placeholder="Address"
            onChange={handleAddressChange}
            onBlur={handleAddressChange}
            loading={data.address && !lat ? true : false}
            required
          />
        </Ref>
        <Form.Input
          name="address2"
          placeholder="Appartment #, building, buzzer code"
          onChange={handleChange}
          value={data.address2}
        />
        
        <Form.Checkbox
          name="terms"
          checked={data.terms}
          disabled={!online}
          onChange={() => {
            setData({ ...data, terms: !data.terms });
          }}
          label={
            <label>
              I agree to the{' '}
              <a href={'/terms-and-conditions'} target="_blank" rel="noreferrer">Terms and Conditions</a>
              <Popup
                content="Disclaimer:
                To the maximum extent permitted by applicable law, 
                we exclude all representations, warranties and conditions relating 
                to our website and the use of this website."
                trigger={<Icon color="grey" name="question circle outline" />}
              />
            </label>
          }
          required
        />
        <Form.Checkbox
          name="recieve_notifications"
          checked={data.recieve_notifications}
          disabled={!online}
          onChange={() => {
            setData({ ...data, recieve_notifications: !data.recieve_notifications });
          }}
          label={
            <label>
              I would love to hear about free services, special offers, and discounts as they are announced.  
            </label>
          }
        />
        <Message
          error
          header="Registration error"
          content={data.error}
        />

        <Button primary type="submit" disabled={!online || !data.terms || !lat}>
          Submit
        </Button>

        <p>Already have an account? <Link to={'/login'}>Log in Here</Link></p>
      </Form>
    </Content>
  );
};

export default RegistrationForm;
