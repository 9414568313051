import React from 'react';

const MobileSpecificContent = ({ children }) => {
  return (
    <div className="
      md:hidden
      "
    >
      {children}
    </div>
  );
};

export default MobileSpecificContent;
