import React from 'react';
import { SecondaryHeading, SubHeading } from 'components/common/Textbox';
import Container from 'components/Landing/Container';
import ESDC from 'assets/images/collaborators/ESDC.png';
import capu from 'assets/images/collaborators/capu.png';
import NSKWS from 'assets/images/collaborators/NSKWS.png';
import capCommunity from 'assets/images/collaborators/capilanocommunity.jpeg';


const ShoutOuts = () => {
  return (
    <Container>
      <div className="md:text-center">
        <div className="sm:pr-16">
          <SecondaryHeading>
            Our Collaborators and Contributors
          </SecondaryHeading>
        </div>
        <div className="sm:py-6 md:py-6">
          <SubHeading>
            Immense gratitude to all those who helped make this project possible.
          </SubHeading>
        </div>
      </div>
      <div className="flex justify-center sm:flex-wrap items-center md:gap-16 lg:gap-24">
        <img className="flex-none sm:w-1/2 md:w-1/5" src={capu} alt="Capilano University" />
        <img className="flex-none sm:w-1/2 md:w-1/4" src={ESDC} alt="Employment and Social Development Canada" />
        <img className="flex-none sm:w-1/2 md:w-1/5" src={NSKWS} alt="North Shore Keep Well Society" />
        <img className="flex-none sm:w-1/2 md:w-1/5" src={capCommunity} alt="Capilano Community Services" />
      </div>
    </Container>
  );
};

export default ShoutOuts;
