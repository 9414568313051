import React from 'react';
import TitleCard from 'components/common/TitleCard';
import Content from 'components/common/Content';
import ContactDetails from './ContactDetails';

const ContactInfo = () => {
  return (
    <Content padding>
      <div className="flex flex-col justify-center items-center">
        <TitleCard className="pr-0">Contact Us</TitleCard>
        <p className="text-lg text-center max-w-xl">We&apos;d love to hear from you! To find a helper, book a helper, or provide more information, we&apos;re here for you.</p>
        <ContactDetails/>
      </div>
    </Content>
  );
};

export default ContactInfo;
