import React from 'react';
import Container from 'components/Landing/Container'; 
import { PrimaryHeading, SubHeading } from 'components/common/Textbox';
import StaticSearchBar from 'components/common/StaticSearchBar';
import phoneHelp from 'assets/images/phone-help.jpg';

const Search = () => {
  return (
    <div className="bg-background-secondary">
      <div className="flex sm:flex-col-reverse md:flex-col-reverse lg:flex-row items-center">
        <Container> 
          <div className="flex-auto max-w-4xl">
            <PrimaryHeading>Local Home Help for Family</PrimaryHeading>
            <div className="sm:py-6 md:py-6">
              <SubHeading>
                Choro quickly connects you with a Helper in your neighbourhood for companionship and help with everyday tasks.
              </SubHeading>
            </div>
            <StaticSearchBar allowToggle={false} toggleSmall={false} setToggleSmall={() => {}}/>
          </div>
        </Container>
        <div className="flex-none sm:w-1/1 md:w-1/1 lg:w-2/5 2xl:w-1/4">
          <div className="relative sm:pb-1/2 md:pb-1/2 lg:pb-3/2">
            <img 
              className="absolute h-full w-full object-cover"
              src={phoneHelp}
              alt="Young woman showing a senior woman how to use an iPhone."
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Search;
