import React from 'react';
import { Switch } from 'react-router-dom';

import AboutScreens from './about';
import AuthScreens from './authentication';
import ChatScreens from './chat';
import ContactScreens from './contact';
import FaqsScreens from './faqs';
import HelpersScreens from './helpers';
import LandingScreens from './landing';
import NotFoundScreens from './notFound';
import NotAuthorizedScreens from './notAuthorized';
import PrivacyScreens from './privacy';
import CancellationPolicyScreens from './cancellationPolicy';
import ProfileScreens from './profile';
import SeekersScreens from './seekers';
import PaymentScreens from './payments';
import TermsAndConditionsScreens from './termsAndConditions';
import AdminScreens from './admin';
import BookingsScreens from './bookings';
import ApplicationSubmittedScreens from './helperApplication';
import RequestSentScreens from './requestSent';
import TeamScreens from './team';
import MediaScreens from './media';
import GiftCardScreens from './giftCards';

const Router = () => {
  return (
    <Switch>
      {
        [   
          ...AboutScreens,
          ...HelpersScreens,
          ...AuthScreens,
          ...ChatScreens,
          ...ContactScreens,
          ...FaqsScreens,
          ...LandingScreens,
          ...PrivacyScreens,
          ...CancellationPolicyScreens,
          ...SeekersScreens,
          ...ProfileScreens,
          ...TermsAndConditionsScreens,
          ...ApplicationSubmittedScreens,
          ...RequestSentScreens,
          ...AdminScreens,
          ...PaymentScreens,
          ...BookingsScreens,
          ...TeamScreens,
          ...MediaScreens,
          ...GiftCardScreens,
          ...NotAuthorizedScreens,
          ...NotFoundScreens
        ]
      }
    </Switch>
  );
};

export default Router;
