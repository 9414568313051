import React, { useContext } from 'react';
import { Image, Label, Header } from 'semantic-ui-react';
import appContext from 'contexts/AppContext';

const Conversations = ({
  conversations,
  handleClick,
  current_convo_id
}) => {
  const { currentUser } = useContext(appContext);
  const list = conversations && conversations.map((conversation) => {
    let otherUser;
    // let length = conversation.messages.length;
    if (conversation.person1_id === currentUser.id){
      otherUser = conversation.person2;
      otherUser.id = conversation.person2_id;
    } else {
      otherUser = conversation.person1;
      otherUser.id = conversation.person1_id;
    }

    return (
      <div
        className={
          `flex gap-2 
          cursor-pointer
          sm:flex-col
          items-center 
          hover:text-blue
          p-2 
          ${current_convo_id === conversation.id && 'bg-ui-gray-20'}
        `}
        onClick={() => handleClick(conversation.id)}
        key={conversation.id}
      >
        <Image
          src={otherUser.image}
          avatar
          style={{ fontSize: '25px' }}
          alt={`${otherUser.first_name}`}
        />
        <div className="flex flex-col">  
          <strong>{`${otherUser.first_name}`}</strong>
          <p className="text-ui-gray-100 break-words md:max-w-5 sm:w-20 max-h-4 overflow-y-hidden">
            { conversation.last_message }
          </p>
          {
            (conversation.unread_count > 0) && (
              <Label className="bg-logo text-white" floating style={{
                left: 0
              }}>
                !
              </Label>
            )
          }
         
        </div>
      </div>
    );
  });

  return (
    <>
      <Header className='text-lg mb-0' dividing>
        <h4>Conversations</h4>
      </Header>
      <div className="max-h-3/4 md:overflow-y-scroll md:overflow-x-hidden flex flex-col max-w-full sm:overflow-x-scroll sm:flex-row sm:divide-x md:divide-y">
        {list}
      </div>
    </>
  );
};

export default Conversations;
