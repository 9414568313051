import React from 'react';
import { Image, Modal } from 'semantic-ui-react';
import nlogo from '../full-logo.png';
import MenuLink from 'components/common/MenuLink';

const PopupFont = {
  'font-family': '\'Montserrat\', -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif'
};

function CovidPopup() {
  const [open, setOpen] = React.useState(false);

  return (
    <Modal
      closeIcon
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={
        <div className="p-2">
          <MenuLink white="true" to="#">
            COVID-19 Precautions
          </MenuLink>
        </div>
      }
    >
      <Modal.Header style={PopupFont}>Safety procedures regarding Covid 19</Modal.Header>
      <Modal.Content image scrolling>
        <Image size='medium' src={nlogo} wrapped />
        <Modal.Description>
          <p>
            We’re fully committed to providing a safe service for all clients and helpers. To protect the Choro community, we are ensuring all safety precautions and measures are being taken. Both clients and helpers must agree to the following:
          </p>
          <br></br>
          <ul className="list-disc pl-4">
            <li>
              All helpers will conduct a health and safety assessment the morning of a visit, wash or sanitize hands before entry to a home, and wear a mask or other facial covering while inside the home
            </li>
            <li>
              All clients will wear masks unless restricted by a medical condition
            </li>
            <li>
              Both clients and helpers will maintain a social distance of six feet or two metres wherever possible, stay home and cancel or reschedule bookings* if they are experiencing any kind of illness or symptoms**, and follow all applicable public health guidelines and regulations as they are updated and announced by the provincial government.
            </li>
          </ul>
          <br></br>
          <p>
            These precautions and guidelines may change as provincial health orders are announced and updated. We understand that everyone has different comfort levels, and whenever possible, we will modify our help to ensure everyone’s safety. Please discuss any concerns you may have with your helper. 
          </p>
          <br></br>
          <p>
            If you have any questions or concerns about vaccination status or any other COVID-19 related issues, please contact us at 604-774-3708 or support@choro.ca.
          </p>
          <br></br>
          <p>
            Thank you for doing your part to ensure the health and safety of our community! 
          </p>
          <br></br>
          <p>
            <small>
              *Please cancel as soon as possible. Cancellations made at least 2 hours before a booking will not be charged. 
              <br></br>
              ** Symptoms include but are not limited to fever, dry cough, sore throat, aches and pains, diarrhea, loss of taste or smell, headache, rash, difficulty breathing or shortness of breath, or chest pain or pressure.
            </small>
          </p>
          <br></br>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
}

export default CovidPopup;
