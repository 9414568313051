import React from 'react';
import { HeadingFive, HeadingSix } from 'components/common/Textbox';
import { readableDay } from 'helpers/general';

const RecommendationsSegment = ({ data }) => {
  const recommendations = data.reviews && Array.isArray(data.reviews) && data.reviews.length > 0  && data.reviews.map(r => 
    <div key={r.id} className="mt-4">
      <HeadingSix>{r.name} - {readableDay(r.date)} </HeadingSix>
      &ldquo;{r.text}&rdquo;
    </div>
  );

  return recommendations ? (
    <>
      <hr className="border-t-2 border-ui-gray-40"/>
      <div className="relative">
        <HeadingFive>Recommendations</HeadingFive>
        <span id="recommendations" className="absolute -top-24"></span>
        {recommendations}
      </div>
    </>
  ) : <></>;
};

export default RecommendationsSegment;
