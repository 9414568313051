import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import appContext from 'contexts/AppContext';
import NotAdmin from './NotAdmin';
import Content from 'components/common/Content';
import SubTitleCard from 'components/common/SubTitleCard.jsx';
import Table from 'components/common/Table';
import HelperAppllicationStatus from 'components/common/HelperApplicationStatus';
import { adminGetUsers } from 'requests/admin';
import UsersSearch from 'components/Admin/UsersSearch';

const headers = ['Name', 'Date', 'Status'];
const statusLookup = {
  pending: 1,
  approved: 2,
  declinded: 3,
  null: 1
};

const AdminDashboard = () => {
  const { currentUser } = useContext(appContext);
  const [users, setUsers] = useState([]);
  const [sort, setSort] = useState({ item: null, order: 1 });

  useEffect(() => {
    adminGetUsers(true)
      .then(users => {
        users.sort((a,b) => {
          let rank = statusLookup[a.helper_application.status] - statusLookup[b.helper_application.status];
          if (rank === 0) {
            rank = new Date(b.helper_application.created_at).setHours(0,0,0,0) - new Date(a.helper_application.created_at).setHours(0,0,0,0);
          }
          if (rank === 0) {
            if (a.first_name < b.first_name) { 
              rank = -1; 
            } else if (a.first_name > b.first_name) {
              rank = 1;
            } else if (a.last_name < b.last_name) {
              return -1;
            } else {
              return 1;
            }
          }
          return rank;
        });
        setUsers(users);
      });
  }, []);

  const handleSetSort = (item) => {
    setSort({ item, order: sort.order * -1 });
  };

  let sortedUsers = users;
  if (sort.item) {
    sortedUsers = [...users].sort((a,b) => {
      let rank;
      switch(sort.item) {
      case 'Name':
        if (a.first_name < b.first_name) { 
          rank = -1; 
        } else if (a.first_name > b.first_name) {
          rank = 1;
        } else if (a.last_name < b.last_name) {
          rank = -1;
        } else {
          rank = 1;
        }
        break;
      case 'Date':
        rank = new Date(a.helper_application.created_at).setHours(0,0,0,0) - new Date(b.helper_application.created_at).setHours(0,0,0,0);
        break;
      default:
        rank = statusLookup[a.helper_application.status] - statusLookup[b.helper_application.status];
      }
      return rank * sort.order;
    });
  }

  const rows = sortedUsers.map((user, index) => [
    (<Link key={index} style={{ color: '#33c18f' }} to={`/admin/users/${user.id}/helper_application/${user.helper_application.id}`}>
      {user.first_name} {user.last_name}
    </Link>),
    (new Date(user.helper_application.created_at)).toDateString(),
    <HelperAppllicationStatus key={index} status={user.helper_application.status}/>
  ]
  );

  if (rows.length < 1) {
    rows.push([<p key={9999}>There are currently no helper applications. Invite helpers to join <a href="/helpers/new">here</a></p>]);
  } else {
    rows.push([<p key={9999}>Invite helpers to join <a href="/helpers/new">here</a></p>]);
  }


  const Panel = 
    <>
      <UsersSearch/>
      <br></br>
      <SubTitleCard>Pending helper applications</SubTitleCard>
      <Table rows={rows} headers={headers} setSort={handleSetSort} sort={sort} />
    </>;

  return (
    <Content padding>
      {currentUser && currentUser.type === 'Admin' ?
        Panel : <NotAdmin/> }
    </Content>
  );
};

export default AdminDashboard;

