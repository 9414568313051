import React, { useState, useEffect } from 'react';
import { Form, Message, Icon } from 'semantic-ui-react';
import Button from 'components/common/Button/ButtonPrimaryOutline';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import styled from 'styled-components';
import PaymentMethod from './PaymentMethod';
import { removePaymentMethod } from 'requests/payments';
import HeadingFive from 'components/common/Textbox/HeadingFive';

const CardContainer = styled.div`
  border: 1px solid rgba(34,36,38,.15);
  padding: .67857143em 1em;
  background-color: white;
  border-radius: .28571429rem;
  .fields {
    margin: 0 !important;
  }
`;

const PaymentContainer = styled.div`
  .fields {
    margin: 0 !important;
  }
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
`;

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      margin: '5px 15px',
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '1em',
      '::placeholder': {
        color: 'rgba(0,0,0,.4)'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }
};

function StripeCardSetup({ direct, clientSecret, booking, paymentMethods, setPaymentMethods, updatePaymentMethod }) {
  const [paymentInfo, setPaymentInfo] = useState(false);
  const [showNewPayment, setShowNewPayment] = useState(paymentMethods ? false : true);
  const [savedPaymentMethod, setSavedPaymentMethod] = useState(paymentMethods ? paymentMethods[0].id : null);
  const [cardSubmitted, setCardSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({});
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (paymentMethods) {
      setSavedPaymentMethod(paymentMethods[0].id);
      updatePaymentMethod(paymentMethods[0].id);
      setShowNewPayment(false);
    }
  }, [paymentMethods]);

  const validateForm = () => {
    return !loading && (showNewPayment ? paymentInfo.billingAddress && paymentInfo.firstName && paymentInfo.lastName : savedPaymentMethod);
  };

  useEffect(() => {
    if (clientSecret && validateForm()) {
      handleSubmit();
    }
  }, [clientSecret]);

  const handleSubmit = async () => {
    setLoading(true);
    if (!stripe || !elements ) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      setMessage({ success: false, content: 'Payment could not be processed.' });
      return;
    }

    if (!showNewPayment && savedPaymentMethod) {
      setLoading(false);
      return handleSuccess(savedPaymentMethod);
    }
    let result;
    if (!clientSecret) {
      result = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
        billing_details: {
          name: `${paymentInfo.firstName} ${paymentInfo.lastName}`,
          address: paymentInfo.billingAddress
        }
      });
    } else {
      result = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: `${paymentInfo.firstName} ${paymentInfo.lastName}`,
            address: paymentInfo.billingAddress
          }
        }
      });
    }

    if (result.error) {
      // Show error to your customer (e.g., insufficient funds)
      setMessage({ success: false, content: result.error.message });
      setLoading(false);
      console.log(result.error.message);
    } else {
      // The payment has been processed!
      setMessage({
        success: true, 
        content: 'Success, payment method recieved.'
      });
      setLoading(false);
      handleSuccess(result.setupIntent ? result.setupIntent.payment_method : result.paymentMethod.id);
    }
  };

  const handleSuccess = (paymentMethod) => {
    if (!cardSubmitted) {
      updatePaymentMethod(paymentMethod, true);
      setCardSubmitted(true);
    }
  };

  const checkIfNewCard = (e) => {
    if (cardSubmitted && e.complete) {
      setCardSubmitted(false);
    }
  };

  const updateSavedPaymentSelection = (paymentMethod) => {
    updatePaymentMethod(paymentMethod);
    setSavedPaymentMethod(paymentMethod);
  };

  const checkComplete = () => {
    if (validateForm()) {
      handleSubmit();
    }
  };

  const toggleNewCard = () => {
    setShowNewPayment(!showNewPayment);
    setMessage({});
  };

  const handleRemovePaymentMethod = (method) => {
    removePaymentMethod(method.id)
      .then(() => {
        if (savedPaymentMethod === method.id) {
          setSavedPaymentMethod(null);
          setPaymentMethods(current => {
            let filtered = current.filter(currentMethod => currentMethod.id !== method.id);
            if (filtered.length < 1) {
              setShowNewPayment(true);
              return null;
            }
            return filtered;
          }
          );
        }
      });
  };

  return (
    <div className="flex flex-col gap-4">
      { paymentMethods && <div className="self-start"> <Button small onClick={toggleNewCard}>{ showNewPayment ? 'Saved Cards' : 'New Card' }</Button></div> }
      <Form className="border border-ui-gray-100 bg-ui-gray-20 p-5 rounded-md">
        <div className="flex justify-between items-center mb-4">
          <HeadingFive>{showNewPayment ? 'Credit Card' : 'Saved Cards'}</HeadingFive>
        </div>
        {
          paymentMethods && !showNewPayment ?
            <div className="flex flex-col gap-2">
              {paymentMethods.map((method, index) => <PaymentMethod key={index} method={method} selected={savedPaymentMethod} removePaymentMethod={handleRemovePaymentMethod} setPaymentMethod={updateSavedPaymentSelection}/>)}
            </div>
            :
            <PaymentContainer>
              <Form.Field required>
                <label>Card</label>
                <CardContainer>
                  <CardElement onBlur={checkComplete} onChange={checkIfNewCard} options={CARD_ELEMENT_OPTIONS} />
                </CardContainer>
              </Form.Field>
              <div className="flex sm:flex-col gap-4">
                <Form.Input 
                  label='First name'
                  required
                  className="w-full"
                  placeholder='First name' 
                  value={paymentInfo.firstName}
                  onChange={(e)=>{setPaymentInfo({ ...paymentInfo, firstName: e.target.value });}}
                  onBlur={checkComplete}
                />
                <Form.Input 
                  label='Last name' 
                  required
                  className="w-full"
                  placeholder='Last name' 
                  value={paymentInfo.lastName}
                  onChange={(e)=>{setPaymentInfo({ ...paymentInfo, lastName: e.target.value });}}
                  onBlur={checkComplete}
                />
              </div>
              <Form.Input 
                label='Billing Address' 
                required
                placeholder='Address'
                value={paymentInfo.billingAddress}
                onChange={(e)=>{setPaymentInfo({ ...paymentInfo, billingAddress: e.target.value });}}
                onBlur={checkComplete}
              />
            </PaymentContainer>
        }
      </Form>
      {
        message.content &&
        <Message attached='bottom' negative={!message.success} positive={message.success}>
          {message.success ? 
            <Icon name='check circle outline' />
            :
            <Icon name='times circle outline' />
          }
          {message.content}
        </Message>
      }
      {!direct && booking &&
        <div className="border border-ui-gray-80 text-sm p-5 rounded-md">
          Please note: All cancellations must be <span className="text-red">placed 2 hours</span> prior to booking time to avoid 
          charges By confirming to this booking, you agree to Choro’s <a className="text-blue" href="choro.ca/terms-and-conditions">Terms & Conditions</a>,&nbsp; 
          <a className="text-blue" href="choro.ca/privacy">Privacy Policy</a>, and <a className="text-blue" href="choro.ca/cancellation-policy">Cancellation Policy</a>
        </div>
      }
    </div>
  );
}

export default StripeCardSetup;

