import { useState, useEffect } from 'react';

/**
 * Reusable Hook to toggle and track the rendering of minimized versions of large
 * text bodies.
 *  
 * @param {*} textList the list of minimized texts
 * @param {*} textLimit the word count of the miniminzed text version
 * @returns array of minimized-text state and state-modifying functions
 */
const useMinimizedText = (textList, textLimit) => {
  const [isMinimizedText, setIsMinimizedText] = useState(true);
  const [minimizedText, setMinimizedText] = useState([]);
  
  const minimizeText = (longText, limit) => {
    return `${longText.split(' ').slice(0, limit).join(' ')}...`;
  };

  // Generate all shortened strings once
  useEffect(() => {
    const minimizedTextTemp = [];   

    textList.forEach(content => {
      minimizedTextTemp.push(minimizeText(content, textLimit));
    });

    setMinimizedText(minimizedTextTemp);
  }, [textLimit, textList]);



  return [isMinimizedText, minimizedText, setIsMinimizedText];
};

export default useMinimizedText;
