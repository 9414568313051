import React from 'react';

const Button = ({ onClick, isSelected }) => {
  const baseClass = 'text-5xl mx-1';

  return (
    <button
      onClick={onClick}
      className={ isSelected ? baseClass + ' text-gray-600' : baseClass + ' text-gray-400'}>
				•
    </button>
  );
};

export default Button;
