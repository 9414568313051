import React from 'react';
import { Form, Button, Input } from 'semantic-ui-react';

const ReferenceForm = props => {

  return (
    <Form.Field>
      <label htmlFor="">References</label>
      <Form.Group>
        <Input
          name="name"
          placeholder="Name of reference"
          value={props.reference.name}
          onChange={(e) => {props.handleExpChange(e, 'reference', props.num);}}
        >
        </Input>
        <Input
          name="email"
          placeholder="Email of reference"
          value={props.reference.email}
          onChange={(e) => {props.handleExpChange(e, 'reference', props.num);}}
        >
        </Input>
        <Input
          name="relation"
          placeholder="Relation to reference"
          value={props.reference.relation}
          onChange={(e) => {props.handleExpChange(e, 'reference', props.num);}}
        >
        </Input>
        {props.length - 1 === props.num && 
        <Button 
          onClick={() => {
            props.handleAddClick('reference');
          }}>
          Add
        </Button>}
        {props.length > 1 &&
        <Button 
          onClick={() => {
            props.handleRemoveClick('reference');
          }}>
          Remove
        </Button>}
      </Form.Group>
    </Form.Field>
  );
};

export default ReferenceForm;
