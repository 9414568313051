import React from 'react';
import ButtonRoot from './Shared/ButtonRoot';

const ButtonPrimarySquare = ({ className, ...rest }) => {
  return (
    <ButtonRoot 
      className={`
        ${className}
        block
        bg-btn-primary
        text-white 
        font-semibold 
        rounded 
        hover:bg-btn-primary-hover
        hover:no-underline
        hover:text-white
      `}
      {...rest}
    />
  );
};

export default ButtonPrimarySquare;
