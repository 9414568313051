import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Table from 'components/common/Table';
import BookingRequestStatus from 'components/common/BookingRequestStatus';
import { getBookings } from 'requests/bookings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleRight } from '@fortawesome/free-regular-svg-icons';
import Button from 'components/common/Button/ButtonPrimaryOutline';
import { useMediaQuery } from 'react-responsive';
import ServiceRequest from 'components/Booking/ServiceRequest';

const headers = ['Client', 'Submitted', 'Services', 'Status', ''];
const statusLookup = {
  pending: 1,
  approved: 2,
  declinded: 3,
  null: 1
};

const RequestsList = ({ open, bookingParams }) => {
  const [sort, setSort] = useState({ item: null, order: 1 });
  const [bookings, setBookings] = useState([]);
  const isMobile = useMediaQuery({ query: '(max-width: 760px)' });

  useEffect(() => {
    if(open) {
      refreshBookings();
    }
  }, []);

  const refreshBookings = () => {
    getBookings(bookingParams)
      .then(setBookings);
  };

  const handleSetSort = (item) => {
    setSort({ item, order: sort.order * -1 });
  };

  let sortedBookings = bookings;
  if (sort.item) {
    sortedBookings = [...bookings].sort((a,b) => {
      let rank;
      switch(sort.item) {
      case 'Client':
        if (a.first_name_of_seeker < b.first_name_of_seeker) { 
          rank = -1; 
        } else if (a.first_name_of_seeker > b.first_name_of_seeker) {
          rank = 1;
        }
        break;
      case 'Helper':
        if (a.first_name_of_helper < b.first_name_of_helper) { 
          rank = -1; 
        } else if (a.first_name_of_helper > b.first_name_of_helper) {
          rank = 1;
        }
        break;
      case 'Submitted':
        rank = new Date(a.created_at).setHours(0,0,0,0) - new Date(b.created_at).setHours(0,0,0,0);
        break;
      default:
        rank = statusLookup[a.helper_application.status] - statusLookup[b.helper_application.status];
      }
      return rank * sort.order;
    });
  }

  const rows = sortedBookings.map(booking => booking ? [
    (<Link key={booking.id} to={`/users/${booking.seeker_user_id}`}>
      {booking.first_name_of_seeker}
    </Link>),
    `${(new Date(booking.created_at)).toDateString()}`,
    booking.services,
    <BookingRequestStatus key={booking.id} booking={booking}/>,
    (
      <>
        <Link key={booking.id} to={`/bookings/${booking.id}`}>
          <Button small >
            View
            <FontAwesomeIcon icon={faArrowAltCircleRight}/>
          </Button>
        </Link>
      </>
    )
  ]: []);

  if (rows.length < 1) {
    rows.push([<p key={9999}>-</p>]);
  }

  return (
    <>
      {isMobile ? 
        <div className="flex flex-col gap-4">
          {bookings.map((bookingRequest, index) => <ServiceRequest key={index} booking={bookingRequest}/>)}
        </div>
        :
        <Table rows={rows} headers={headers} setSort={handleSetSort} sort={sort} />
      }
    </>
  );
};

export default RequestsList;

