import { useState, useEffect } from 'react';
import { getNeighborhood } from 'requests/google';

const useFetchNeighborhood = (address) => {
  const [neighborhood, setNeighborhood] = useState('');
  useEffect(() => {
    if (address != '') {
      getNeighborhood(address)
        .then(setNeighborhood)
        .catch(console.log);
    }
  }, [address]);
  return neighborhood;
};

export default useFetchNeighborhood;
