import { useState, useEffect } from 'react';

const filterToParams = (filters) => {
  let params = {};
  for (let filterName in filters) {
    params[filterName] = Object.entries(filters[filterName]).reduce((acc, [optionName, option]) => {
      if (option.value) {
        return [...acc, optionName];
      }
      return acc;
    }, []);
  }
  return params;
};

const getFilterDefaults = (filterDefaults, name) => {
  const cached = localStorage.getItem(`${name}-filters`);
  if (cached) {
    return JSON.parse(cached);
  } else {
    return filterDefaults;
  }
};

const useFilters = (filterDefaults, sortDefaults, paramDefaults, sortLookup, getItems, name) => {
  const [sort, setSort] = useState(sortDefaults || { item: null, direction: null });
  const [filters, setFilters] = useState(getFilterDefaults(filterDefaults, name));
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(-1);
  const [items, setItems] = useState({});
  
  useEffect(() => {
    refreshBookings();
    localStorage.setItem(`${name}-filters`, JSON.stringify(filters));
  }, [sort, filters, page]);

  const refreshBookings = () => {
    getItems({ 
      ...paramDefaults, 
      page: page, 
      order_by: sortLookup[sort.item], 
      order_direction: sort.direction,
      ...filterToParams(filters)
    })
      .then((items) => {
        if (items.length < 10) {
          setTotalPages(page);
        }
        setItems((current) => ({ ...current, [page]: items }));
      });
  };

  const handleSetSort = (item) => {
    setSort({ item, direction: sort.direction == 'asc' ?  'desc' : 'asc' });
  };
  
  return { 
    items: items[page],
    filterOptions: {
      totalPages,
      page,
      setPage,
      setFilters,
      filters,
      setSort: handleSetSort,
      sort
    }
  };
};

export default useFilters;
