import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react';
import UserProfileDropdownItem from './UserProfileDropdownItem';
import appContext from 'contexts/AppContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMask } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

const ResponsiveDropdown = styled(Dropdown)`
  text-align: center !important;
`;

const ResponsiveDropdownMenu = styled(Dropdown.Menu)`
  a {
    display: block;
    padding: 0.4rem 1rem;
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
  a:hover {
    text-decoration: none;
    background-color: #F2F2F2;
  }
  @media only screen and (max-width: 800px) {
    position: relative !important;
    border-width: 1px 0px 0px 0px !important;
    box-shadow: none !important;
    margin-top: 0.2rem !important;
    margin-bottom: 0.2rem !important;
    a {
      text-align: center;
      padding: 0px;
    }
    a:hover {
      background-color: transparent;
    }
  }
`;

const EditProfileDropdown = ({ onClick }) => {
  const { currentUser, impersonating } = useContext(appContext);
  const editDropdown = currentUser ? (
    <ResponsiveDropdown 
      item
      className="text-center"
      style={{ textAlign: 'center' }}
      text={
        <div className="opacity-60 text-black text-lg -mr-2">
          {impersonating && <FontAwesomeIcon className="mr-1" icon={faMask}/>}
          {`${currentUser.first_name} ${currentUser.last_name}`}
        </div>
      } 
      direction={'left'}
    >
      <ResponsiveDropdownMenu>
        <Link
          to={`/users/${currentUser.id}/edit`}
          onClick={onClick}
          className="text-primary hover:text-btn-primary-hover"
        >Edit Your Information</Link>
        <UserProfileDropdownItem type="Helpers" onClick={onClick}/>
        {/* <UserProfileDropdownItem type="Seekers" /> */}
        {(currentUser.type === 'Admin') && 
          <Link
            className="text-primary hover:text-btn-primary-hover"
            onClick={onClick}
            to={'/admin/dashboard'}
          >Admin Dashboard</Link>}
        {currentUser && 
          <Link
            className="text-primary hover:text-btn-primary-hover"
            onClick={onClick}
            to={'/bookings'}
          >Bookings Dashboard</Link>}
      </ResponsiveDropdownMenu>
    </ResponsiveDropdown>
  ) : (
    <></>
  );

  return editDropdown;
};

export default EditProfileDropdown;
