import axios from 'axios';
import { handleRequestError } from 'requests/requestHandlers';

export const registerForPayments = () => {
  return axios.post('/payments/register')
    .then((response) => {
      window.location.replace(response.data.onboard_url);
    })
    .catch(res => handleRequestError(res, 'registerForPayments'));
};

export const estimate = (services) => {
  return axios.post('/payments/estimate', {
    payment : {
      services
    }
  })
    .then(response => response.data.cost)
    .catch(res => handleRequestError(res, 'estimate'));
};

export const checkCoupon = (code) => {
  return axios.post('/payments/check_promo_code', {
    promo_code: code
  })
    .then(response => response.data);
};

export const checkGiftCard = (code) => {
  return axios.get(`/gift_cards/${code}`, {
    code
  })
    .then(response => response.data);
};

export const initializePaymentMethod = (user_id) => {
  return axios.post('/payments/initialize_payment_method', { payment: { user_id } })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      if (err.response.status === 400) {
        window.location.reload();
      }
      throw new Error(err);
    })
    .catch(res => handleRequestError(res, 'initializePaymentMethod'));
};

export const setPaymentMethod = (booking_id, payment_method) => {
  return axios.post('/payments/set_payment_method', { payment: { booking_id, payment_method } })
    .catch(res => handleRequestError(res, 'setPaymentMethod'));
};

export const setBatchInvoices = (value) => {
  return axios.post('/payments/bach_invoices', { batch: value })
    .catch(res => handleRequestError(res, 'setBatchInvoices'));
};

export const removePaymentMethod = (payment_method) => {
  return axios.post('/payments/remove_payment_method', { payment_method })
    .catch(res => handleRequestError(res, 'removePaymentMethod'));
};

export const purchaseGiftCard = (gift_card, payment_method) => {
  return axios.post('/gift_cards', { gift_card, payment_method })
    .then(res => {
      return res.data;
    })
    .catch(res => handleRequestError(res, 'createGiftCard'));
};

