import { useState, useEffect } from 'react';
import { getUser } from 'requests/users';

const useUser = (userInfo) => {
  const [user, setUser] = useState({});

  useEffect(() => {
    if (userInfo != null && Number.isInteger(Number(userInfo))) {
      getUser(userInfo)
        .then(setUser);
    } else if (typeof userInfo === 'object') {
      setUser(userInfo);
    }
  }, [userInfo]);
  
  return { user };
};

export default useUser;
