import React from 'react';
import ButtonRoot from './Shared/ButtonRoot';

const ButtonPrimaryRound = (props) => {
  return (
    <ButtonRoot 
      className="
        block
        bg-btn-primary
        text-white 
        font-semibold 
        rounded-full 
        hover:bg-btn-primary-hover
        hover:no-underline
        hover:text-white
      "
      {...props}
    />
  );
};

export default ButtonPrimaryRound;
