import React from 'react'; 

const SubHeading = ({ children, className }) => {
  return (
    <p className={`
        ${className}
        text-gray-500
        sm:text-lg 
        md:text-lg 
        lg:text-xl 
        xl:text-2xl
        `}
    >
      <span className="leading-relaxed">
        {children}
      </span> 
    </p>
  );
};

export default SubHeading;
