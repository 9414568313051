import { useState, useEffect } from 'react';

/**
 * Reusable Hook to control carousel indexes in real time
 *  
 * @param {*} length number of elements within the carousel 
 * @param {*} duration time in ms between carousel transitions
 * @returns array of carousel state and state-modifying functions
 */
const useCarouselIndex = (length, duration) => {
  const [carouselIndex, setCarouselIndex] = useState(0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCarouselIndex(current => (current + 1) % length);
    }, duration);
    return () => clearTimeout(timeout);
  }, [carouselIndex, duration, length]);
  
  const decrementCarouselIndex = () => {
    carouselIndex <= 0 ? setCarouselIndex(length - 1) : setCarouselIndex(carouselIndex - 1);
  };

  const incrementCarouselIndex = () => {
    carouselIndex >= length - 1 ? setCarouselIndex(0) : setCarouselIndex(carouselIndex + 1);
  };

  return [carouselIndex, setCarouselIndex, decrementCarouselIndex, incrementCarouselIndex];
};

export default useCarouselIndex;
