import React from 'react';
import useCarouselIndex from 'hooks/useCarouselIndex';
import { Text, TextHeading } from 'components/common/Textbox';
import CarouselBullet from './CarouselBullet'; 
import CarouselButton from './CarouselButton';
import Fadebox from './Fadebox';

// Control auto-scroll within the carousel 
const duration = 5000;

const ImageCarousel = ({ contentList }) => {
  const [
    carouselIndex, 
    setCarouselIndex, 
    decrementCarouselIndex,
    incrementCarouselIndex 
  ] = useCarouselIndex(contentList.length, duration);

  return (
    <div className="flex flex-col border border-gray-300 rounded-md">
      <div className="flex-none">
        <div className="relative pb-2/3">
          <CarouselButton 
            onClick={decrementCarouselIndex} 
            isLeft={true}>
            {'<'}
          </CarouselButton>
          <CarouselButton 
            onClick={incrementCarouselIndex}
            isLeft={false}>
            {'>'}
          </CarouselButton>      
          <Fadebox id={contentList[carouselIndex]._id}>
            <img 
              className="absolute h-full w-full object-cover rounded-t-md"
              src={contentList[carouselIndex].image}
              alt={contentList[carouselIndex].alt}
            />
          </Fadebox>
        </div>
      </div>
      <Fadebox id={contentList[carouselIndex]._id}>
        <div className="flex-none p-4 sm:h-36 md:h-44">      
          <TextHeading>{contentList[carouselIndex].title}</TextHeading>
          <div className="">
            <Text>{contentList[carouselIndex].description}</Text>
          </div>
        </div>
      </Fadebox>
      <div className="flex-none p-2">
        <div className="flex justify-center">
          {
            contentList.map((content, i) => {
              return <CarouselBullet key={i} onClick={() => setCarouselIndex(i)} isSelected={i === carouselIndex} />;
            })
          }
        </div>
      </div>
    </div>
  );
};

export default ImageCarousel;
