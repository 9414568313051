import React from 'react'; 

const HeadingTwo = ({ children, center }) => {
  return (
    <h2 className={`
      font-semibold 
      text-5xl
      my-0
      ${center && 'text-center'}
      `}
    >
      {children}
    </h2>
  );
};

export default HeadingTwo;
