import React from 'react';
import Container from 'components/Landing/Container';
import { ButtonSecondaryRound } from 'components/common/Button';
import { TertiaryHeading } from 'components/common/Textbox'; 

const Join = () => {
  return (
    <Container>
      <div className="flex sm:flex-col md:flex-col lg:flex-row items-center sm:gap-4 md:gap-8 lg:gap-12 xl:gap-16">
        <div className="flex-none md:w-1/1 sm:text-center md:text-center lg:text-left lg:w-1/5 xl:w-1/4">
          <TertiaryHeading>Still have questions?</TertiaryHeading>
        </div>
        <div className="flex-auto sm:w-1/1 md:w-1/2 lg:w-1/5 xl:w-3/8 mx-auto text-center">
          <ButtonSecondaryRound to="/faqs">Check out our FAQ page!</ButtonSecondaryRound>  
        </div>
        <div className="flex-auto sm:w-1/1 md:w-1/2 lg:w-1/5 xl:w-3/8 mx-auto text-center">
          <ButtonSecondaryRound to="/contact">Give us a call!</ButtonSecondaryRound>  
        </div>
      </div>
    </Container>
  );
};

export default Join;
