import React from 'react';
import { HeadingFive } from 'components/common/Textbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const ServicesList = ({ services }) => {
  const renderedServices =
    services &&
    services.map(service => (
      <div
        key={service.id}
        className="flex items-center gap-1"
      >
        <FontAwesomeIcon className="text-btn-primary" icon={faCheck}/>
        {service.name}
      </div>
    ));

  return (
    <div className="">
      <div className="mb-4 relative">
        <span id="tasks" className="absolute -top-24"></span>
        <HeadingFive>My&nbsp;Services</HeadingFive>
      </div>
      <div className="flex gap-2 flex-wrap">
        { renderedServices }
      </div>
    </div>
  );
};

export default ServicesList;
