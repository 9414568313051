import axios from 'axios';
import { saveHeaders } from 'helpers/headerHelpers';
import { setDefaultHeaders } from 'helpers/headerHelpers';
import { handleRequestError } from 'requests/requestHandlers';

export const getUser = (userId) => {
  return axios.get(`/users/${userId}`)
    .then(res => {
      saveHeaders(res.headers);
      return res.data;
    })
    .catch(res => handleRequestError(res, 'getUser'));
};

export const getMe = () => {
  setDefaultHeaders();
  return axios.get('/users/me')
    .then(res => {
      saveHeaders(res.headers);
      return res.data;
    });
};

export const createUser = (user, offered_services) => {
  return axios.post('/users', { user, offered_services })
    .then(response => {
      saveHeaders(response.headers);
      return response.data;
    });
};

export const updateUser = (user) => {
  return axios
    .put(`/users/${user.id}`, user)
    .then((res) => {
      saveHeaders(res.headers);
      return res;
    })
    .catch(res => handleRequestError(res, 'updateUser'));
};

export const createUserReview = (userId, review) => {
  return axios.post(`/users/${userId}/reviews/`, {
    review: {
      text: review
    }
  })
    .catch(res => handleRequestError(res, 'createUserReview'));
};
