import React, { useState, useContext } from 'react';
import { approveBooking, declineBooking } from 'requests/bookings';
import Button from 'components/common/Button/ButtonPrimaryOutline';
import HeadingSix from 'components/common/Textbox/HeadingSix';
import { DateTimePicker } from 'react-widgets';
import { Message } from 'semantic-ui-react';
import Moment from 'moment';
import { useHistory } from 'react-router-dom';
import appContext from 'contexts/AppContext';

export default function ApproveDecline({ booking }) {
  const { online } = useContext(appContext);
  const [requestResponse, setRequestResponse] = useState('');
  const [details, setDetails] = useState('');
  const [date, setDate] = useState();
  const [errors, setErrors] = useState({});
  const history = useHistory();
  
  const handleResponse = (type) => {
    if (type === 'approve') {
      approveBooking(booking.id, date)
        .then(() => history.push('/bookings'));
    } else {
      declineBooking(booking.id, details)
        .then(() => history.push('/bookings'));
    }
  };

  const handleDateChange = (newDate) => {
    if (Moment(newDate).isBefore(Moment())) {
      setDate(newDate);
      setErrors({ ...errors, date: 'You cannot select a date in the past' });
    } else {
      setDate(newDate);
      setErrors({ ...errors, date: null });
    }
  };

  const responseInvalid = () => {
    return requestResponse == 'approve' ? (!date || errors.date) : !details;
  };;

  return (
    <div>
      <Button
        small
        disabled={!online}
        selected={requestResponse === 'approve'}
        onClick={() => (booking.user_id ? handleResponse('approve') : setRequestResponse('approve'))}
      >
        Approve
      </Button>
      <Button
        small
        disabled={!online}
        selected={requestResponse === 'decline'}
        margin
        onClick={() => (booking.user_id ? handleResponse('decline') : setRequestResponse('decline'))}
      >
        Decline
      </Button>
      {requestResponse &&
        <div className="mt-6 w-100">
          {requestResponse === 'approve' ?
            <>
              <HeadingSix >
                When are you available?
                <br></br>
                <small>* Please ensure to select a time when {booking.first_name_of_seeker} is available</small>
              </HeadingSix>
              {errors.date &&<Message negative>
                <p>{errors.date}</p>
              </Message>}
              <DateTimePicker
                onChange={handleDateChange}
                value={date}
                name="date"
                error={errors.date}
                required
                className="mb-6"
              />
            </>
            :
            <>
              <HeadingSix >
                Why are you declining?
              </HeadingSix>
              <textarea
                className="border-2 w-full block mb-6 border-ui-gray-40 p-3"
                cols="38"
                name="details"
                value={details}
                onChange={(e) => { setDetails(e.target.value); }}
              />
            </>}
          <Button
            margin
            full
            disabled={responseInvalid()}
            onClick={() => handleResponse(requestResponse)}
          >
            Submit
          </Button>
        </div>
      }
    </div>
  );
}
