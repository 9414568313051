import axios from 'axios';
import { handleRequestError } from 'requests/requestHandlers';

// not a fan of this request should be reworked
export const getBookingId = (firstUserId, secondUserId) => {
  return axios.get('/get_booking_id', 
    { params: { user1: firstUserId, user2: secondUserId } }
  )
    .catch(res => handleRequestError(res, 'getBookingId'));
};

export const getBooking = (id) => {
  return axios.get(`/bookings/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch(res => handleRequestError(res, 'getBooking'));
};

export const createBooking = (booking) => {
  return axios.post('/bookings', booking)
    .then(res => res.data)
    .catch(res => handleRequestError(res, 'createBooking'));
};

export const updateBooking = (booking) => {
  return axios.put(`/bookings/${booking.id}`, booking)
    .then(res => res.data)
    .catch(res => handleRequestError(res, 'updateBooking'));
};

export const reviewBooking = (booking, rating) => {
  return axios.put(`/bookings/${booking.id}/review`, { review: { rating } })
    .then(res => {
      return res.data.status;
    })
    .catch(res => handleRequestError(res, 'reviewBooking'));
};

export const getBookings = (bookingParams) => {
  return axios.get('/bookings', { params: bookingParams })
    .then(res => {
      return res.data;
    })
    .catch(res => handleRequestError(res, 'getBookings'));
};

export const approveBooking = (id, date) => {
  return axios.put(`/bookings/${id}/approve_request`, { date })
    .catch(res => handleRequestError(res, 'approveBooking'));
};

export const declineBooking = (id, reason_declined) => {
  return axios.put(`/bookings/${id}/decline_request`, { reason_declined })
    .catch(res => handleRequestError(res, 'declineBooking'));
};

export const cancelBooking = (id) => {
  return axios.put(`/bookings/${id}/cancel_request`)
    .catch(res => handleRequestError(res, 'cancelBooking'));
};

export const confirmBookingService = (id, complete) => {
  return axios.put(`/bookings/${id}/confirm_service`, { complete })
    .then(res => res.data.status)
    .catch(res => handleRequestError(res, 'confirmBookingService'));
};

export const addExtraCharge = (booking_id, service_ids, description, reason, value) => {
  return axios.post(`/bookings/${booking_id}/extra_charges`, { extra_charge: { booking_id, service_ids, description, reason, value } })
    .then(res => {
      return res.data;
    })
    .catch(res => handleRequestError(res, 'addExtraCharge'));
};
