import React from 'react';
import { DesktopSpecificContent, MobileSpecificContent } from 'components/common/Layout';
import { HeadingFour, HeadingFive, TextBodyXLg } from 'components/common/Textbox';
import HelperProfileInformationSection from './HelperProfileInformationSection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLanguage } from '@fortawesome/free-solid-svg-icons';
import HelperProfileActions from './HelperProfileActions';


const HelperProfileInformation = ({ first_name, about, hobbies, languages, how_can_i_help, visits, reviews }) => {
  return (
    <div className="flex flex-col gap-6 relative" >
      <span id="about" className="absolute -top-24"></span>
      <div className="xs:hidden">
        <HelperProfileActions visits={visits} reviews={reviews}/>
      </div>
      <DesktopSpecificContent>
        <HeadingFour>About {first_name}</HeadingFour>
      </DesktopSpecificContent>
      <MobileSpecificContent>
        <HeadingFive>About {first_name}</HeadingFive>
      </MobileSpecificContent>
      <div className="text-text-gray-80">
        <DesktopSpecificContent>
          <TextBodyXLg>{about}</TextBodyXLg>
        </DesktopSpecificContent>
        <MobileSpecificContent>
          <div className="text-base leading-6">{about}</div>
        </MobileSpecificContent>
      </div>
      <HelperProfileInformationSection heading={'What I like to do for fun!'} subHeading={hobbies} />
      <div className="flex items-center gap-1">
        <HeadingFive>Languages</HeadingFive>
        <FontAwesomeIcon className="text-btn-primary text-xl" icon={faLanguage}/>
        <TextBodyXLg> English {languages}</TextBodyXLg>
      </div>
      <hr className="border-t-2 border-ui-gray-40" />
      <HelperProfileInformationSection heading={'How can I help?'} subHeading={how_can_i_help} />
      <hr className="border-t-2 border-ui-gray-40" />
    </div>
  );
};

export default HelperProfileInformation;
