import React from 'react';
import { Form, Button, Input } from 'semantic-ui-react';

const EducationExpForm = props => {
  return (
    <Form.Field>
      <label htmlFor="">Education Experience</label>
      <Form.Group>
        <Input
          name="name_of_institution"
          placeholder="Name of institution"
          value={props.experience.name_of_institution}
          onChange={(e) => {props.handleExpChange(e, 'education_experience', props.num);}}
        >
        </Input>
        <Input
          name="field_of_study"
          placeholder="Field of study"
          value={props.experience.field_of_study}
          onChange={(e) => {props.handleExpChange(e, 'education_experience', props.num);}}
        >
        </Input>
        <Input
          name="graduation_date"
          placeholder="Date of graduation"
          value={props.experience.graduation_date}
          onChange={(e) => {props.handleExpChange(e, 'education_experience', props.num);}}
        >
        </Input>
        {props.length - 1 === props.num && 
        <Button 
          onClick={() => {
            props.handleAddClick('education');
          }}>
          Add
        </Button>}
        {props.length > 1 &&
        <Button 
          onClick={() => {
            props.handleRemoveClick('education');
          }}>
          Remove
        </Button>}
      </Form.Group>
    </Form.Field>
  );
};

export default EducationExpForm;
