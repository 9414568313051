import React from 'react';
import AboutInfo from './AboutInfo';
import styled from 'styled-components';
import aboutBanner from 'assets/images/aboutbanner.jpg';
import Choroicon from 'assets/icons/Choroicon.png';
import { Helmet } from 'react-helmet';

const AboutBanner = styled.div`
  width: 100%;
  background-image: url(${aboutBanner});
  background-size: cover;
  height: 40vw;
  color: white;
`;

const Screen = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.6rem;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  @media (max-width: 740px) {
    gap: 1rem;
  }
`;

const About = () => {
  return (
    <>
      <AboutBanner>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Choro - About Choro Senior Help</title>
          <meta
            name="description"
            content="About Choro Senior Help"
          />
        </Helmet>
        <Screen>
          <img src={Choroicon} className="w-2/12"/>
          <div className="flex text-1.5xl sm:text-sm gap-3 xs:gap-1">
            <span>Empowered</span>
            <span>•</span>
            <span>Affordable</span>
            <span>•</span>
            <span>Independence</span>
          </div>
        </Screen>
      </AboutBanner>
      <AboutInfo/>
    </>
  );
};

export default About;
