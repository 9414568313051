import React from 'react';

const NeighborhoodMap = ({ lat, lng }) => {
  const key = process.env.REACT_APP_GOOGLE_MAP_KEY;

  return (
    <div className="relative pb-1/1">
      <div className="
				absolute 
				bg-opacity-25 
				bg-btn-primary 
				border-btn-primary 
				border-2
				rounded-full 
				z-50 
				w-7/8 
				h-7/8 
				inset-x-1/16 
				inset-y-1/16 
				"
      />
      <img
        className="absolute h-full w-full object-cover rounded-xl"
        src={`https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=14&size=500x500&maptype=roadmap&key=${key}`}
        alt="Location"
      />
    </div>
  );
};

export default NeighborhoodMap;

