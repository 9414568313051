import React from 'react';
import { Form, Button, Input } from 'semantic-ui-react';


const WorkExpForm = props => {

  return (
    <Form.Field>
      <label htmlFor="">Work Experience</label>
      <Form.Group>
        <Input
          name="company"
          placeholder="Company"
          value={props.experience.company}
          onChange={(e) => {props.handleExpChange(e, 'work_experience', props.num);}}
        >
        </Input>
        <Input
          name="location"
          placeholder="Location"
          value={props.experience.location}
          onChange={(e) => {props.handleExpChange(e, 'work_experience', props.num);}}
        >
        </Input>
        <Input
          name="length_of_time"
          placeholder="Length of time working here"
          value={props.experience.length_of_time}
          onChange={(e) => {props.handleExpChange(e, 'work_experience', props.num);}}
        >
        </Input>
        {props.length - 1 === props.num && 
        <Button 
          onClick={(e) => {
            e.preventDefault();
            props.handleAddClick('work');
          }}>
          Add
        </Button>}
        {props.length > 1 &&
        <Button 
          onClick={() => {
            props.handleRemoveClick('work');
          }}>
          Remove
        </Button>}
      </Form.Group>
    </Form.Field>
  );
};

export default WorkExpForm;
