import React from 'react';
import HeadingFive from 'components/common/Textbox/HeadingFive';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList, faMapMarkerAlt, faClock } from '@fortawesome/free-solid-svg-icons';
import { readableDate } from 'helpers/general';
import styled from 'styled-components';

const Grid = styled.div`
  display: grid;
  gap: 0.5rem 0.5rem;
  grid-template-columns: min-content 1fr;
  margin-top: 1rem;
`;

export default function ServiceRequest({ booking }) {
  return (
    <div className="bg-ui-gray-20">
      <div className="p-4">
        <HeadingFive>{booking.first_name_of_seeker} </HeadingFive> 
        <Grid>
          <FontAwesomeIcon className="text-lg" icon={faClipboardList} />{booking.services}
          <FontAwesomeIcon className="text-lg" icon={faMapMarkerAlt} />{booking.address}
          {booking.date && <> <FontAwesomeIcon className="text-lg" icon={faClock} /> {readableDate(booking.date)} </>}
        </Grid>
      </div>
      <Link key={booking.id} to={`/bookings/${booking.id}`}>
        <button className="w-full bg-btn-secondary p-1"><HeadingFive>View {booking.date ? 'Booking' : 'Request'}</HeadingFive></button>
      </Link>
    </div>
  );
}
