import React from 'react';
import TitleCard from 'components/common/TitleCard';
import Content from 'components/common/Content';
import TeamMember from 'screens/team/TeamMember';
import useGraphRequest from 'hooks/useGraphRequest';
import { Helmet } from 'react-helmet';

const query =`
{
  teamMemberCollection {
  	items {
  	  firstName
      lastName
      image {
        url
      }
      position
      blurb
      order
  	}
  }
}`;

const Team = () => {
  const { response } = useGraphRequest(query);

  const teamComponents = response && response.teamMemberCollection && response.teamMemberCollection.items.sort((a, b) => a.order - b.order).map((member, i) => <TeamMember key={i} info={member}/>);
  return (
    <Content padding>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Choro - Learn about the choro team</title>
        <meta
          name="description"
          content="Learn about the choro team"
        />
      </Helmet>
      <TitleCard>Meet Our Team</TitleCard>
      <div className="flex flex-wrap gap-4">
        {teamComponents}
        <TeamMember blank/>
        <TeamMember blank/>
        <TeamMember blank/>
      </div>
    </Content>
  );
};

export default Team;
