const faqsList = [
  {
    heading: 'Questions about Choro Services',
    content: [
      {
        title: 'Do I get to choose my Helper?',
        text: `
          <p>Yes — you can choose the helper you prefer based on experience, reviews, interests, language skills and location.</p>
        `
      },
      {
        title: 'How much does Choro cost?',
        text: `
          <p>Choro implements value-based pricing, so you pay for each task you’d like completed rather than paying per hour. The costs are as follows:</p>
          <ul>
            <li>$25.99 for a single task visit</li>
            <li>$37.99 for a two task visit</li>
            <li>$48.99 for a three task visit</li>
            <li>$59.99 for a four task visit</li>
            <li>$74.99 for a five task visit</li>
          </ul>
          <p>Please note that we also ask the Helper to engage in a volunteer component of each visit which includes a 10 minute uplifting chat and opportunity to make a genuine connection.</p>
        `
      },
      {
        title: 'Can I choose what services I want?',
        text: `
          <p>Yes — you can mix and match tasks based on exactly what you need. See below for an example.</p>
          <p>Examples:</p>
          <ul>
            <li>A grocery pick-up and a fridge cleanout is two tasks, and costs $37.99.</li>
            <li>A kitchen floor mop, help with the iPad and some quick weeding in the garden are three tasks, and costs $48.99.</li>
            <li>A grocery pick-up, a fridge cleanout, some meal prep and a kitchen clean-up is four tasks, and costs $59.99.</li>
          </ul>
        `
      },
      {
        title: 'How long does each task take?',
        text: `
          <p>Each task should take no longer than 30 minutes. If a task takes longer than 30 minutes, an extra task charge will be applied to each additional 30-minute block.</p>
        `
      }
    ]
  },
  {
    heading: 'Questions about Helpers',
    content: [
      {
        title: 'Who are the "Helpers"?',
        text: `
          <p>Helpers are people in your neighbourhood who are looking to lend a hand and build community connection! They are people who are genuinely interested in getting to know the people in their community, and are looking to build a support network where people can safely rely on their neighbours, just like the old-days.</p>
          <p>Helpers do this through supporting older adults and seniors with tasks in and around the home.</p>
        `
      },
      {
        title: 'How do I know the Helpers are safe?',
        text: `
          <p>The Helpers go through an extensive onboarding process that includes:</p>
          <ul>
            <li>Criminal Record Checks</li> 
            <li>Background Checks</li> 
            <li>Reference Checks</li> 
            <li>Interviews</li> 
            <li>Training</li> 
          </ul>
          <p>Choro has consulted representatives from healthcare educational programs as well as work safety organizations to create our training programs.</p>
        `
      },
      {
        title: 'What training do the Helpers go through?',
        text: `
          <p>Helpers are trained in the following:</p>
          <ul>
            <li>Working with cognitive impairments</li>
            <li>Elder abuse recognition and prevention</li>
            <li>Risk management</li>
            <li>Safety</li>
            <li>Emergency protocols</li>
          </ul>
        `
      },
      {
        title: 'Do Helpers provide personal care?',
        text: `
          <p>No — Helpers provide companionship and non-contact assistance with instrumental activities of daily living. Helpers do not provide personal care services such as feeding, grooming, bathing, etc.</p>
        `
      }
    ]
  },
  {
    heading: 'Questions about Booking a Helper',
    content: [
      {
        title: 'How do I find Helpers?',
        text: `
          <p>On the homepage, enter your location and hit “Find Help Now!”</p>
        `
      },
      {
        title: 'What if I cannot locate someone near me?',
        text: `
          <p>If you are unable to locate someone nearby, expand your range from 3km to something larger on the “Find Helpers” page.  If you are still having difficulties locating someone, visit our <a href="/contact" target="_blank"><u>contact us</u></a> page.</p>
        `
      },
      {
        title: 'What if I don’t see my preferred task when looking for helpers?',
        text: `
          <p>If you don’t see your preferred task in the list of services a Helper offers you have two options:</p>
          <ul>
            <li>You can chat with the Helpers available to see if they are willing to help with that task.</li>
            <li>You can <a href="/contact" target="_blank"><u>contact us</u></a>.</li>
          </ul>
        `
      },
      {
        title: 'Do I need an account to view Helpers?',
        text: `
          <p>No, you don’t need an account to view Helpers. However, you do need one to make a booking and to start a conversation with a Helper.</p>
        `
      },
      {
        title: 'How do I book a Helper?',
        text: `
          <p>Click the helpers name or click the “view full profile” to access this Helper’s profile and then hit the “Book [name]” to start the booking process.</p>
        `
      },
      {
        title: 'Can I chat with a Helper before booking them?',
        text: `
          <p>Yes, you can chat with a Helper by accessing their profile and clicking the “Chat” button. You must have your own account for this.</p>
        `
      },
      {
        title: 'How far in advance should I make a booking?',
        text: `
          <p>We recommend you make a booking at least 24 hours prior to the desired visit time.  The Helper is sent an email notification shortly after you book. If you do not receive a response to your booking request quickly enough to meet your needs, feel free to cancel the booking request and book another helper.</p>
          <p>Providing several days notice is better so that the Helpers can schedule their time as well.  If you need help in a shorter time frame, please <a href="/contact" target="_blank"><u>contact us</u></a>.</p>
        `
      },
      {
        title: 'Do I pay before the Helper visits?',
        text: `
          <p>No — you enter your payment information before the visit, but your credit card is not charged until after the visit has completed. </p>
        `
      },
      {
        title: 'How do I cancel a booking?',
        text: `
          <p>Please give at least 2 hours notice before cancelling a booking to avoid being charged for the service.</p>
          <ul>
            <li>Click the arrow next to your name in the top right corner and select “Bookings Dashboard”.</li>
            <li>Select the booking you’d like to cancel by clicking the arrow on the far right. </li>
            <li>Select “Cancel Booking”</li>
          </ul>
        `
      },
      {
        title: 'How far in advance do I need to cancel a booking?',
        text: `
          <p>If you need to cancel a booking, please cancel at least <b>2 hours</b> before your visit to avoid being charged for the service.</p>
        `
      }
    ]
  },
  {
    heading: 'Questions about the Helpers Visit',
    content: [
      {
        title: 'How do I work out the details of the Helpers visit?',
        text: `
          <p>When making a booking, you are required to leave detailed information about the tasks you need completed in the “additional information” section. If further clarification is needed prior to the visit, the helper will reach out to you before.</p>
          <p>Example: If you request a grocery and prescription pick-up, the Helper will ask you which grocery store and pharmacy you have ordered and pre-paid from.</p>
        `
      },
      {
        title: 'How will my Helper contact me?',
        text: `
          <p>The Helper will contact you using the chat feature on the Choro website.  If you are not online, an email notification will be sent to you.</p>
          <p>If you would like your Helper to contact you by phone, you can leave your phone number in the “additional information” section when making a booking.</p>
        `
      },
      {
        title: 'How do I leave a review?',
        text: `
          <p>After the visit is completed, you will be sent an email containing a link that will navigate you to choro.ca to review your helper.</p>
        `
      }
    ]
  }
];

export default faqsList;
