import React from 'react';
import TitleCard from 'components/common/TitleCard';
import Content from 'components/common/Content';
import MediaPost from 'screens/media/MediaPost';
import useGraphRequest from 'hooks/useGraphRequest';
import { Helmet } from 'react-helmet';

const query =`
{
  mediaPostCollection {
  	items {
  	  title
      link
  	}
  }
}`;

const Media = () => {
  const { response } = useGraphRequest(query);


  const teamComponents = response && response.mediaPostCollection && response.mediaPostCollection.items.map((member, i) => <MediaPost key={i} info={member}/>);
  
  return (
    <Content padding>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Choro - Choro in the Media</title>
        <meta
          name="description"
          content="Choro in the Media"
        />
      </Helmet>
      <TitleCard>Choro in the Media</TitleCard>
      <div className="flex flex-row flex-wrap gap-4">
        {teamComponents}
        <MediaPost blank/>
        <MediaPost blank/>
        <MediaPost blank/>
      </div>
    </Content>
  );
};

export default Media;
