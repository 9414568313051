import React, { useState, useEffect, useContext, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Form, Button, Popup, Icon, Input, Radio } from 'semantic-ui-react';
import { getServices } from 'requests/services';
import appContext from 'contexts/AppContext';
import Content from 'components/common/Content';
import styled from 'styled-components';
import WorkExpForm from './JoinHelpersWorkExp';
import VolunteerExpForm from './JoinHelpersVolunteerExp';
import EducationExpForm from './JoinHelpersEducationExp';
import ReferenceForm from './JoinHelpersReference';
import useApplicationData from '../../hooks/UseApplicationData.js';
import { createHelperApplication } from 'requests/helperApplications';

const Title = styled.p`
  margin-top: 0;
  font-size: 40px;
  font-weight: 800;
`;

const JoinHelperForm = () => {
  const { data, setData, setServices, handleAddClick, handleRemoveClick, handleExpChange, handleChange } = useApplicationData();
  const { setCurrentUser, currentUser } = useContext(appContext);
  const history = useHistory();
  const location = useLocation();
  const [disabled, setDisabled] = useState(false);
  const scrollTarget = useRef();
  
  useEffect(() => {
    setTimeout(() => {
      if (location.state && location.state.scroll == 'joinHelpers') {
        scrollTarget.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 1000);  
  }, [location.state]);

  useEffect(() => {
    getServices()
      .then(services => {
        services = services.map(item => {
          return { key: item.id, text: item.name, value: item.id };
        });
        setServices(services);
      });
  }, [setServices]);

  const formValid = () => {
    return data.terms && data.application_type && data.phone_number && data.availability && data.why_neighborli_helper;
  };
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    setDisabled(true);
    createHelperApplication({
      user_id: currentUser.id,
      ...data
    })
      .then(function() {
        let path = '/submitted';
        setCurrentUser((previous) => {
          return { ...previous, helper_application: true };
        });
        history.push(path);
      })
      .catch(function(error) {
        setData({ ...data, error: error });
        setDisabled(false);
      });
  };

  return (
    <Content paddingBottom ref={scrollTarget}>
      <Title>Join Helpers</Title>
      {data.error && 
      <p>error in submitting your application</p>}
      <Form onSubmit={handleSubmit}>
        <p>
          Join our community of change creators reaching out in meaningful ways
          making a difference one neighborhood at a time.
        </p>
        <p>
          Please provide as much information as you can. We do 
          extensive background checks to ensure the safest community for all.
        </p>
        <Form.Field required>
          <label>
           Phone Number
          </label>
          <Input
            name="phone_number"
            placeholder="Number"
            value={data.phone_number}
            onChange={handleChange}
            required
          />
        </Form.Field>
        <Form.Field required>
          <label>
           Are you interested in being a Helper, a Driver, or both
          </label>
        </Form.Field>
        <Form.Field>
          <Radio
            label='Helper'
            name='application_type'
            value='helper'
            checked={data.application_type === 'helper'}
            onChange={handleChange}
            required={false}
            className="mr-6"
          />
          <Radio
            label='Driver'
            name='application_type'
            value='driver'
            checked={data.application_type === 'driver'}
            onChange={handleChange}
            className="mr-6"
          />
          <Radio
            label='Both'
            name='application_type'
            value='helper and driver'
            checked={data.application_type === 'helper and driver'}
            onChange={handleChange}
            className="mr-6"
          />
        </Form.Field>
        <Form.Field required>
          <label>
            Availability
            <Popup
              content="Write down when's a good time for you"
              trigger={<Icon color="grey" name="question circle outline" />}
            />
          </label>
          <Input
            name="availability"
            placeholder="Availability"
            value={data.availability}
            onChange={handleChange}
            required
          />
        </Form.Field>
        {data.work_experience.map((experience, i) => {
          return (
            <WorkExpForm
              key={i}
              num={i} 
              experience={experience}
              length={data.work_experience.length}
              handleAddClick={handleAddClick}
              handleRemoveClick={handleRemoveClick}
              handleExpChange={handleExpChange}
            />
          );
        })}
        {data.volunteer_experience.map((experience, i) => {
          return (
            <VolunteerExpForm
              key={i}
              num={i} 
              experience={experience}
              length={data.volunteer_experience.length}
              handleAddClick={handleAddClick}
              handleRemoveClick={handleRemoveClick}
              handleExpChange={handleExpChange}
            />
          );
        })}
        {data.education_experience.map((experience, i) => {
          return (
            <EducationExpForm
              key={i}
              num={i} 
              experience={experience}
              length={data.education_experience.length}
              handleAddClick={handleAddClick}
              handleRemoveClick={handleRemoveClick}
              handleExpChange={handleExpChange}
            />
          );
        })}
        {data.reference.map((reference, i) => {
          return (
            <ReferenceForm
              key={i}
              num={i} 
              reference={reference}
              length={data.reference.length}
              handleAddClick={handleAddClick}
              handleRemoveClick={handleRemoveClick}
              handleExpChange={handleExpChange}
            />
          );
        })}
        <Form.TextArea 
          name="why_neighborli_helper"
          label='Why do you want to be a helper' 
          placeholder='Tell us why you want to become a Neighborli helper...' 
          value={data.why_neighborli_helper}
          onChange={handleChange}
          required
        /> 
        <Form.Checkbox
          name="terms"
          checked={data.terms}
          onChange={() => {
            setData({ ...data, terms: !data.terms });
          }}
          label={
            <label>
              I agree to the{' '}
              <a href={'/terms-and-conditions'} target="_blank" rel="noreferrer">Terms and Conditions</a>
              <Popup
                content="Disclaimer:
                To the maximum extent permitted by applicable law, 
                we exclude all representations, warranties and conditions relating 
                to our website and the use of this website."
                trigger={<Icon color="grey" name="question circle outline" />}
              />
            </label>
          }
          required
        />
        <Button disabled={!formValid() || disabled} primary type="submit">
          Submit
        </Button>
      </Form>
    </Content>
  );
};

export default JoinHelperForm;
