import React from 'react';
import ContactInfo from './ContactInfo';
import { Helmet } from 'react-helmet';

const Contact = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Choro - Contact Choro about Senior Help</title>
        <meta
          name="description"
          content="Contact Choro about Senior Help"
        />
      </Helmet>
      <ContactInfo/>
    </>
  );
};

export default Contact;
