import React from 'react';
import { SubHeading } from 'components/common/Textbox';

const Feature = ({ icon, title, text, isLeftMobile }) => {
  // Alternate mobile features with icon on left and right
  const flexOrdering = isLeftMobile ? 'flex-auto' : 'flex-auto sm:order-first'; 
  const flexMargin = isLeftMobile ? 'text-center sm:text-base sm:ml-6' : 'text-center sm:text-base sm:mr-6';

  return (
    <div className="flex md:flex-col items-center">
      <div className="flex-none sm:w-2/7">
        <img className="mx-auto sm:w-20 md:w-24" src={icon} alt=""/>
        <div className="text-center">
          <p className="sm:text-lg md:text-3xl lg:text-4xl font-semibold sm:py-1 md:py-4">{title}</p>
        </div>
      </div>
      <div className={flexOrdering}>
        {/* Only safe because this directly reads HTML from the passed object */}
        <div className={flexMargin}>
          <SubHeading>
            {text}
          </SubHeading>
        </div>
      </div>
    </div>
  );
};

export default Feature;
