import React from 'react';
import ButtonRoot from './Shared/ButtonRoot';

const ButtonSecondaryRound = (props) => {
  return (
    <ButtonRoot 
      className="
        block
        bg-btn-secondary
        text-black
        font-semibold 
        rounded-full 
        border-2
        border-black
        hover:bg-btn-secondary-hover
        hover:no-underline
        hover:text-black
      "
      {...props}
    />
  );
};

export default ButtonSecondaryRound;
