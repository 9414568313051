import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faSmile } from '@fortawesome/free-solid-svg-icons';


export default function HelperProfileActions({ visits, reviews }) {
  return (
    <div className="flex gap-4 flex-wrap xs:flex-col">
      <div className="border-2 xs:hidden border-btn-primary font-medium text-md self-start text-btn-primary rounded-md p-2 flex items-center gap-1">
        {(visits && visits > 0) ?
          <>
            <FontAwesomeIcon className="text-md" icon={faHome}/>
            {visits} VISITS
          </>
          :
          <>
            <FontAwesomeIcon className="text-md" icon={faSmile}/>
                NEW HELPER
          </>
        }
      </div>
      <a href="#about" className="hidden xs:block">
        <div className="border-2 border-ui-gray-100 font-medium text-md self-start text-ui-gray-100 rounded-md p-2 flex items-center gap-1">
                About
        </div>
      </a>
      <a href="#tasks">
        <div className="border-2 border-ui-gray-100 font-medium text-md self-start text-ui-gray-100 rounded-md p-2 flex items-center gap-1">
                Task Types
        </div>
      </a>
      {reviews &&
            <a href="#recommendations">
              <div className="border-2 border-ui-gray-100 font-medium text-md self-start text-ui-gray-100 rounded-md p-2 flex items-center gap-1">
                Recommendations
              </div>
            </a>
      }
    </div>
  );
}
