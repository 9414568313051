import React from 'react'; 

const PrimaryHeading = ({ children }) => {
  return (
    <h1 className="
      font-semibold 
      sm:text-3xl 
      md:text-4xl 
      lg:text-5xl 
      xl:text-6xl"
    >
      {children}
    </h1>
  );
};

export default PrimaryHeading;
