import React, { useContext, useState } from 'react';
import Content from 'components/common/Content';
import { HeadingFour } from 'components/common/Textbox';
import Payments from 'components/common/Payments/Payments';
import appContext from 'contexts/AppContext';
import { Form, Input, Label } from 'semantic-ui-react';
import GiftCard from './GiftCard';
import ButtonPrimary from 'components/common/Button/ButtonPrimaryRound';
import { purchaseGiftCard } from 'requests/payments';

const GiftCards = () => {
  const { currentUser } = useContext(appContext);
  const [giftCard, setGiftCard] = useState();
  const [data, setData] = useState({});
  const [submitted, setSubmitted] = useState(false);


  const handleChange = (e, type) => {
    let { name, value } = e.target || {};
    if (!name && !value) {
      name = type.name;
      value = type.value;
    }
    setData({ ...data, [name]: value });
    // errors[name] && setErrors({ ...errors, [name]: null });
  };

  const handleSubmit = () => {
    if (valid()) {
      setSubmitted(true);
      purchaseGiftCard({ ...data, total: data.total * 100, message: data.gift ? data.message : null }, data.payment_method)
        .then(giftCard => {
          setSubmitted(false);
          setGiftCard(giftCard);
          setSubmitted(true);
        });
    }
  };
  const valid = () => {
    return data.email && data.total && data.payment_method;
  };

  return (
    <Content padding>
      {giftCard ?
        <>
          <HeadingFour>Here is your new Gift Card!</HeadingFour>
          <p>This card has also been emailed to {data.email}</p>
          <GiftCard card={giftCard}/>
        </>
        :
        <>
          <HeadingFour>Purchase A Gift Card</HeadingFour>
          <br/>
          <Form>
            <Form.Field>
              <label>Is this for you or someone else?</label>
              <Form.Checkbox
                radio
                label='Someone else'
                name='checkboxRadioGroup'
                value={true}
                checked={data.gift}
                onChange={() => {handleChange({}, { value: true, name:'gift' });}}
              />
              <Form.Checkbox
                radio
                label='Me'
                name='checkboxRadioGroup'
                value={false}
                checked={!data.gift}
                onChange={() => {handleChange({}, { value: false, name:'gift' });}}
              />
            </Form.Field>
            <Form.Field className="flex flex-col">
              <label>{data.gift ? 'Recipient\'s email' : 'Email'}</label>
              <Input 
                type='email' 
                name="email"
                placeholder='email@email.com'
                onChange={handleChange}
                value={data.email}
              />
            </Form.Field>
            {
              data.gift &&
              <Form.Field className="flex flex-col">
                <label>Message to gift card recipient</label>
                <textarea 
                  name="message"
                  placeholder='message'
                  onChange={handleChange}
                  value={data.message}
                />
              </Form.Field>
            }
            <Form.Field className="flex flex-col">
              <label>Gift Card Amount</label>
              <Input  
                labelPosition='right' 
                type='number' 
                min="1" 
                step="1"
                placeholder='Amount'
                onChange={handleChange}
                name="total"
              >
                <Label basic>$</Label>
                <input />
                <Label>.00</Label>
              </Input>
            </Form.Field>
            <Form.Field>
              <label>Payment</label>
              <Payments amount={data.total * 100} updatePaymentMethod={(method) => {handleChange({}, { value: method, name:'payment_method' });}} user={currentUser} direct />
            </Form.Field>
            <div className="flex w-full justify-center">
              <ButtonPrimary onClick={handleSubmit} disabled={submitted || !valid()}>Submit</ButtonPrimary>
            </div>
          </Form>
        </>
      }
    </Content>
  );
};

export default GiftCards;
