import React from 'react';
import { Route } from 'react-router-dom';
import LandingPage from './LandingPage';

const routes = [
  <Route key="landing" path="/landing" component={LandingPage} />,
  <Route key="index" path="/" exact component={LandingPage} />
];

export default routes;
