import { useState } from 'react';

export default function useApplicationData() {

  const [data, setData] = useState({
    availability: '',
    application_type: '',
    phone_number: '',
    why_neighborli_helper: '',
    work_experience: [{ company: '', location: '', length_of_time: '' }],
    volunteer_experience: [{ organization: '', length_of_time: '' }],
    education_experience: [{ name_of_institution: '', field_of_study: '', graduation_date: '' }],
    reference: [{ name: '', email: '', phone_number: '', relation: '' }]
  });
  const [services, setServices] = useState([]);

  const handleChange = (e, { name, value }) => {
    setData({ ...data, [name]: value });
  };

  const handleExpChange = (e, type, index) => {
    const { name, value } = e.target;
    const list = [...data[type]];
    list[index][name] = value;
    setData({ ...data, [type]: list });
  };

  function handleRemoveClick(expType, index) {
    if (expType === 'volunteer') {
      data.volunteer_experience.splice(index, 1);
      setData({
        ...data
      });
    } else if (expType === 'education') {
      data.education_experience.splice(index, 1);
      setData({
        ...data
      });
    } else if (expType === 'reference') {
      data.reference.splice(index, 1);
      setData({
        ...data
      });
    }
    else {
      data.work_experience.splice(index, 1);
      setData({
        ...data
      });
    }
  }

  function handleAddClick(expType) {
    if (expType === 'volunteer') {
      setData({
        ...data,
        volunteer_experience: [...data.volunteer_experience, { organization: '', length_of_time: '' }]
      });
    } else if (expType === 'education') {
      setData({
        ...data,
        education_experience: [...data.education_experience, { name_of_institution: '', field_of_study: '', graduation_date: '' }]
      });
    } else if (expType === 'reference') {
      setData({
        ...data,
        reference: [...data.reference, { name: '', email: '', phone_number: '', relation: '' }]
      });
    }
    else {
      setData({
        ...data,
        work_experience: [...data.work_experience, { company: '', location: '', length_of_time: '' }]
      });
    }
  }

  return { data, setData, services, setServices, handleAddClick, handleRemoveClick, handleExpChange, handleChange };

}
