import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import appContext from 'contexts/AppContext';
import { PageMargined } from 'components/common/Layout';
import useHelperProfile from 'hooks/useHelperProfile';

import BackButton from './BackButton';
import HelperProfileHeader from './HelperProfileHeader';
import HelperProfileInformation from './HelperProfileInformation';
import ServicesList from './ServicesList';
import RecommendationsSegment from '../RecommendationsSegment';
import HeaderBookingModal from './HelperBookingModal';
import HelperProfileActions from './HelperProfileActions';
import OfflineNotice from 'components/common/OfflineNotice';

const HelperProfile = () => {
  const params = useParams();
  const { currentUser, online } = useContext(appContext);
  const { id } = params;
  const [helperProfile] = useHelperProfile(id);
  
  return (
    <div>
      <PageMargined>
        <div className="
					flex
					flex-col
					sm:py-6
					sm:gap-4
					md:py-12
					md:gap-8"
        >
          <div className="flex-none">
            <BackButton>Back to Find Helpers</BackButton>
          </div>
          {!online && (!helperProfile || !helperProfile.id) ?
            <OfflineNotice  message="Go Online to view this helper's profile"/>
            :
            <div className="flex xs:flex-col gap-6 sm:gap-8">
              <div className="hidden xs:block">
                <HelperProfileActions visits={helperProfile.visits} reviews={helperProfile.reviews}/>
              </div>
              <HelperProfileHeader currentUser={currentUser} helperProfile={helperProfile} />
              <div className="flex flex-col gap-6 sm:w-1/1 md:w-1/2">
                <hr className="md:hidden border-t-1 border-ui-gray-40" />
                <HelperProfileInformation {...helperProfile} />
                <ServicesList services={helperProfile.services} />
                <RecommendationsSegment data={helperProfile}/>
                <div className="xs:self-center">
                  <HeaderBookingModal seekerInfo={currentUser} helperInfo={helperProfile} />
                </div>
              </div>
            </div>
          }
        </div>
      </PageMargined>
    </div>
  );
};

export default HelperProfile;

