import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import CovidPopup from './NavBar/CovidPopup';
import styled from 'styled-components';

const NotificationBanner = styled.div`
  width: 100%;
  text-align: center;
  color: #ffffff;
  z-index: 20;
`;

const Footer = ({ childRef }) => {
  return (
    <>
      <NotificationBanner className="bg-primary"><CovidPopup/></NotificationBanner>
      <div 
        className="
          bg-background-dark
          sm:p-6 
          md:px-12 
          md:py-6 
          lg:px-16 
          lg:py-8 
          xl:px-24 
          xl:py-12
          "
        ref={childRef}
      >
        <div className="2xl:mx-auto 2xl:max-w-8xl">
          <div className="flex sm:flex-col md:flex-row-reverse">
            <div className="flex-auto">
              <div className="flex sm:items-center sm:mb-4 md:flex-col">
                <div className="
                  flex-auto
                  text-white 
                  text-lg
                  sm:text-left
                  md:text-right 
                  md:font-bold
                  "
                >
                  Join the Choro<br />Community!
                </div>
                <div className="flex-auto text-right my-3">
                  <a className="text-white text-4xl mr-3" href="https://www.facebook.com/choro.help"><FontAwesomeIcon icon={faFacebook} /></a>
                  <a className="text-white text-4xl" href="https://www.instagram.com/choro.help"><FontAwesomeIcon icon={faInstagram} /></a>
                  <a className="text-white text-4xl ml-3" href="https://www.linkedin.com/company/neighborli/"><FontAwesomeIcon icon={faLinkedin} /></a>
                </div>
              </div>
            </div>
            <div className="flex-auto">
              <p className="
                text-lg
                text-white
                font-bold 
                "
              >
                <span className="border-b-2 border-gray-400">Resources</span>
              </p>
              <div className="flex flex-col py-2 gap-2">
                <Link to="/contact"><span className="text-white text-base">Contact Us</span></Link>
                <Link to="/about"><span className="text-white text-base">About Us</span></Link>
                <Link to="/faqs"><span className="text-white text-base">FAQ</span></Link>
                <Link to="/helpers/new"><span className="text-white text-base">Join Helpers</span></Link>
                <Link to="/team"><span className="text-white text-base">Our Team</span></Link>
                <Link to="/media"><span className="text-white text-base">Media</span></Link>
                <Link to="/gift-cards"><span className="text-white text-base">Gift Cards</span></Link>
                <a href="https://blog.choro.ca/"><span className="text-white text-base">Blog</span></a>
              </div>
            </div>
          </div>
          <hr className="border-t-2 border-gray-400" />
          <div className="flex sm:flex-col">
            <div className="flex-auto">
              <div className="my-2 text-white text-xs">
                &copy; 2019 NEIGHBORLI SERVICES INC.
              </div>
              <div className="text-white text-xs">
                <Link to={'/terms-and-conditions'}><span className="text-white">TERMS AND CONDITIONS</span></Link>
                <span> | </span>
                <Link to={'/privacy'}><span className="text-white">PRIVACY POLICY</span></Link>
              </div>
            </div>
            <div className="flex-auto">
              <div className="my-2 text-white text-xs md:text-right">
                Icons made by Freepik from www.flaticon.com 
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
