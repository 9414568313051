import React from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft } from '@fortawesome/free-regular-svg-icons';

const BackButton = ({ children }) => {
  const history = useHistory();

  return (
    <button
      className='text-xl text-black font-semibold hover:underline hover:text-black'
      onClick={() => history.goBack()}
    >
      <div 
        className='flex items-center gap-6'
      >
        <FontAwesomeIcon className='text-2xl' icon={faArrowAltCircleLeft} />
        {children}
      </div>
    </button>
  );
};

export default BackButton;
