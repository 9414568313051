import React, { useContext } from 'react';
import Content from 'components/common/Content';
import { Link } from 'react-router-dom';
import appContext from 'contexts/AppContext';

const NotAuthorized = () => {
  const { online } = useContext(appContext);
  return (
    <Content padding>
      {online ?
        <>
          <h1>You are not authorized to do this.</h1>
          <p>Make sure you are <Link to="/login">logged in</Link> and have the right access.</p>
        </>
        :
        <>
          <h1>You must be online to do this.</h1>
        </>
      }
    </Content>
  );
};

export default NotAuthorized;
