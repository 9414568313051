import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { createConversation } from 'requests/chat';
import Button from 'components/common/Button/ButtonPrimaryOutline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots } from '@fortawesome/free-solid-svg-icons';
import appContext from 'contexts/AppContext';

export default function ChatButton({ person1_id, person2_id, small }) {
  const history = useHistory();
  const { currentUser, online } = useContext(appContext);

  person2_id = person2_id || (currentUser && currentUser.id);
  const startChat = () => {
    if (currentUser) {
      let params = { conversation: { person1_id, person2_id } };
      createConversation(params)
        .then(() =>  history.push('../../chat', params));
    } else {
      history.push({
        pathname: '/login',
        state: {
          message: 'You must be logged in to chat.',
          from: location.pathname
        }
      });
    }
  };

  return small ?
    <button disabled={!online} onClick={startChat}>
      <FontAwesomeIcon icon={faCommentDots}/>
    </button>
    :
    <Button disabled={!online} small onClick={startChat}>
        Chat <FontAwesomeIcon icon={faCommentDots}/>
    </Button>;
}
