import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import appContext from 'contexts/AppContext';

const UserProfileDropdownItem = ({ type, onClick }) => {
  const { currentUser } = useContext(appContext);
  const profile =
    type === 'Helpers'
      ? currentUser.helper_profile
      : currentUser.seeker_profile;

  const dropdownItem = profile ? (
    <Link
      className="text-primary hover:text-btn-primary-hover"
      to={`/${type.toLowerCase()}/${currentUser.id}/edit`}
      onClick={onClick}
    >{`Edit ${type.slice(0, -1)} Profile`}</Link>
  ) : (
    <Link
      className="text-primary hover:text-btn-primary-hover"
      to={`/${type.toLowerCase()}/new`}
      onClick={onClick}
    >{`Join ${type}`}</Link>
  );

  return dropdownItem;
};

export default UserProfileDropdownItem;
