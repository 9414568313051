import { useState, useEffect } from 'react';


const getServerUrl = () => {
  return document.getElementById('serverUrl').value || window.location.origin;
};


function isReachable(url) {
  /**
   * Note: fetch() still "succeeds" for 404s on subdirectories,
   * which is ok when only testing for domain reachability.
   *
   * Example:
   *   https://google.com/noexist does not throw
   *   https://noexist.com/noexist does throw
   */
  return fetch(url, { method: 'HEAD', mode: 'no-cors' })
    .then(function(resp) {
      return resp && (resp.ok || resp.type === 'opaque');
    })
    .catch(function(err) {
      console.warn('[conn test failure]:', err);
    });
}

const useOnlineTest = () => {
  const [online, setOnline] = useState(true);

  useEffect(() => {
    window.addEventListener('online', handleConnectionChange);
    window.addEventListener('offline', handleConnectionChange);
  }, []);

  function handleConnectionChange() {
    if (navigator.onLine) {
      isReachable(getServerUrl()).then(function(updateOnline) {
        if (updateOnline) {
          // handle online status
          if (!online) {
            location.reload();
          }
          setOnline(true);
        } else {
          setOnline(false);
        }
      });
    } else {
    // handle offline status
      setOnline(false);
    }
  }

  return online;
};

export default useOnlineTest;
