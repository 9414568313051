import React, { useState } from 'react';
import ServiceRequest from 'components/Booking/ServiceRequest';
import TablePagination from '@material-ui/core/TablePagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TableFilters from 'components/common/TableFilters';
import styled from 'styled-components';

const FilterHolder = styled.div`
  top: -4rem;
`;

export default function MobileBookingsTable({ bookings, headers, filterOptions }) {
  const [showFilters, setShowFilters] = useState(false);
  const { page, setPage, totalPages, filters, setFilters } = filterOptions || {};

  return (
    <div className="flex flex-col gap-4 relative">
      {filters &&
        <FilterHolder className="absolute -top-12 right-0">
          <Tooltip title="Filter">
            <IconButton aria-label="filter list" onClick={() => setShowFilters(!showFilters)}>
              <FontAwesomeIcon icon={faFilter}/>
            </IconButton>
          </Tooltip>
        </FilterHolder>
      }
      {
        showFilters &&
        <TableFilters headers={headers} filters={filters} setFilters={setFilters}/>
      }
      {bookings && bookings.map((bookingRequest, index) => <ServiceRequest key={index} booking={bookingRequest}/>)}
      {setPage && <TablePagination
        component="div"
        count={totalPages}
        rowsPerPage={10}
        rowsPerPageOptions={[10]}
        labelDisplayedRows={() => `page ${page + 1} ${(totalPages != -1) ? `of ${totalPages + 1}` : ''}`}
        page={page}
        onChangePage={(e, pageNumber) => setPage(pageNumber)}
      />}
    </div>
  );
}
