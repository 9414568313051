import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Button from 'components/common/Button/ButtonPrimaryOutline';
import Table from 'components/common/Table';
import Status from 'components/common/Status';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleRight } from '@fortawesome/free-regular-svg-icons';
import { getBookings } from 'requests/bookings';
import { useMediaQuery } from 'react-responsive';
import useFilters from 'hooks/useFilters';
import MobileBookingsTable from 'screens/bookings/MobileBookingsTable';

const headers = ['Client', 'Helper', 'Date', 'Services', 'Status'];
const sortLookup = {
  Client: 'seeker_user_id',
  Helper: 'user_id',
  Date: 'date',
  'Requested On': 'created_at',
  Services: 'services',
  Status: 'status'
};

const filterDefaults = (bookingParams) => {
  return ({
    status: {
      'pending': { value: true, name: 'pending' }, 
      'approved': { value: true, name: 'payment required' }, 
      'declined': { value: true, name: 'declined' }, 
      'cancelled': { value: false, name: 'cancelled' }, 
      'payment-ready': { value: true, name: bookingParams.time == 'past' ? 'needs review' : 'pending service' }, 
      'incomplete': { value: true, name: 'incomplete' }, 
      'payment-declined': { value: true, name: 'payment declined' }, 
      'paid': { value: true, name: 'complete' }
    }
  });
};

const BookingsList = ({ bookingParams }) => {
  const { items, filterOptions } = useFilters(filterDefaults(bookingParams), { item: 'Date', direction: 'desc' }, bookingParams, sortLookup, getBookings, `bookings-${bookingParams.time}`);
  const isMobile = useMediaQuery({ query: '(max-width: 760px)' });
  
  const rows = items ? items.map(booking => booking ? [
    (<Link key={booking.id} to={`/users/${booking.seeker_user_id}`}>
      {booking.first_name_of_seeker}
    </Link>),
    (<Link key={booking.id} to={`/users/${booking.user_id}`}>
      {booking.first_name_of_helper}
    </Link>),
    `${(new Date(booking.date)).toDateString()} ${moment(booking.date).format('hh:mm a')}`,
    booking.services,
    <Status key={booking.id} status={booking.status} date={booking.date}/>,
    (
      <>
        <Link key={booking.id} to={`/bookings/${booking.id}`}>
          <Button small >
            View <FontAwesomeIcon icon={faArrowAltCircleRight}/> 
          </Button>
        </Link>
      </>
    )
  ]: []) : [];

  return (
    <>
      {isMobile ? 
        <MobileBookingsTable bookings={items} headers={headers} filterOptions={filterOptions}/>
        :
        <Table rows={rows} headers={headers} filterOptions={filterOptions}/>
      }
    </>
  );
};



export default BookingsList;
