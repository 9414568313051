import React from 'react'; 

const Text = ({ children }) => {
  return (
    <p className="
      text-gray-500
      font-light
      sm:text-sm 
      md:text-base" 
    >
      {children}
    </p>
  );
};

export default Text;
