import React, { useState, useContext } from 'react';
import { Form, Button, Message } from 'semantic-ui-react';
import { saveCurrentUser } from 'helpers/storageHelpers';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Content from 'components/common/Content';
import appContext from 'contexts/AppContext';
import styled from 'styled-components';
import { signIn } from 'requests/auth';
import { getUser } from 'requests/users';
import OfflineNotice from 'components/common/OfflineNotice';
import { postSubscription } from 'requests/pushNotifications';
import { base64UrlToUint8Array } from 'helpers/general';

const Title = styled.p`
  margin-top: 0;
  font-size: 40px;
  font-weight: 800;
`;

const HandledLink = styled.span`
  color: #007bff;
  cursor: pointer;
`;

const LoginForm = () => {
  const { setCurrentUser, online } = useContext(appContext);
  const location = useLocation();
  let initialData = {
    email: '',
    password: ''
  };
  const [data, setData] = useState(initialData);
  const history = useHistory();

  const handleChange = (e, { name, value }) => {
    setData({ ...data, [name]: value });
  };

  const handleSubmit = () => {
    const { email, password } = data;

    signIn(email, password)
      .then(user => {
        return getUser(user.id);
      })
      .then((user) => {
        navigator.serviceWorker.ready.then((serviceWorker) => {
          serviceWorker.pushManager.getSubscription().then((pushSubscription) => { 
            if (pushSubscription) {
              return pushSubscription;
            } else {
              return Notification.requestPermission()
                .then((answer) => {
                  if (answer != 'denied') {
                    return serviceWorker.pushManager.subscribe({
                      userVisibleOnly: true,
                      applicationServerKey: base64UrlToUint8Array(process.env.REACT_APP_PUSH_PUBLIC_KEY)
                    });
                  }
                });
            }
          })
            .then(postSubscription);
        });

        saveCurrentUser(user);
        setCurrentUser(user);
        let path = '/helpers';
        if (location.state && location.state.from) {
          history.push(location.state.from);
        }else {
          history.push(path);
        }
      })
      .catch(function(error) {
        setData({ ...data, error: error });
      });
  };

  const forwardToSignup = () => {
    history.push('/users/new', location.state);
  };

  return (
    <Content padding>
      {location.state && 
        <Message
          warning
          header="Must be logged in"
          content={location.state.message}
        />  
      }
      <Form onSubmit={handleSubmit} error={data.error ? true : false}>
        {!online && <OfflineNotice message="You need to be online to login."/>}
        <Title>Login</Title>
        <Message
          error
          header="Login error"
          content="Invalid email or password"
        />      
        <Form.Input
          label="E-mail"
          name="email"
          type="email"
          placeholder="E-mail"
          disabled={!online}
          value={data.email}
          onChange={handleChange}
          error={data.error ? true : false}
          required
        />
        <Form.Input
          label="Password"
          name="password"
          type="password"
          placeholder="Password"
          disabled={!online}
          value={data.password}
          onChange={handleChange}
          error={data.error ? true : false}
          required
        />
        <Button primary type="submit" disabled={!online}>
          Submit
        </Button>

        <p>Forgot your password? <Link to={'/forgot_password'}>Reset it Here</Link></p>
        <p>New to Choro? <HandledLink onClick={forwardToSignup}>Sign up Here</HandledLink></p>
      </Form>
    </Content>
  );
};

export default LoginForm;
