import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';

const CarouselContainer = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CarouselCell = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  transition: all 2s;
  position: absolute;
  left: 100%;
  background-color: white;
  ${props => props.show ? 
    css`
    left: 0;
    opacity: 1;
    z-index: 10;
    position: relative;
    `
    :
    css`
      animation-name: hide;
      z-index: 1;
      animation-duration: 2s;
    `
}
  @keyframes hide {
    0%   {
      left: 0;
      opacity: 1;
    }
    100%  {
      left: -100%;
      opacity: 1;
    }
  }
`;

const Arrow = styled.div`
  z-index: 11;
  font-size: 30px;
  opacity: 0.7;
  cursor: pointer;
  ${props => props.full && 
    css`
      position: absolute;
      ${props => props.right && 
        css`
          right: 0;
        `
}
    `
}
`;

const Carousel = ({ children, full, arrows }) => {
  const [position, setPosition] = useState(0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setPosition(prev => (prev + 1) % (children.length));
    }, 8000);
    return () => clearTimeout(timeout);
  }, [position, children.length]);

  const changePosition = (increment) => {
    setPosition(prev => (prev + increment) % (children.length));
  };

  return (
    <CarouselContainer>
      {arrows && <Arrow full={full} onClick={() => changePosition(-1)}><FontAwesomeIcon icon={faAngleDoubleLeft}/></Arrow>}
      {children.map((child, index) => <CarouselCell key={index} show={index === position}>{child}</CarouselCell>)}
      {arrows && <Arrow full={full} right onClick={() => changePosition(1)}><FontAwesomeIcon icon={faAngleDoubleRight}/></Arrow>}
    </CarouselContainer>
  );
};

export default Carousel;
