import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const OfflineNotice = ({ message }) => {
  return (
    <div className="flex w-full gap-4 justify-center items-center text-base p-4 border border-ui-gray-40 rounded-sm">
      <FontAwesomeIcon className="text-4xl" icon={faTimesCircle}/>
      {message}
    </div>
  );
};

export default OfflineNotice;
