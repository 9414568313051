import React from 'react';
import { TertiaryHeading } from 'components/common/Textbox'; 
import { ButtonPrimaryRound } from 'components/common/Button'; 

const Join = () => {
  return (
    <div className="
      bg-background-secondary
      sm:p-6 
      md:px-12 
      md:py-6 
      lg:px-16 
      lg:py-8 
      xl:px-24 
      xl:py-12
      " 
    >
      <div className="flex flex-col">
        <div className="flex-none text-center mb-4">
          <TertiaryHeading>Interested in becoming a Choro Helper?</TertiaryHeading>
        </div>
        <div className="flex-none mx-auto">
          <ButtonPrimaryRound to="/helpers/new">Join Helpers</ButtonPrimaryRound>
        </div>
      </div>
    </div>
  );
};

export default Join;
