import React from 'react';
import Container from 'components/Landing/Container';
import { SecondaryHeading, SubHeading } from 'components/common/Textbox';
import TextCarousel from 'components/Landing/Carousel/TextCarousel';

const serviceList = [
  {
    _id: 0,
    title: '"Dad can now stay in his home ..."',
    text: 'We really didn’t want to send him to an assisted living home. He’s so worried about getting Covid and not being able to see us, he didn’t want to go either. This has been such a godsend to have someone come twice a week who helps him with whatever he wants done around the house. Dad’s now king of his castle!',
    author: 'Sarah J'
  },
  {
    _id: 1,
    title: '"Made a huge impact ..."',
    text: 'To have a student come and chat and spend time with my parents. The isolation has been really hard on my parents both mentally and physically. Well worth the small cost!',
    author: 'Lawrence T'
  },
  {
    _id: 2,
    title: '"Joyce is all about safety ..."',
    text: 'Our helper person was well-trained and so careful with cleaning, her mask and social distancing. She was only there for 2 hours but helped mom with sweeping the stairs, picked up a few things from the drug store and helped her with her iPad issues. She gives me the break I desperately need.',
    author: 'Amy S'
  },
  {
    _id: 3,
    title: '"Enjoying garden walks . . ."',
    text: 'Xuemei has been an exceptional walking companion for my Mom in the last month. Am sorry to lose her, since Mom will be placed in a long term facility today. She is so presentable, a perfectionist, full of empathy. And one to be trusted. I shall refer her to others who may approach me for companions. Good luck to Xuemei.',
    author: 'Nania B'
  },
  {
    _id: 4,
    title: '"Heavy lifting . . "',
    text: 'When Ian came he was so humble, caring and kind and I just couldn’t believe it. He was an angel. He would do lifting, put together our bed and ask if he could do anything else. He renewed my faith in human kind.',
    author: 'Leila E'
  },
  {
    _id: 5,
    title: '"Driving me across town . . ."',
    text: 'Ed was the absolute perfect “Helper” for my important (no tv for six days) task. Knowledge about the TV too. Patient and easy-Go- with-the-flow- kind of Guy!!',
    author: 'June V'
  }
];

const Testimonials = () => {
  return (
    <Container>
      <div className="flex sm:flex-col items-center">
        <div className="flex-auto sm:w-1/1 sm:pr-16 md:mr-16 lg:mr-20 xl:mr-24"> 
          <div className="leading-snug">
            <SecondaryHeading>
              Don&apos;t just take our word for it
            </SecondaryHeading>
          </div> 
          <div className="sm:text-base sm:py-6 md:py-6">
            <SubHeading>
              See the difference our Helpers are making for people like you.
            </SubHeading>
          </div>
        </div>
        <div className="flex-none sm:w-1/1 md:w-5/8">
          <TextCarousel contentList={serviceList} />
        </div>
      </div>
    </Container>
  );
};

export default Testimonials;
