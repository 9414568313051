import React from 'react';
import ChatButton from 'components/Chat/ChatButton';
import Impersonate from 'components/Admin/Impersonate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpenText, faIdCard } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { Popup } from 'semantic-ui-react';

export default function UserSummary({ user }) {
  return (
    <div className="flex items-center bg-gray-100 rounded-full w-full justify-between">
      <div className="flex items-center">
        <img src={user.image} className="rounded-full h-10 w-10 object-cover"></img>
        <strong className="ml-2">{user.first_name}</strong>
        <span className="text-sm ml-2">
          <FontAwesomeIcon className="mr-1" icon={faEnvelopeOpenText}/>
          {user.email}
        </span>

      </div>
      <div className="flex items-center pr-3 gap-3">
        <Popup
          trigger={
            <Link to={`/users/${user.id}`}>
              <FontAwesomeIcon icon={faIdCard}/>
            </Link>
          }
          size={'small'}
          wide={'very'}
          position='top center'
        >
          Profile
        </Popup>
        <Popup
          trigger={
            <div>
              <Impersonate small user={user}/>
            </div>
          }
          size={'small'}
          wide={'very'}
          position='top center'
        >
          Impersonate
        </Popup>
        <Popup
          trigger={
            <div>
              <ChatButton small person1_id={user.id}/>
            </div>
          }
          size={'small'}
          wide={'very'}
          position='top center'
        >
          Chat
        </Popup>
      </div>
    </div>
  );
}
