import React, { useEffect, useRef, useContext } from 'react';
import { Comment, Header } from 'semantic-ui-react';
import moment from 'moment';
import appContext from 'contexts/AppContext';
import Linkify from 'react-linkify';

const Messages = ({ conversation }) => {
  const { currentUser } = useContext(appContext);

  let otherUser;
  conversation.person1_id === currentUser.id
    ? (otherUser = conversation.person2)
    : (otherUser = conversation.person1);

  const messages = conversation.messages.map(message => {
    return (
      <Comment key={message.id}>
        <Comment.Avatar as="a" src={message.user.image} />
        <Comment.Content>
          <Comment.Author as="a">
            {`${message.user.first_name}`}
          </Comment.Author>
          <Comment.Metadata>
            <span>{moment(message.created_at).fromNow()}</span>
          </Comment.Metadata>
          <Comment.Text><Linkify properties={{ target: '_blank' }}>{message.text}</Linkify></Comment.Text>
          <Comment.Actions>{/* <a>Reply</a> */}</Comment.Actions>
        </Comment.Content>
      </Comment>
    );
  });
  //scroll to bottom of messages
  const messagesRef = useRef(null);
  useEffect(() => {
    messagesRef.current.scrollTop = messagesRef.current.scrollHeight + 100;
  }, [conversation]);

  return (
    <Comment.Group minimal>
      <Header className="chat-header text-lg" dividing>
        <h4>{`Conversation with ${otherUser.first_name}`}</h4>
      </Header>
      <div ref={messagesRef} className="messages">
        {messages}
      </div>
    </Comment.Group>
  );
};

export default Messages;
