import React from 'react';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import 'styles/animations/FadeInOut.css';

// Must correspond to animation durations in FadeInOut.css
const duration = 250;

const Fadebox = ({ children, id }) => {
  return (
    <SwitchTransition mode="out-in"> 
      <CSSTransition
        key={id}
        timeout={duration}
        classNames="fade"
      >
        {children}     
      </CSSTransition>
    </SwitchTransition>
  );
};

export default Fadebox;
