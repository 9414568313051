import React, { useContext } from 'react';
import Content from 'components/common/Content';
import SubTitleCard from 'components/common/SubTitleCard.jsx';
import BookingsList from './BookingsList';
import RequestsList from './RequestsList';
import appContext from 'contexts/AppContext';

const BookingDashboard = () => {
  // const [selected, setSelected] = useState('bookings');
  const { currentUser } = useContext(appContext);

  return (
    <>
      <Content padding >
        { currentUser.type && 
          <>
            <SubTitleCard small >Pending Service Requests</SubTitleCard>
            <RequestsList open bookingParams={{ status: ['pending', 'approved', 'payment-ready'], time: 'future', type: 'request' }}/>
          </>
        }
        <SubTitleCard small >Upcoming bookings</SubTitleCard>
        <BookingsList open bookingParams={{ time: 'future', type: 'booking' }}/>
        <SubTitleCard small>Past Bookings</SubTitleCard>
        <BookingsList open bookingParams={{ time: 'past', type: 'booking' }}/>
      </Content>
    </>
  );
};



export default BookingDashboard;
