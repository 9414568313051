import { useState, useEffect } from 'react';
import { GraphQLClient, gql } from 'graphql-request';

const client = new GraphQLClient(
  `https://graphql.contentful.com/content/v1/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}/`,
  {
    headers: {
      authorization: `Bearer ${process.env.REACT_APP_CONTENTFUL_TOKEN}`
    }
  }
);

export default function useGraphRequest(query) {
  const [response, setResponse] = useState();

  useEffect(() => {
    const gqlQuery = gql`${query}`;
    client.request(gqlQuery)
      .then(setResponse);
  }, [query]);

  return {
    response
  };
}
