import React, { useEffect, useMemo } from 'react';
import { useSwipeable } from 'react-swipeable';
import useCarouselIndex from 'hooks/useCarouselIndex';
import useMinimizedText from 'hooks/useMinimizedText';
import CarouselBullet from './CarouselBullet'; 
import Fadebox from './Fadebox';

// Control auto-scroll within the carousel 
const duration = 10000;

// Control length of minimized text preview in words
const minimizedTextLimit = 30; 

const TextCarousel = ({ contentList }) => {
  // memoize textList so that useMinimizedText is not executed on every render
  const textList = useMemo(() => contentList.map(content => content.text), [contentList]);
  const [
    isMinimizedText,
    minimizedText,
    setIsMinimizedText
  ] = useMinimizedText(textList, minimizedTextLimit);
  
  const [
    carouselIndex, 
    setCarouselIndex, 
    decrementCarouselIndex,
    incrementCarouselIndex 
  ] = useCarouselIndex(contentList.length, duration);
 
  // Propagate current minimization state on index swap
  useEffect(() => {
    setIsMinimizedText(isMinimizedText);
  }, [carouselIndex, isMinimizedText, setIsMinimizedText]); 
  
  const swipeHandler = useSwipeable({
    onSwipedLeft: () => {
      incrementCarouselIndex();
    },
    onSwipedRight: () => {
      decrementCarouselIndex();
    }
  });

  const renderMobileTextBox = () => {
    return (
      <div className={isMinimizedText ? 'h-28': 'h-48'}>
        {isMinimizedText ? `${minimizedText[carouselIndex]} ` : `${contentList[carouselIndex].text} `}
        <button onClick={() => {setIsMinimizedText(!isMinimizedText);}}>
          <b>
            {isMinimizedText ? 'See more.' : 'See less.'}
          </b> 
        </button>
      </div>
    );
  };

  return (
    <div className="
      flex 
      flex-col 
      bg-background-secondary 
      rounded-md"
    >
      <div className="flex-none">
        <Fadebox id={contentList[carouselIndex]._id}>
          <div className="sm:px-3 sm:pt-3 md:px-4 md:pt-4 lg:px-5 lg:pt-5 xl:px-6 xl:pt-6" {...swipeHandler}>
            <div className="
              font-semibold
              py-2
              sm:text-base
              md:text-2xl
              lg:text-3xl
              xl:text-4xl 
              "
            >
              {contentList[carouselIndex].title}
            </div>
            <div className="
              font-light
              sm:hidden
              md:h-36
              md:my-4
              lg:h-32
              xl:text-xl
              "
            >
              {contentList[carouselIndex].text} 
            </div>
            <div className="
              font-light
              sm:text-sm
              md:hidden
              "
            >
              {renderMobileTextBox()} 
            </div>
            <div className="
              text-gray-500
              sm:text-xs
              md:pt-4            
              "
            >
              <b>{contentList[carouselIndex].author}</b>, Choro Client
            </div>
          </div>
        </Fadebox>
      </div>
      <div className="flex-none">
        <div className="flex justify-center">
          {
            contentList.map((content, i) => {
              return <CarouselBullet key={i} onClick={() => setCarouselIndex(i)} isSelected={i === carouselIndex} />;
            })
          }
        </div>
      </div>
    </div>
  );
};

export default TextCarousel;
