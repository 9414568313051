import React, { useState, useEffect, useContext } from 'react';
import { Form, Dropdown, Button, Popup, Icon, Input, Checkbox, TextArea, Confirm } from 'semantic-ui-react';
import { getServices } from 'requests/services';
import { editHelperProfile, deleteHelperProfile, getHelperProfile } from 'requests/helperProfiles';
import appContext from 'contexts/AppContext';
import Content from 'components/common/Content';
import RegisterForPayments from 'components/Payments/RegisterForPayments';
import { useParams, useHistory } from 'react-router-dom';
import SubTitleCard from 'components/common/SubTitleCard';
import Weekly from 'components/Calendar/Weekly';
import OfflineNotice from 'components/common/OfflineNotice';

const EditHelperForm = () => {
  const params = useParams();
  const history = useHistory();
  const [showConfirm, setShowConfirm] = useState();
  const { currentUser, online } = useContext(appContext);
  const [data, setData] = useState({
    weekly_schedule: [],
    offered_services: [],
    how_can_i_help: '',
    about: '',
    hobbies: '',
    languages: ''
  });
  const [services, setServices] = useState([]);

  //fetching profile data
  useEffect(() => {
    getHelperProfile(params.id)
      .then(profile => {
        const { services, weekly_schedule, hide_profile, how_can_i_help, about, hobbies, languages } = profile;
        const offered_services = services.map(service => service.id);
        setData({ weekly_schedule: weekly_schedule && weekly_schedule.availability, offered_services, hide_profile, how_can_i_help, about, hobbies, languages });
      });
  }, [params.id]);

  //fetching all available services
  useEffect(() => {
    getServices()
      .then(services => {
        services = services.map(item => {
          return { key: item.id, text: item.name, value: item.id };
        });
        setServices(services);
      });
  }, []);

  const handleChange = (e, { name, value, checked }) => {
    setData({ ...data, [name]: value || checked });
  };

  const handleSubmit = async () => {
    const { weekly_schedule, offered_services, hide_profile, how_can_i_help, about, hobbies } = data;

    // Temp fix, checked input in handleChange sets empty strings to undefined
    const languages = data.languages ? data.languages : '';

    editHelperProfile({
      id: params.id,
      user_id: currentUser.id,
      weekly_schedule,
      offered_services,
      hide_profile,
      how_can_i_help,
      about,
      hobbies,
      languages
    })
      .then(() => {
        history.push(`/helpers/${params.id}`);
      });
  };

  const removeFromHelpers = () => {
    deleteHelperProfile(params.id)
      .then(() => history.push('/'));
  };

  const paymentOptions = () => {
    let option;
    if (currentUser.charges_enabled) {
      option =  <p>You have payments enabled.</p>;
    } else if (currentUser.account_id) {
      option = <>
        <p>To enable payments please finish payment onboarding.</p>
        <RegisterForPayments title="Finish onboarding"/>
        <br></br>
        <br></br>
      </>;
    } else {
      option = <>
        <p>To enable payments please register.</p>
        <RegisterForPayments/>
        <br></br>
        <br></br>
      </>;
    }
    return (
      <Form.Field>
        <label>
          Payments
        </label>
        {option}
      </Form.Field>
    );
  };

  return (
    <Content padding>
      {!online && <OfflineNotice message="You need to be online to edit your profile."/>}
      <SubTitleCard>Edit your helper profile</SubTitleCard>
      <Form onSubmit={handleSubmit}>
        <Form.Field>
          <label>Profile Visibility</label>
          <Checkbox 
            toggle
            disabled={!online}
            name="hide_profile"
            checked={data.hide_profile}
            onChange={handleChange}
            label='Hide my Profile'
          />
        </Form.Field>
        <Form.Field required>
          <label>Type of help you want to offer</label>
          <Dropdown
            disabled={!online}
            name="offered_services"
            placeholder="Type of Help"
            fluid
            multiple
            search
            selection
            value={data.offered_services}
            onChange={handleChange}
            options={services}
          />
        </Form.Field>
        <Form.Field required>
          <label>
            Availability&nbsp;
            <Popup
              content="Please indicate the days of the week you are generally available and whether morning, afternoon or evening.  (examples: 'Thursday and Friday Afternoons', 'Weekend Mornings', 'Weekday Evenings')"
              trigger={<Icon color="grey" name="question circle outline" />}
            />
          </label>
          <Weekly 
            onChange={(value) => handleChange(null, { name: 'weekly_schedule', value })}
            weeklySchedule={data.weekly_schedule}
          />
        </Form.Field>
        <Form.Field required>
          <label>
            A little about myself?&nbsp;
            <Popup
              content="Tell us about yourself, your background, education and any other relevant work experience."
              trigger={<Icon color="grey" name="question circle outline" />}
            />
          </label>
          <TextArea
            disabled={!online}
            name="about"
            placeholder="Tell us about yourself, your background, education and any other relevant work experience."
            value={data.about}
            onChange={handleChange}
            rows={4}
            required
          />
        </Form.Field>
        <Form.Field required>
          <label>
            How can I help?&nbsp;
            <Popup
              content="Tell us about your skills and how you’d like to use them to help the seniors in your neighborhood (if you have any experience working with seniors, this would be a great place to mention it!)."
              trigger={<Icon color="grey" name="question circle outline" />}
            />
          </label>
          <TextArea
            disabled={!online}
            name="how_can_i_help"
            placeholder="Tell us about your skills and how you’d like to use them to help the seniors in your neighborhood (if you have any experience working with seniors, this would be a great place to mention it!)."
            value={data.how_can_i_help}
            onChange={handleChange}
            rows={4}
            required
          />
        </Form.Field>
        <Form.Field required>
          <label>
            What do I like to do for fun?&nbsp;
            <Popup
              content="Briefly tell us about a couple of your hobbies and passions. (75 char max)"
              trigger={<Icon color="grey" name="question circle outline" />}
            />
          </label>
          <Input
            disabled={!online}
            name="hobbies"
            placeholder="Briefly tell us about a couple of your hobbies and passions. (75 char max)"
            value={data.hobbies}
            onChange={handleChange}
            maxLength={75}
            required
          />
        </Form.Field>
        <Form.Field>
          <label>
            Additional Languages&nbsp;
            <Popup
              content="Please list any additional languages you can speak fluently. Example: Mandarin (75 char max)"
              trigger={<Icon color="grey" name="question circle outline" />}
            />
          </label>
          <Input
            disabled={!online}
            name="languages"
            placeholder="Please list any additional languages you can speak fluently. Example: Mandarin (75 char max)"
            value={data.languages}
            onChange={handleChange}
            maxLength={75}
          />
        </Form.Field>
        {paymentOptions()}
        <div style={{
          display: 'flex'
        }}>
          <Button primary disabled={!online} type="submit">
            Save
          </Button>
          <Confirm
            open={showConfirm}
            header="Remove Helper Profile?"
            content="You will no longer be able to take bookings."
            onCancel={() => setShowConfirm(false)}
            onConfirm={removeFromHelpers}
          />
          <Button negative disabled={!online} type="button" onClick={() => setShowConfirm(true)}>
            Remove from Helpers
          </Button>
        </div>
      </Form>
    </Content>
  );
};

export default EditHelperForm;
