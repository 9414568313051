import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMask } from '@fortawesome/free-solid-svg-icons';
import { adminImpersonate } from 'requests/admin';
import appContext from 'contexts/AppContext';

export default function Impersonate({ user, small }) {
  const { updateImpersonation } = useContext(appContext);
  const history = useHistory();
  const handleImpersonate = () => {
    adminImpersonate(user)
      .then((user) => {
        updateImpersonation(user);
        history.push(`/users/${user.id}`);
      });
  };
  return small ?
    <button onClick={handleImpersonate}>
      <FontAwesomeIcon icon={faMask}/>
    </button>
    :
    <Button primary basic onClick={handleImpersonate} className="m-0">
        Impersonate <FontAwesomeIcon icon={faMask}/>
    </Button>;
}
