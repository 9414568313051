import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { createConversation } from 'requests/chat';
import { ButtonSecondaryRound } from 'components/common/Button';
import appContext from 'contexts/AppContext';

/**
 * Direct copy of ChatButton with new button UI to avoid unintended changes to different app UI 
 */
const HelperChatButton = ({ senderId, recipientId, children }) => {
  const history = useHistory();
  const { currentUser } = useContext(appContext);

  recipientId = recipientId || (currentUser && currentUser.id);
  const startChat = () => {
    if (currentUser) {
      let params = { conversation: { person1_id: senderId, person2_id: recipientId } };
      createConversation(params)
        .then(() =>  history.push('../../chat', params));
    } else {
      history.push({
        pathname: '/login',
        state: {
          message: 'You must be logged in to chat.',
          from: location.pathname
        }
      });
    }
  };

  return (
    <ButtonSecondaryRound small={true} onClick={startChat}>{children}</ButtonSecondaryRound>
  );
};

export default HelperChatButton;
