import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Content from 'components/common/Content';
import { ButtonPrimarySquare } from 'components/common/Button';
import {
  Form,
  Popup,
  Icon,
  Input,
  Image
} from 'semantic-ui-react';
import { getUser, updateUser } from 'requests/users';
import { uploadImage } from 'requests/aws';
import useFetchLatLng from 'hooks/useFetchLatLng.jsx';
import appContext from 'contexts/AppContext';
import SubTitleCard from 'components/common/SubTitleCard';
import Payments from 'components/common/Payments/Payments';
import HeadingFive from 'components/common/Textbox/HeadingFive';
import OfflineNotice from 'components/common/OfflineNotice';

const formatAddress = (address, address2) => {
  return address2 ? `${address2},: ${address}` : address;
};

const EditProfileForm = () => {
  const params = useParams();
  const history = useHistory();
  const { setCurrentUser, currentUser, online } = useContext(appContext);
  const [data, setData] = useState({
    email: '',
    first_name: '',
    last_name: '',
    description: '',
    image: '',
    address: '',
    address2: '',
    preview: '',
    lat: null,
    lng: null,
    notifications: false
  });
  const { lat, lng } = useFetchLatLng(data.address);

  useEffect(() => {
    setData(data => {
      return {
        ...data,
        lat,
        lng
      };
    });
  }, [lat, lng]);
  useEffect(() => {
    getUser(params.id)
      .then(user => {
        const [address, address2] = user.address.split(',: ').reverse();
        setData({ ...user, address, address2 });
      });
  }, [params.id]);

  const handleChange = (e, { name, value }) => {
    setData({ ...data, [name]: value });
  };

  const handleUpload = async e => {
    const file = e.target.files[0];
    setData({ ...data, preview: file });
  };

  const handleSubmit = async () => {
    const {
      email,
      first_name,
      last_name,
      phone_number,
      description,
      address,
      address2,
      preview,
      notifications,
      batch_invoices,
      payment_method_id
    } = data;
    let { image } = data;
    //If file selecteed, show new image, else old image
    if (preview) {
      let res = await uploadImage(preview);
      const parser = new DOMParser();
      const parsed = parser.parseFromString(res, 'text/xml');
      image = parsed.getElementsByTagName('Location')[0].childNodes[0].nodeValue;
    }
    let updatedUser = {
      id: params.id,
      email,
      first_name,
      last_name,
      phone_number,
      description,
      image,
      address: formatAddress(address, address2),
      lat: data.lat,
      lng: data.lng,
      notifications,
      batch_invoices,
      payment_method_id
    };
    updateUser(updatedUser)
      .then(() => {
        setCurrentUser({ ...currentUser, ...data });
        history.push(`/users/${params.id}`);
      });
  };

  return (
    <Content padding>
      <Form className="sm:m-0 m-auto">
        {!online && <OfflineNotice message="You need to be online to edit your profile."/>}
        <SubTitleCard className="m-1">Edit Profile</SubTitleCard>
        <Form.Input
          label="E-mail"
          disabled={!online}
          name="email"
          type="email"
          placeholder="E-mail"
          value={data.email}
          onChange={handleChange}
          required
        />
        <Form.Input
          label="First Name"
          disabled={!online}
          name="first_name"
          placeholder="First name"
          value={data.first_name}
          onChange={handleChange}
          required
        />
        <Form.Input
          label="Last Name"
          disabled={!online}
          name="last_name"
          placeholder="Last name"
          value={data.last_name}
          onChange={handleChange}
          required
        />
        <Form.Input
          label="Phone Number"
          name="phone_number"
          placeholder="###-###-####"
          value={data.phone_number}
          onChange={handleChange}
          required
        />
        <Form.Field 
          required 
          style={{ paddingTop: '10px' }}
        >
          <label>
            Description
            <Popup
              flowing
              content={
                <div>
                  Please provide your:
                  <ul>
                    <li>relevant education and/or work background</li>
                    <li>experience helping seniors</li>
                    <li>other languages (if any)</li>
                    <li>a quirky fact, your passions</li>
                    <li>how you want to help!</li>
                  </ul>
                </div>
              }
              trigger={<Icon color="grey" name="question circle outline" />}
            />
          </label>
          <Form.TextArea
            disabled={!online}
            name="description"
            placeholder="Description"
            value={data.description}
            onChange={handleChange}
            required
          />
        </Form.Field>
        <Form.Field>
          <label>Profile Image</label>
          {data.image && (
            <Image
              src={data.preview ? URL.createObjectURL(data.preview) : data.image}
              size="medium"
              alt="image to upload"
            />
          )}
          <Input
            disabled={!online}
            name="image"
            type="file"
            accept="image/*"
            onChange={handleUpload}
          />
        </Form.Field>
        <Form.Input
          label="Address"
          disabled={!online}
          name="address"
          placeholder="Address"
          value={data.address}
          onChange={handleChange}
          loading={data.address && !lat ? true : false}
          required
        />
        <Form.Input
          disabled={!online}
          name="address2"
          placeholder="Appartment #, building, buzzer code"
          onChange={handleChange}
          value={data.address2}
        />
        <Form.Checkbox
          disabled={!online}
          name="notifications"
          checked={data.notifications}
          onChange={() => {
            setData({ ...data, notifications: !data.notifications });
          }}
          label={
            <label>
              I would like to receive notifications
              <Popup
                content="If checked, we will email you at the end of day if you received any new chat messages."
                trigger={<Icon color="grey" name="question circle outline" />}
              />
            </label>
          }
        />
        <Form.Checkbox
          disabled={!online}
          name="batch_invoices"
          checked={data && data.batch_invoices}
          onChange={() => {
            setData({ ...data, batch_invoices: !data.batch_invoices });
          }}
          label={
            <label>
              I would like to receive weekly invoices
              <Popup
                content="For Clients Only: If checked, we will invoice you once weekly instead of per booking."
                trigger={<Icon color="grey" name="question circle outline" />}
              />
            </label>
          }
        />
        {
          !currentUser.batch_invoices && data.batch_invoices &&
          <div className="p-7 pt-2">
            <HeadingFive>Payment Method</HeadingFive>
            <p>
              Please provide a payment method to be used for your monthly Choro invoice
            </p>
            <br/>
            <Payments user={currentUser} updatePaymentMethod={(payment_method_id) => setData({ ...data, payment_method_id })}/>
          </div>
        }
        <br/>
        <ButtonPrimarySquare
          onClick={handleSubmit}
          disabled={!online || lat !== data.lat && lng !== data.lng || (!currentUser.batch_invoices && data.batch_invoices && !data.payment_method_id)}
        >
          Submit
        </ButtonPrimarySquare>
        <br/>
      </Form>
    </Content>
  );
};

export default EditProfileForm;
