import React from 'react';
import ChoroSmallLogo from 'assets/icons/ChoroSmallLogo.png';
import styled from 'styled-components';

const Amount = styled.h2`
  font-weight: bold;
  font-size: 2rem;
  background: -webkit-linear-gradient(45deg, #F5F486, #DDB12D 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 -2px 0 rgba(255, 255, 255, 0.4);
`;

const Code = styled.p`
  font-weight: bold;
  text-shadow: 0 0.2px 0 #ccc,
  0 0.4px 0 #c9c9c9,
  0 0.6px 0 #bbb,
  0 0.8px 0 #b9b9b9,
  0 1px 0 #aaa;
`;

const Card = styled.div`
  display: flex;
  align-items: center;
  border-radius: 1rem;
  width: 50vw;
  height: 25vw;
  max-width: 30rem;
  max-height: 15rem;
  min-width: 20rem;
  min-height: 10rem;
  padding: 1.5rem;
  background-image:  repeating-radial-gradient( circle at 0 0, transparent 0, #ffffff 10px ), repeating-linear-gradient( #a6f0db55, #a6f0db );  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  `;

export default function GiftCard({ card }) {
  return (
    <div className="flex items-center justify-center w-full p-2">
      <Card>
        <div className="flex flex-col flex-shrink justify-between h-full flex-grow">
          <div>
            <img className="w-1/3" src={ChoroSmallLogo}/>
            <p className="text-logo text-md font-medium">Redeemable at Choro.ca</p>
          </div>
          <br/>
          <div>
            <Code># {card.code}</Code>
            <small className="text-logo">Use this code to redeem the gift card</small>
          </div>
        </div>
        <div className="flex flex-col justify-between h-full flex-grow items-center">
          <div className="flex flex-col justify-center flex-grow items-center">
            <h2 className="font-bold text-logo text-lg">Gift Card</h2>
            <Amount>{card.total/100}$</Amount>
          </div>
          <p className="text-logo text-xs">© 2019 NEIGHBORLI SERVICES INC.</p>
        </div>
      </Card>
    </div>
  );
}
