import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faCheckSquare, faTimes } from '@fortawesome/free-solid-svg-icons';

export default function BookingRequestStatus({ booking }) {
  const getStatus = (booking) => {
    let status = 'declined';
    for (let serviceRequest of booking.service_requests ) {
      if (serviceRequest.availability) {
        return 'accepted';
      } else if (!serviceRequest.reason_declined) {
        status = 'pending';
      }
    }
    return status;
  };

  let status = getStatus(booking);
  if (status === 'accepted') {
    return (<span>accepted <FontAwesomeIcon icon={faCheckSquare} /></span>);
  } else if (status === 'declined') {
    return (<span>declined <FontAwesomeIcon icon={faTimes} /></span>);
  } else {
    return (<span>pending <FontAwesomeIcon icon={faClock} /></span>);
  }
}
