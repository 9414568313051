import React, { useContext } from 'react';
import JoinHelpersInfo from './JoinHelpersInfo';
import JoinHelperForm from 'components/forms/JoinHelperForm';
import RegistrationForm from 'components/forms/RegistrationForm';
import appContext from 'contexts/AppContext';
import { Helmet } from 'react-helmet';

const JoinHelpers = () => {
  const { currentUser } = useContext(appContext);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Choro - Join Choro and make a difference in your neighbourhood</title>
        <meta
          name="description"
          content="Join Choro and make a difference in your neighbourhood"
        />
      </Helmet>
      <JoinHelpersInfo/>
      <div id="join-helpers">
        {currentUser ? <JoinHelperForm/> : <RegistrationForm embedded joinHelpers noRedirect/>}
      </div>
    </>
  );
};

export default JoinHelpers;
