import React, { useState } from 'react';
import { Button } from 'semantic-ui-react';
import { registerForPayments } from 'requests/payments';

const RegisterForPayments = ({ title }) => {
  const [disabled, setDisabled] = useState(false);
  const register = async () => {
    setDisabled(true);
    registerForPayments()
      .catch(function(error) {
        setDisabled(false);
        console.log(error);
      });
  };

  return (
    <Button primary basic onClick={register} disabled={disabled}>{title || 'Register for Payments'}</Button>
  );
};

export default RegisterForPayments;
