import React from 'react';
import { SecondaryHeading, SubHeading } from 'components/common/Textbox';
import Container from 'components/Landing/Container';
import { ButtonPrimaryRound } from 'components/common/Button';
import ImageCarousel from 'components/Landing/Carousel/ImageCarousel';

import tablet from 'assets/images/tablet_sm.jpg';
import vacuum from 'assets/images/vacuum_sm.jpg';
import cooking from 'assets/images/cooking_sm.jpg';
import farming from 'assets/images/farming_sm.jpg'; 
import groceries from 'assets/images/groceries_sm.jpg';

const serviceList = [
  {
    _id: 0,
    title: 'Help with Technology',
    description: 'Accessing online resources, digital forms and paperwork, iPhone and iPad lessons, help with virtual communications.',
    image: tablet,
    alt: 'Young man showing a senior man how to use a tablet.'
  },
  {
    _id: 1,
    title: 'Help with Light Housekeeping',
    description: 'A load of laundry, a quick vacuum, dusting, decluttering and organizing.',
    image: vacuum,
    alt: 'Young woman vacuuming the living room carpet.'
  },
  {
    _id: 2,
    title: 'Help in the Kitchen',
    description: 'Fridge clean out, grocery list preparation, meal preparation, kitchen floor mopping, kitchen tidy-up.',
    image: cooking,
    alt: 'Young man and older man happily collecting vegetables in a community garden.'
  },
  {
    _id: 3,
    title: 'Help Outside the Home',
    description: 'Lawn mowing, gardening, heavy lifting, neighborhood walk, donation drop-off, raking the leaves, walking the dog.',
    image: farming,
    alt: 'Young man and a older man laughing while chopping vegetables for a meal.'
  },
  {
    _id: 4,
    title: 'Help with Errands',
    description: 'Grocery pick-up, prescription pick-up, appointment drop-off.',
    image: groceries,
    alt: 'Smiling young woman dropping off groceries for an older woman.'
  }
];

const Services = () => {
  return (
    <Container>
      <div className="flex sm:flex-col-reverse items-center">
        <div className="flex-none sm:mb-8 sm:w-1/1 md:w-5/12 md:h-1/1">
          <ImageCarousel contentList={serviceList} />
        </div>
        <div className="flex-auto sm:order-last md:ml-12 lg:ml-16 xl:ml-24">
          <div className="sm:pb-6">
            <SecondaryHeading>
              Our&nbsp;Services
            </SecondaryHeading>
          </div>
          <div className="sm:hidden">
            <div className="sm:py-6 md:py-6">
              <SubHeading>
                Choro Helpers support you and your loved ones with everyday tasks so you have time for what matters most.
              </SubHeading>
            </div>
            <div className="flex justify-center">
              <ButtonPrimaryRound to="/helpers">Find&nbsp;Help&nbsp;Now!</ButtonPrimaryRound>
            </div>
          </div>
        </div>
        <div className="flex-auto text-center sm:order-first md:hidden">
          <div className="sm:py-6 md:py-6">
            <SubHeading>
              Choro Helpers support you and your loved ones with everyday tasks so you have time for what matters most.
            </SubHeading>
          </div>
          <div className="flex justify-center">
            <ButtonPrimaryRound to="/helpers">Find&nbsp;Help&nbsp;Now!</ButtonPrimaryRound>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Services;
