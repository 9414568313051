import React, { useContext } from 'react';
import MenuLink from 'components/common/MenuLink';
import appContext from 'contexts/AppContext';

const LogInOutMenuItem = ({ onClick }) => {
  const { setCurrentUser, currentUser, updateImpersonation, impersonating } = useContext(appContext);
  const logOut = () => {
    if (impersonating) {
      updateImpersonation(null);
    } else {
      localStorage.clear();
      setCurrentUser('');
    }
  };
  const menuItem = currentUser ? (
    <MenuLink
      to="/"
      onClick={() => {
        onClick();
        logOut();
      }}
      active="false" 
    >
      { impersonating ? 'Stop Impersonating' : 'Logout' }
    </MenuLink>
  ) : (
    <MenuLink name="Login" to="/login" onClick={onClick}>Log in</MenuLink>
  );
  return menuItem;
};

export default LogInOutMenuItem;
