import React from 'react'; 

const TextBodyLg = ({ children }) => {
  return (
    <p className="
      text-base
      leading-5
      break-words
      " 
    >
      {children}
    </p>
  );
};

export default TextBodyLg;
