import React from 'react';
import { Route } from 'react-router-dom';
import JoinHelpers from './JoinHelpers';
import EditHelperForm from 'components/forms/EditHelperForm';
import FindHelpers from 'components/FindHelpers/FindHelpers.jsx';

const routes = [
  <Route key="helpers-new" path="/helpers/new" ><JoinHelpers/></Route>,
  <Route key="helpers-edit" path="/helpers/:id/edit" ><EditHelperForm/></Route>,
  <Route key="helpers" path="/helpers" exact><FindHelpers/></Route>
];

export default routes;
