import React from 'react';
import ButtonRoot from './Shared/ButtonRoot';

const ButtonPrimaryOutline = (props) => {
  return (
    <ButtonRoot 
      className="
        border-2 border-text-gray-80
        font-semibold 
        rounded 
        mr-2
        hover:no-underline
        hover:text-black
      "
      {...props}
    />
  );
};

export default ButtonPrimaryOutline;
