import React, { useState, useEffect } from 'react';
import { Modal, Form, Button, Message, Popup, Icon } from 'semantic-ui-react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { LinkReg } from 'components/common/Textbox';
import styled from 'styled-components';
import PaymentMethod from './PaymentMethod';
import { estimate, removePaymentMethod } from 'requests/payments';
import { useHistory } from 'react-router-dom';

const CardContainer = styled.div`
  margin-bottom: 10px;
  border: 1px solid rgba(34,36,38,.15);
  padding: .67857143em 1em;
  border-radius: .28571429rem;
`;

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      margin: '5px 15px',
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '1em',
      '::placeholder': {
        color: 'rgba(0,0,0,.4)'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }
};

const PopupFont = {
  'font-family': '\'Montserrat\', -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif'
};

function StripeCardSetupForm({ clientSecret, paymentMethods, setPaymentMethods, handleSuccess, booking }) {
  const [paymentInfo, setPaymentInfo] = useState(false);
  const [showNewPayment, setShowNewPayment] = useState(paymentMethods ? false : true);
  const [savedPaymentMethod, setSavedPaymentMethod] = useState(paymentMethods ? paymentMethods[0].id : null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({});
  const [bookingCost, setBookingCost] = useState('');
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();

  useEffect(() => {
    if (paymentMethods) {
      setSavedPaymentMethod(paymentMethods[0].id);
      setShowNewPayment(false);
    }
  }, [paymentMethods]);

  useEffect(() => {
    estimate(booking.services.split(/,\s*/))
      .then((estimate) => {
        setBookingCost('$' + estimate);
      })
      .catch(() => {
        history.push('/not-authorized');
      });
  }, [booking]);

  const validateForm = () => {
    return !loading && !message.success && paymentInfo.terms && paymentInfo.authorize && (showNewPayment ? paymentInfo.billingAddress && paymentInfo.firstName && paymentInfo.lastName : savedPaymentMethod);
  };

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    setLoading(true);
    if (!stripe || !elements || !clientSecret) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      setMessage({ success: false, content: 'Payment could not be processed.' });
      return;
    }

    if (!showNewPayment && savedPaymentMethod) {
      setLoading(false);
      return handleSuccess(savedPaymentMethod);
    }

    const result = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: `${paymentInfo.firstName} ${paymentInfo.lastName}`,
          address: paymentInfo.billingAddress
        }
      }
    });
    if (result.error) {
      // Show error to your customer (e.g., insufficient funds)
      setMessage({ success: false, content: result.error.message });
      setLoading(false);
      console.log(result.error.message);
    } else {
      // The payment has been processed!
      setMessage({
        success: true, 
        content: 'Success, payment method recieved.'
      });
      setLoading(false);
      handleSuccess(result.setupIntent.payment_method);
    }
  };

  const handleRemovePaymentMethod = (method) => {
    removePaymentMethod(method.id)
      .then(() => {
        if (savedPaymentMethod === method.id) {
          setSavedPaymentMethod(null);
          setPaymentMethods(current => {
            let filtered = current.filter(currentMethod => currentMethod.id !== method.id);
            if (filtered.length < 1) {
              setShowNewPayment(true);
              return null;
            }
            return filtered;
          }
          );
        }
      });
  };

  return (
    <>
      <Modal.Header style={PopupFont}>Pay for your booking!</Modal.Header>
      <Form style={{ margin: '15px' }}>
        <div className="flex flex-col gap-2 mb-2">
          <div className="font-bold">Payment Summary</div>
          <div><span className="font-medium">Services: </span>{booking.services}</div>
          <div><span className="font-medium">Total: </span>{bookingCost}</div>
        </div>
        <div className="flex justify-between items-center mb-2">
          <strong>Card</strong>
          { paymentMethods && <Button size="mini" onClick={() => setShowNewPayment(!showNewPayment)}>{ showNewPayment ? 'Saved Card' : 'New Card' }</Button> }
        </div>
        {
          paymentMethods && !showNewPayment ?
            <div className="flex flex-column gap-2 mb-3">
              {paymentMethods.map((method, index) => <PaymentMethod key={index} method={method} selected={savedPaymentMethod} setPaymentMethod={setSavedPaymentMethod} removePaymentMethod={handleRemovePaymentMethod}/>)}
            </div>
            :
            <>
              <CardContainer>
                <CardElement options={CARD_ELEMENT_OPTIONS} />
              </CardContainer>
              <Form.Group unstackable widths={2}>
                <Form.Input 
                  label='First name'
                  placeholder='First name' 
                  value={paymentInfo.firstName}
                  onChange={(e)=>{setPaymentInfo({ ...paymentInfo, firstName: e.target.value });}}
                />
                <Form.Input 
                  label='Last name' 
                  placeholder='Last name' 
                  value={paymentInfo.lastName}
                  onChange={(e)=>{setPaymentInfo({ ...paymentInfo, lastName: e.target.value });}}
                />
              </Form.Group>
              <Form.Group widths={2} className="mt-3">
                <Form.Input 
                  label='Billing Address' 
                  placeholder='Address'
                  value={paymentInfo.billingAddress}
                  onChange={(e)=>{setPaymentInfo({ ...paymentInfo, billingAddress: e.target.value });}}
                />
                <Form.Input label='Phone' placeholder='Phone' />
              </Form.Group>
            </>
        }
        <Form.Checkbox
          name="terms"
          checked={paymentInfo.terms}
          className="mr-2"
          onChange={() => {
            setPaymentInfo({ ...paymentInfo, terms: !paymentInfo.terms });
          }}
          label={
            <label>
              I have read and agree to the&nbsp; 
              <LinkReg href={'/cancellation-policy'} rel="noreferrer" target="_blank">Cancellation Policy</LinkReg>,&nbsp; 
              <LinkReg href={'/terms-and-conditions'} rel="noreferrer" target="_blank">Terms and Conditions</LinkReg>&nbsp;and&nbsp; 
              <LinkReg href={'/privacy'} rel="noreferrer" target="_blank">Privacy Policy</LinkReg>
              <Popup
                content="Disclaimer:
                To the maximum extent permitted by applicable law, 
                we exclude all representations, warranties and conditions relating 
                to our website and the use of this website."
                trigger={<Icon color="grey" name="question circle outline" />}
              />
            </label>
          }
          required
        />
        <Form.Checkbox
          name="authorize"
          checked={paymentInfo.authorize}
          className="mr-2"
          onChange={() => {
            setPaymentInfo({ ...paymentInfo, authorize: !paymentInfo.authorize });
          }}
          label={
            <label>
              I authorise Choro to send instructions to take payments from my card account
              on completion of services rendered by {booking.name_of_helper}.
            </label>
          }
          required
        />
        <Button
          margin
          primary basic
          disabled={!validateForm()}
          onClick={handleSubmit}
        >
          {loading || !clientSecret ?
            <Icon name='circle notched' loading style={{ margin: 0 }}/>
            : 'Pay Now'
          }
        </Button>
      </Form>
      {
        message.content &&
        <Message attached='bottom' negative={!message.success} positive={message.success}>
          {message.success ? 
            <Icon name='check circle outline' />
            :
            <Icon name='times circle outline' />
          }
          {message.content}
        </Message>
      }
    </>
  );
}

export default StripeCardSetupForm;

