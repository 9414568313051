import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import vancouvericon from 'assets/images/vancouvericon.png';
import victoriaicon from 'assets/images/victoriaicon.png';


const ContactDetails = () => {
  return (
    <>
      <br></br>
      <br></br>
      <div className="flex flex-col text-lg font-medium items-center">
        <p><FontAwesomeIcon icon={faPhone}/>&nbsp;Call us:</p>
        <a className="text-logo" href="tel:604-774-3708">604-774-3708</a>
      </div>
      <br></br>
      <br></br>
      <div className="flex flex-col text-lg font-medium items-center">
        <p className="mb-4"><FontAwesomeIcon icon={faEnvelope}/>&nbsp;Email us:</p>
        <div className="flex flex-wrap items-center justify-center gap-12">
          <div className="w-60 flex flex-col text-lg font-medium items-center">
            <img className="mb-2 h-12" src={vancouvericon} alt="vancouver"/>
            <p>In Greater Vancouver:</p>
            <a className="text-logo" href="mailto: support@choro.ca">support@choro.ca</a>
          </div>
          <div className="w-60 flex flex-col text-lg font-medium items-center">
            <img className="mb-2 h-12" src={victoriaicon} alt="victoria"/>
            <p>In Victoria:</p>
            <a className="text-logo" href="mailto: victoriasupport@choro.ca">victoriasupport@choro.ca</a>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactDetails;
