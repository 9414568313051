import React from 'react';

const DesktopSpecificContent = ({ children }) => {
  return (
    <div className="
      sm:hidden
      "
    >
      {children}
    </div>
  );
};

export default DesktopSpecificContent;
