import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Popup } from 'semantic-ui-react';
import Status from 'components/common/Status';
import ExtraCharge from 'screens/bookings/ExtraCharge';
import { readableDate, readableTime } from 'helpers/general';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList, faMapMarkerAlt, faPhone, faEnvelope, faLongArrowAltLeft, faClock, faBan } from '@fortawesome/free-solid-svg-icons';
import { faArrowAltCircleLeft } from '@fortawesome/free-regular-svg-icons';
import HeadingSix from 'components/common/Textbox/HeadingSix';
import HeadingFive from 'components/common/Textbox/HeadingFive';
import HeadingFour from 'components/common/Textbox/HeadingFour';
import styled from 'styled-components';
import appContext from 'contexts/AppContext';
import EditBooking from './EditBooking';
import WeeklyDate from 'components/Calendar/WeeklyDate';

const Grid = styled.div`
  display: grid;
  gap: 1rem 0.5rem;
  grid-template-columns: min-content 1fr;
  margin-top: 1rem;
`;

const BookingInfo = ({ booking }) => {
  const { currentUser } = useContext(appContext);

  const formatDateShown = () => {
    if (booking.date) {
      return `${readableDate(booking.date)} - ${readableTime(new Date(booking.date).getTime() + (booking.services && booking.services.split(',').length*30*60*1000))}`;
    } else {
      return booking.services && `${booking.services.split(',').length * 0.5} hour${booking.services.split(',').length > 2 ? 's' : ''}`;
    }
  };
  
  return (
    booking && 
        <>
          <Link to="/bookings" className="mb-3 inline-block">
            <HeadingFive><FontAwesomeIcon icon={faArrowAltCircleLeft} />&nbsp;&nbsp;Back</HeadingFive>
          </Link>
          <div className="divide-y divide-ui-gray-80 text-sm">
            <div className="py-3 flex gap-2 cursor-pointer">
              <HeadingFour>{booking.first_name_of_helper ? 'Booking Details' : 'Request Details'}</HeadingFour>
              <EditBooking booking={booking}/>
            </div>
            <div className="py-3 flex flex-col">
              <div className="flex justify-between items-center">
                <HeadingFive>
                  <Link key={booking} to={`/users/${booking.seeker_user_id}`}>
                    {booking.first_name_of_seeker}
                  </Link>
                  {booking.first_name_of_helper && <>
                    <FontAwesomeIcon className="mx-2" icon={faLongArrowAltLeft} />
                    <Popup
                      trigger={ 
                        <Link className="relative" key={booking} to={`/users/${booking.user_id}`}>
                          {booking.first_name_of_helper}
                        </Link>}
                      size={'mini'}
                      wide={'very'}
                      position='top center'
                    >
                    Helper
                    </Popup>
                  </>}
                </HeadingFive>
                <div className="flex flex-wrap gap-2">
                  {booking.skip_payment && 
                    <div>
                      offline payment <FontAwesomeIcon icon={faBan} />
                    </div>
                  }
                  <Status status={booking.status} date={booking.date}/>
                </div>
              </div>
              <Grid>
                <FontAwesomeIcon className="text-lg" icon={faClipboardList} /> {booking.services}
                <FontAwesomeIcon className="text-lg" icon={faMapMarkerAlt} /> {booking.address}
                <FontAwesomeIcon className="text-lg" icon={faClock} /> {formatDateShown()}
                {currentUser.type && 
                  <>
                    <FontAwesomeIcon className="text-lg" icon={faPhone} /><a className="text-blue-500" href={`tel:${booking.phone_number}`}>{booking.phone_number}</a>
                    <FontAwesomeIcon className="text-lg" icon={faEnvelope} /><a className="text-blue-500" href={`mailto: ${booking.email}`}>{booking.email}</a>
                  </>
                }
              </Grid>
            </div>
            <div className="py-3 flex flex-col gap-2">
              {booking.requested_time && <div><HeadingSix>Client Time Preferred</HeadingSix>{booking.requested_time}</div>}
              <div><HeadingSix>Additional Information</HeadingSix>{booking.additional_info}</div>
              {booking.admin_notes &&
                <div><HeadingSix>Notes</HeadingSix>{booking.admin_notes}</div>
              }
              {booking.coupon &&
                <div>
                  <HeadingSix>Discounts</HeadingSix>
                  <p className="text-btn-primary">{booking.coupon.name} : <strong>{booking.coupon.percent_off}% off</strong></p>
                </div>
              }
            </div>
            {booking.requested_time_range && booking.requested_time_range.length > 0 &&
              <div className="py-3 flex flex-col gap-2">
                <HeadingFive>Client Preffered Time</HeadingFive>
                <WeeklyDate disabled weeklySchedule={booking.requested_time_range}/>
              </div>
            }
            {
              booking.emergency_contact &&
              <div className="py-3 flex flex-col gap-2">
                <HeadingSix>Emergency Contact Information</HeadingSix>
                <span className='mr-2'><strong>Name: </strong>{booking.emergency_contact.name || '-'}</span>
                <span className='mr-2'><strong>Relation: </strong>{booking.emergency_contact.relation || ''}</span>
                <span className='mr-2'><strong>Phone Number: </strong>{booking.emergency_contact.phone_number || ''}</span>
              </div>
            }
            {booking.extra_charges && booking.extra_charges.map((charge, index) => {
              return <ExtraCharge key={index} charge={charge}/>;
            })}
          </div>
        </>
  );
};

export default BookingInfo;
