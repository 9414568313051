import React from 'react';
import { HeadingTwo } from 'components/common/Textbox';

import HeaderBookingModal from './HelperBookingModal';
import HeaderChatButton from './HelperChatButton';
import useFetchNeighborhood from 'hooks/useFetchNeighborhood';
import { HeadingFive, TextBodyXLg } from 'components/common/Textbox';
import NeighborhoodMap from './NeighborhoodMap';

const HelperProfileInformation = ({ currentUser, helperProfile }) => {
  const neighborhood = useFetchNeighborhood(helperProfile.address);

  return (
    <div className="flex flex-col xs:self-stretch self-start items-center xs:items-stretch sm:gap-4 md:gap-6 border-2 p-4 border-ui-gray-40 rounded-md md:max-w-xs">
      <div className="w-full">
        <img 
          className="h-full w-full object-cover rounded-lg"
          src={helperProfile.image}
          alt={`${helperProfile.first_name}'s profile picture`}
        />
      </div>
      <HeadingTwo center>{helperProfile.first_name}</HeadingTwo>
      <HeaderBookingModal seekerInfo={currentUser} helperInfo={helperProfile} />
      <HeaderChatButton senderId={currentUser && currentUser.id} recipientId={helperProfile.id}>
            Contact {helperProfile.first_name}
      </HeaderChatButton>
      <div className="xs:hidden flex flex-col text-center sm:w-3/4 md:w-1/1">
        <div className="pb-1">
          <HeadingFive>My Neighborhood</HeadingFive>
        </div>
        <div className="pb-2">
          <span className="text-ui-gray-80">
            <TextBodyXLg>{neighborhood}</TextBodyXLg>
          </span>
        </div>
        <NeighborhoodMap {...helperProfile} />
      </div>
    </div>
  );
};

export default HelperProfileInformation;
