import React, { Fragment, useMemo } from 'react';
import faqsData from './faqsData';
import { PageMargined, DesktopSpecificContent, MobileSpecificContent } from 'components/common/Layout';
import { HeadingTwo, HeadingThree, HeadingFour, HeadingFive } from 'components/common/Textbox';
import DynamicAccordion from 'components/common/DynamicAccordion';
import useGraphRequest from 'hooks/useGraphRequest';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';

const query =`
{
	informationSheet(id: "61K9lLaDVZOVjlSueKkRTr")
	{
    pdf {
      url
    }
  }
}`;

const Faqs = () => {
  const { response } = useGraphRequest(query);
  const faqsContent = useMemo(() => faqsData.map((section, i) => (
    <Fragment key={i}>
      <div className="
        sm:my-6 
        md:my-12
        text-center
      ">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Choro - Learn more about Choro senior care</title>
          <meta
            name="description"
            content="Learn more about Choro senior care"
          />
        </Helmet>
        <DesktopSpecificContent>
          <HeadingFour>{section.heading}</HeadingFour>
        </DesktopSpecificContent>
        <MobileSpecificContent>
          <HeadingFive>{section.heading}</HeadingFive>
        </MobileSpecificContent>
      </div>
      <div className="
        sm:w-1/1
        md:w-3/4
        lg:w-1/2
        xl:w-1/3
      ">
        <DynamicAccordion contentList={section.content} />
      </div>
    </Fragment>
  )), [faqsData]);

  return (
    <div className="bg-background-secondary">
      <PageMargined>
        <div className="
        flex
        flex-col
        items-center
        sm:py-12
        md:py-24
        "
        >
          <span className="
          text-center
        "
          >
            <DesktopSpecificContent>
              <HeadingTwo>Frequently Asked Questions</HeadingTwo>
            </DesktopSpecificContent>
            <MobileSpecificContent>
              <HeadingThree>Frequently Asked Questions</HeadingThree>
            </MobileSpecificContent>
          </span>
          <div className="mt-4">
            <HeadingFive>
              <a href={response && response.informationSheet.pdf.url}>
                <FontAwesomeIcon icon={faFileDownload} /> Download our information sheet
              </a>
            </HeadingFive>
          </div>
          {faqsContent}
        </div>
      </PageMargined>
    </div>
  );
};

export default Faqs;
