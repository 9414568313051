import React from 'react';
import Search from './Search';
import Services from './Services';
import Why from './Why';
import Testimonials from './Testimonials';
import Questions from './Questions';
import Thanks from './Thanks';
import Join from './Join';
import HorizontalRule from 'components/Landing/HorizontalRule';

const LandingPage = () => {
  return (
    <>
      <Search />
      <Services />
      <Why />
      <HorizontalRule />
      <Testimonials />
      <Questions />
      <HorizontalRule />
      <Thanks />
      <Join />
    </>
  );
};

export default LandingPage;
