import React from 'react';
import { Route } from 'react-router-dom';
import JoinSeekerForm from 'components/forms/JoinSeekerForm';
import EditSeekerForm from 'components/forms/EditSeekerForm';
import Seekers from 'components/seekers';

const routes = [
  <Route key="seeker-new" path="/seekers/new" ><JoinSeekerForm/></Route>,
  <Route key="seeker-edit" path="/seekers/:id/edit"><EditSeekerForm/></Route>,
  <Route key="seekers" path="/seekers" exact><Seekers/></Route>
];

export default routes;
