import React, { useState, useEffect, useContext } from 'react';
import { Form, Dropdown, Button, Popup, Icon, Input } from 'semantic-ui-react';
import appContext from 'contexts/AppContext';
import { getSeekerProfile, updateSeekerProfile, deleteSeekerProfile } from 'requests/seekerProfiles';
import { getServices } from 'requests/services';

const EditSeekerForm = props => {
  const { currentUser } = useContext(appContext);
  const [data, setData] = useState({
    availability: '',
    seeking_services: []
  });
  const [services, setServices] = useState([]);

  //fetching profile data
  useEffect(() => {
    getSeekerProfile(props.match.params.id)
      .then(profile => {
        const { services, availability } = profile;
        const seeking_services = services.map(service => service.id);
        setData({ availability, seeking_services });
      });
  }, [props.match.params.id]);

  //fetching all available services
  useEffect(() => {
    getServices()
      .then(services => {
        services = services.map(item => {
          return { key: item.id, text: item.name, value: item.id };
        });
        setServices(services);
      });
  }, []);

  const handleChange = (e, { name, value }) => {
    setData({ ...data, [name]: value });
  };

  const handleSubmit = async () => {
    const { availability, seeking_services } = data;
    updateSeekerProfile({
      id: props.match.params.id,
      user_id: currentUser.id,
      availability,
      seeking_services
    })
      .then(() => {
        props.history.push(`/seekers/${props.match.params.id}`);
      });
  };

  const removeFromSeekers = async () => {
    deleteSeekerProfile(props.match.params.id)
      .then(() => {
        props.history.push('/');
      });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Field required>
        <label>Type of help you need</label>
        <Dropdown
          name="seeking_services"
          placeholder="Type of Help"
          fluid
          multiple
          search
          selection
          value={data.seeking_services}
          onChange={handleChange}
          options={services}
        />
      </Form.Field>

      <Form.Field required>
        <label>
          Availability
          <Popup
            content="When would you like help?"
            trigger={<Icon color="grey" name="question circle outline" />}
          />
        </label>
        <Input
          name="availability"
          placeholder="Availability"
          value={data.availability}
          onChange={handleChange}
          required
        />
      </Form.Field>
      <div style={{
        display: 'flex'
      }}>
        <Button primary type="submit">
          Submit
        </Button>
        <Button negative type="button" onClick={removeFromSeekers}>
          Remove from Seekers
        </Button>
      </div>
    </Form>
  );
};

export default EditSeekerForm;
