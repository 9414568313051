import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { Modal, Form, Message, Ref, Popup, Icon, Loader } from 'semantic-ui-react';
import Button from 'components/common/Button/ButtonPrimarySquare';
import OutlineButton from 'components/common/Button/ButtonPrimaryOutline';
import { ButtonPrimaryRound } from 'components/common/Button';
import useUser from 'hooks/useUser';
import { useHistory, useLocation } from 'react-router-dom';
import appContext from 'contexts/AppContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark } from '@fortawesome/free-solid-svg-icons';
import locationAutocomplete from 'services/locationAutocomplete';
import { DateTimePicker } from 'react-widgets';
import 'react-widgets/dist/css/react-widgets.css';
import Moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import { getServices } from 'requests/services';
import { createBooking } from 'requests/bookings';
import { estimate } from 'requests/payments';
import ServiceSelector from 'components/common/ServiceSelector';
import styled from 'styled-components';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import HeadingThree from 'components/common/Textbox/HeadingThree';
import HeadingFive from 'components/common/Textbox/HeadingFive';
import Payments from 'components/common/Payments/Payments';
import PromotionCode from 'components/common/Payments/PromotionCode';
import GiftCardCode from 'components/common/Payments/GiftCardCode';

const ModalContainer = styled.div`
  padding: 3rem 4rem 4rem 4rem;
  max-height: 80vh;
  background-color: white;
  color: black;
  overflow-y: scroll;
  .field {
    margin: 0 !important;
  }
  label {
    font-weight: normal !important;
    font-size: .92857143em !important;
  }
`;

const PopupStyle = {
  height: '80%',
  position: 'absolute',
  overflow: 'visible',
  top: '50%',
  left: '50%',
  marginRight: '-50%',
  transform: 'translate(-50%, -50%)'
};


Moment.locale('en');
momentLocalizer();

/**
 * Direct copy of BookingPopup with new button UI to avoid unintended changes to different app UI 
 */
function HelperBookingModal({ seekerInfo, helperInfo, asHelper, booking, outlineButton }) {
  const { currentUser, online } = useContext(appContext);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});
  const { user: seeker } = useUser(seekerInfo);
  const { user: helper } = useUser(helperInfo);
  const [waiting, setWaiting] = useState(false);
  const [helpOptions, setHelpOptions] = useState([]);
  const [paymentStep, setPaymentStep] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const locationSearchRef = useRef();

  const [errors, setErrors] = useState({
    name_of_client: null,
    address: null,
    phone_number: null,
    date: null,
    email: null,
    checkboxError: null,
    help_needed: null,
    help_for: null,
    additional_info: null,
    supplies: null
  });

  const measuredRef = useCallback(node => {
    if (node !== null) {
      const input = node.querySelector('input');
      locationAutocomplete(input);
      locationSearchRef.current = input;
    }
  }, []);

  useEffect(() => {
    if (seeker) {
      setData({
        name_of_client: (booking && booking.name_of_client) || `${seeker.first_name} ${seeker.last_name}`,
        address: (booking && booking.address) || seeker.address,
        phone_number: booking && booking.phone_number,
        email: (booking && booking.email) || seeker.email,
        emergency_contact: {
          name: booking && booking.emergency_contact && booking.emergency_contact.name,
          relation: booking && booking.emergency_contact && booking.emergency_contact.relation, 
          phone_number: booking && booking.emergency_contact && booking.emergency_contact.phone_number
        },
        help_needed: booking && booking.services ? booking.services.split(/,\s*/) : [],
        additional_info: booking && booking.additional_info,
        help_for: booking && (booking.address != seeker.address) ? 'someone else' : 'myself',
        status:'pending',
        cost: 25.99,
        supplies: false
      });
    }
  }, [booking, seeker]);

  useEffect(() => {
    getServices()
      .then(services => {
        services = services.map(item => {
          return { key: item.id, text: item.name, value: item.name };
        });
        setHelpOptions(services);
      });
  }, []);

  const checkError = (update = true) => {
    let newErrors = { ...errors };
    let error = false;
    for (const label in data) {
      if ((!data[label] || (Array.isArray(data[label]) && data[label].length === 0)) && Object.keys(errors).includes(label)) {
        newErrors[label] =  'Cannot be left blank';
        error = true;
      } else {
        newErrors[label] = null;
      }
    }
    if (!asHelper && !data.stripe_payment_method && totalCost(true) > 0) {
      newErrors['payment'] =  'You must enter a payment!';
    }
    update && setErrors(newErrors);
    return error;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { help_needed } = data;
    if (checkError()) return;
    const services = help_needed.join(', ');
    setWaiting(true);
    createBooking({ 
      ...data, 
      services, 
      seeker_user_id: seeker.id,
      user_id: helper.id,
      name_of_client: data.help_for == 'myself' ? `${seeker.first_name} ${seeker.last_name}` :data.name_of_client, 
      address: data.help_for == 'myself' ? seeker.address : data.address,
      email: seeker.email,
      stripe_coupon_id: data.discount && data.discount.id,
      gift_card_id: data.gift_card && data.gift_card.id
    })
      .then((booking) => {
        if (asHelper) {
          setOpen(false);
          history.push(`/bookings/${booking.id}`);
        } else {
          history.push('/request_sent');
        }
      })
      .catch(error => {
        setWaiting(false);
        console.log(error);
      });
  };

  const handleChange = (e, type) => {
    let { name, value } = e.target || {};
    if (!name && !value) {
      name = type.name;
      value = type.value;
    }
    if (type === 'emergency') {
      setData({ ...data, emergency_contact: { ...data.emergency_contact, [name]: value } });
    } else {
      setData({ ...data, [name]: value });
    }
    errors[name] && setErrors({ ...errors, [name]: null });
  };

  const handleAddressChange = () => {
    setTimeout(() => {
      setErrors({ ...errors, address: null });
      setData(data => {
        return { ...data, address: locationSearchRef.current.value };
      });
    }, 10);
  };

  const changeHelpOptions = (value) => {
    estimate(value)
      .then(cost => {
        setData({ ...data, help_needed: value, cost });
      });
  };

  const handleCheckboxChange = (e, boxClicked) => {
    setErrors({ ...errors, help_for: null });
    if (boxClicked === 'someone else') {
      setData({ ...data,
        help_for: boxClicked,
        name_of_client: null,
        address: null
      });
    } else if (seeker) {
      setData({ ...data, 
        name_of_client: (booking && booking.name_of_client) || `${seeker.first_name} ${seeker.last_name}`, 
        address: (booking && booking.address) || seeker.address,
        help_for: boxClicked,
        email: seeker.email
      });
    }
  };

  const handleDateChange = (date) => {
    if (Moment(date).isBefore(Moment())) {
      setData({ ...data, date: undefined });
      setErrors({ ...errors, date: 'You cannot select dates in the past' });
    } else {
      setData({ ...data, date });
      setErrors({ ...errors, date: null });
    }
  };

  const openModal = () => {
    if (!currentUser) {
      history.push({
        pathname: '/login',
        state: {
          message: 'You must be logged in to book help.',
          from: location.pathname
        }
      });
    } else {
      setOpen(true);
    }
  };

  const servicesBreakdown = () => {
    if (data.help_needed) {
      const servicesObj = {};
      for (let service of data.help_needed) {
        if (!servicesObj[service]) {
          servicesObj[service] = 1;
        } else {
          servicesObj[service] += 1;
        }
      }
      return data.help_needed.length > 0 ? Object.entries(servicesObj).map(([service, amount], index) => (
        <p key={index} className="flex justify-between"><span>{service} :</span> <strong>{amount/2} hour{amount > 2 && 's'} </strong></p>
      )) : <p>Minimum Charge</p>;
    } else {
      return <p>Minimum Charge</p>;
    }
  };

  const discountAmount = () => {
    return data.discount ? Math.round(100*(data.cost * data.discount.percent_off/100))/100 : 0;
  };

  const totalCost = (withGiftCard = false) => {
    return Math.max(Math.round((data.cost - discountAmount())*100 - (withGiftCard && data.gift_card ? data.gift_card.remaining_balance : 0))/100, 0);
  };

  return (
    (currentUser && currentUser.type === 'Admin') || !currentUser ?
      <Popup
        trigger={   
          <span>
            <ButtonPrimaryRound disabled small={true}>
            Book {helper.first_name} <FontAwesomeIcon icon={faBookmark}/>
            </ButtonPrimaryRound>
          </span>} 
        size={'mini'}
        wide={'very'}
        position='top center'
      >
        {!currentUser ? 'You must be logged in to book this helper.' : 'You cannot book a helper as an admin'}
      </Popup>
      :
      <Modal
        style={PopupStyle}
        onClose={() => setOpen(false)}
        onOpen={openModal}
        open={open}
        disabled={!online}
        closeIcon
        trigger={outlineButton ?
          <OutlineButton disabled={!online} small>
            {booking ? 'Rebook' : 'Book'} {asHelper ? `for ${seeker.first_name}` : helper.first_name }
          </OutlineButton>
          :
          <ButtonPrimaryRound disabled={!online} small={true}>
            {booking ? 'Rebook' : 'Book'} {asHelper ? `for ${seeker.first_name}` : helper.first_name }
          </ButtonPrimaryRound>
        }
      >
        <ModalContainer>
          {paymentStep ?
            <div className="flex flex-col gap-8">
              <a href="#" onClick={() => setPaymentStep(false)}>
                <FontAwesomeIcon icon={faArrowLeft} />
              </a>
              <div>
                <HeadingFive>Payment Methods</HeadingFive>
                <br/>
                <p>
                  Please provide payment method to confirm the booking once your helper has accepted. You will not be charged until after the service has been completed.                
                </p>
              </div>
              <HeadingFive>Estimated Total</HeadingFive>
              <div className="flex justify-between">
                <div className="w-1/3 flex flex-col gap-2">
                  {servicesBreakdown()}
                  {
                    data.discount && <p className="text-btn-primary">{data.discount.name} : <strong>{data.discount.percent_off}% off</strong></p>
                  }
                  <hr/>
                  {
                    data.gift_card ?
                      <>
                        <p className="self-end"><s>${totalCost()}</s></p>
                        <p className="text-btn-primary flex justify-between"><span>Gift Card :</span> <strong> - ${Math.min(data.cost * 100, data.gift_card.remaining_balance)/100}</strong></p>
                        <hr/>
                        <p className="self-end">${totalCost(true)}</p>
                      </>
                      :
                      <p className="self-end">${totalCost()}</p>
                  }                
                </div>
              </div>
              <GiftCardCode setGiftCard={(giftCard) => handleChange({}, { value: giftCard, name:'gift_card' })} />
              {totalCost(true) > 0 &&
                <Payments booking user={seeker} updatePaymentMethod={pm => handleChange({}, { name: 'stripe_payment_method', value: pm })}/>
              }
              <div className="flex justify-center mt-8">
                <Popup
                  trigger={ 
                    <span>
                      <Button 
                        onClick={handleSubmit}
                        disabled={checkError(false)}
                        type="submit"
                      >
                          Submit
                      </Button>
                    </span>}
                  disabled={!checkError(false)}
                  size={'mini'}
                  wide={'very'}
                  position='top center'
                >
                    Please fill out All required fields
                </Popup>
              </div>
            </div>
            :
            <>
              <a href="#" onClick={() => setOpen(false)}>
                <FontAwesomeIcon icon={faArrowLeft} />
              </a>
              <div className="flex flex-col items-center text-center mb-8">
                <HeadingThree>Request Booking {asHelper ? `for ${seeker.first_name}` : `with ${helper.first_name}`}</HeadingThree>
                <p className="max-w-sm my-4">
                Please fill in the following information to ensure we provide the best help possible <br></br>
                </p>
              </div>
              <Form className="flex flex-col gap-8">
                <HeadingFive>Client Information</HeadingFive>
                {!asHelper &&
                  <div className="flex flex-col gap-3">
                    <Form.Field required error={errors.checkboxError}>
                      <label>Is the help for yourself or someone else?</label>
                    </Form.Field>
                    <Form.Checkbox
                      radio
                      label='For myself'
                      name='checkboxRadioGroup'
                      value='myself'
                      checked={data.help_for === 'myself'}
                      error={errors.help_for && {
                        content: errors.help_for,
                        pointing: 'left'
                      }}
                      onChange={(e) => {handleCheckboxChange(e, 'myself');}}
                    />
                    <Form.Checkbox
                      radio
                      label='For someone else'
                      name='checkboxRadioGroup'
                      value='someone else'
                      checked={data.help_for === 'someone else'}
                      onChange={(e) => {handleCheckboxChange(e, 'someone else');}}
                    />
                  </div>
                }
                {(data.help_for === 'someone else') ? 
                  <Form.Field required className="flex flex-col gap-3">
                    <label>
                    Client Information
                    </label>
                    <Form.Input
                      name="name_of_client"
                      placeholder="Name of client"
                      value={data.name_of_client}
                      error={errors.name_of_client}
                      onChange={(e) => {handleChange(e);}}
                      required
                    />
                    <Ref innerRef={measuredRef}>
                      <Form.Input
                        name="address"
                        placeholder="Address of client"
                        value={data.address}
                        onChange={handleAddressChange}
                        onBlur={handleAddressChange}
                        error={errors.address}
                        required
                      />
                    </Ref>
                    <Form.Input
                      name="phone_number"
                      placeholder="Phone number of client"
                      value={data.phone_number}
                      error={errors.phone_number}
                      onChange={(e) => {handleChange(e);}}
                      required
                    />
                  </Form.Field>
                  : 
                  <>
                    <div className="border border-ui-gray-100 flex flex-col gap-1 p-4 rounded-md">
                      <p>{seeker.first_name} {seeker.last_name}</p>
                      <p>{seeker.email}</p>
                      <p>{seeker.address}</p>
                    </div>
                    <Form.Field 
                      required 
                      style={{ paddingTop: '10px' }}
                    >
                      <label>
                      Phone number
                      </label>
                      <Form.Input
                        name="phone_number"
                        placeholder="Phone number"
                        value={data.phone_number}
                        onChange={(e) => {handleChange(e);}}
                        required
                        error={errors.phone_number}
                      />
                    </Form.Field>
                  </>
                }
                <Form.Field className="flex flex-col gap-3">
                  <label>Emergency Contact Info</label>
                  <Form.Input
                    name="name"
                    placeholder="Name of emergency contact"
                    value={data.emergency_contact && data.emergency_contact.name}
                    onChange={(e) => {handleChange(e, 'emergency');}}
                  />
                  <Form.Input
                    name="phone_number"
                    placeholder="Phone number of emergency contact"
                    value={data.emergency_contact && data.emergency_contact.phone_number}
                    onChange={(e) => {handleChange(e, 'emergency');}}
                  />
                  <Form.Input
                    name="relation"
                    placeholder="Relation to emergency contact"
                    value={data.emergency_contact && data.emergency_contact.relation}
                    onChange={(e) => {handleChange(e, 'emergency');}}
                  />
                </Form.Field>
                <hr/>
                <HeadingFive>Booking Information </HeadingFive>
                <Form.Field required error={errors.date}>
                  <label>When would you like to {asHelper ? 'schedule the booking?' : 'be visited?'}</label>
                  <DateTimePicker
                    onChange={handleDateChange}
                    value={data.date}
                    name="date"
                    error={errors.date}
                    required
                  />
                  {errors.date && 
                  <Message 
                    negative 
                    size="tiny"
                    style={{ padding: '7px', display: 'inline-block' }}
                  >
                    <strong>{errors.date}</strong>
                  </Message>
                  }
                </Form.Field>
                <Form.Field   required>
                  <label>Select the type(s) of help needed and their estimated time.</label>
                  <ServiceSelector
                    value={data.help_needed}
                    error={errors.help_needed}
                    name="help_needed"
                    onChange={changeHelpOptions} 
                    options={helpOptions} 
                  />
                </Form.Field>
                <Form.Field required>
                  <label>
                    { data.help_needed && data.help_needed.length !== 0 ? `Additional information about ${data.help_needed.join(', ')}` : 'Additional Information'}
                    <Popup
                      trigger={<Icon color="grey" name="question circle outline" />}
                      size={'small'}
                      wide={'very'}
                      position='top center'
                      flowing
                    >
                      <p><strong>Provide the following information for:</strong></p>
                      <p><strong>Light Cleaning</strong></p>
                      <ul className="list-disc ml-5">
                        <li>
                          Size of home
                        </li>
                        <li>
                          Confirm all cleaning supplies, equipment and cleaning solutions are on site
                        </li>
                        <li>
                          Exactly what needs cleaning: vacuum living room, 1 bathroom, mopping the kitchen, etc.                        </li>
                        <li>
                          NOTE: &apos;light housekeeping&apos; and senior home help only. &nbsp;Helpers are not professional cleaners &amp; do not do extensive cleaning.&nbsp;
                        </li>
                      </ul>
                      <p><strong>Driving</strong></p>
                      <ul className="list-disc ml-5">
                        <li>
                          Pickup Drop Off locations
                        </li>
                        <li>
                          Client needs to pay mileage of 55 cents per km and parking (if any)
                        </li>
                      </ul>
                      <p><strong>Errands</strong></p>
                      <ul className="list-disc ml-5">
                        <li>
                          Location and exact items&nbsp;
                        </li>
                        <li>
                          Ensure <strong>Pre-Paid</strong>, to save money and avoid extra 5% platform charge
                        </li>
                        <li>
                          Client needs to pay 55 cents per km plus parking
                        </li>
                      </ul>
                      <p><strong>Gardening</strong></p>
                      <ul className="list-disc ml-5">
                        <li>
                          Exactly what tasks are required
                        </li>
                        <li>
                          All garden tools, supplies, plantings are on site
                        </li>
                        <li>
                          Small tasks only. &nbsp;Helpers are not professional gardeners or landscapers
                        </li>
                      </ul>
                      <p><strong>Tech Help</strong></p>
                      <ul className="list-disc ml-5">
                        <li>
                          Confirm brand and make of computer, mobile phone, tablet, TV or remote.&nbsp;
                        </li>
                        <li>
                          Confirm exactly what issues or help is needed
                        </li>
                      </ul>
                    </Popup>
                    <br></br>
                    <small> Extra task details and any health concerns . . .</small>
                  </label>
                  <Form.TextArea
                    name="additional_info"
                    placeholder="Additional information..."
                    value={data.additional_info}
                    error={errors.additional_info}
                    onChange={(e) => {handleChange(e, 'additional_info');}}/>
                </Form.Field>
                <Form.Field>
                  <Form.Checkbox 
                    name="supplies"
                    label="I confirm that I have all supplies needed to complete the task(s)"
                    required
                    checked={data.supplies}
                    onClick={() => handleChange({}, { value: !data.supplies, name:'supplies' })}
                  />
                </Form.Field>
                <hr/>
                <HeadingFive>Estimated Total</HeadingFive>
                <div className="flex justify-between">
                  <div className="w-1/3 flex flex-col gap-2">
                    {servicesBreakdown()}
                    {
                      data.discount && <p className="text-btn-primary">{data.discount.name} : <strong>{data.discount.percent_off}% off</strong></p>
                    }
                    <hr/>
                    {
                      data.gift_card ?
                        <>
                          <p className="self-end"><s>${totalCost()}</s></p>
                          <p className="text-btn-primary flex justify-between"><span>Gift Card :</span> <strong> - ${Math.min(data.cost * 100, data.gift_card.remaining_balance)/100}</strong></p>
                          <hr/>
                          <p className="self-end">${totalCost(true)}</p>
                        </>
                        :
                        <p className="self-end">${totalCost()}</p>
                    }
                  </div>
                  <Message success className="visible mt-0 w-1/2" size="tiny">
                  Please note: 
                    <ul className="list-disc px-3">
                      <li>
                      Visits that <strong>exceed estimated time</strong> will be charged an <strong>extra task fee</strong> for each additional half hour.
                      </li>
                      { 
                        data.help_needed && data.help_needed.includes('Driving') &&
                        <li>
                          <strong>For driving</strong>, there will be an <strong>additional charge of $0.55/km.</strong> Clients are responsible for any parking costs.
                        </li>
                      }
                    </ul>
                  </Message>
                </div>
                <PromotionCode setCoupon={(coupon) => handleChange({}, { value: coupon, name:'discount' })} />              </Form>
              <div className="flex justify-center mt-8">
                {asHelper || (currentUser && currentUser.batch_invoices) ?
                  <Popup
                    trigger={ 
                      <span>
                        <Button 
                          onClick={handleSubmit}
                          disabled={waiting || checkError(false)}
                          type="submit"
                        >
                          { waiting ? <><Loader size="small"/>&nbsp;</> : 'Submit'}
                        </Button>
                      </span>}
                    disabled={!checkError(false)}
                    size={'mini'}
                    wide={'very'}
                    position='top center'
                  >
                  Please fill out All required fields
                  </Popup>
                  :
                  <Button 
                    onClick={() => setPaymentStep(true)}
                    disabled={checkError(false)}
                    type="button"
                  >
                  Next
                  </Button>
                }
              </div>
            </>
          }
        </ModalContainer>
      </Modal>
  );
}

export default HelperBookingModal;
