import React from 'react';
import { DesktopSpecificContent, MobileSpecificContent } from 'components/common/Layout';
import { HeadingFive, HeadingSix, TextBodyXLg } from 'components/common/Textbox';

const HelperProfileInformationSection = ({ heading, subHeading }) => {
  return (
    <div>
      <DesktopSpecificContent>
        <HeadingFive>{heading}</HeadingFive>
      </DesktopSpecificContent>
      <MobileSpecificContent>
        <HeadingSix>{heading}</HeadingSix>
      </MobileSpecificContent>
      <div className="text-text-gray-80 pt-2">
        <DesktopSpecificContent>
          <TextBodyXLg>{subHeading}</TextBodyXLg>
        </DesktopSpecificContent>
        <MobileSpecificContent>
          <div className="text-base leading-6">{subHeading}</div>
        </MobileSpecificContent>
      </div>
    </div>
  );
};

export default HelperProfileInformationSection;
