import { useState } from 'react';

/**
 * Reusable Hook to control accordion indexes in real time
 *  
 * @returns accordion state and state-modifying function
 */
const useAccordionIndex = () => {
  const [accordionIndex, setAccordionIndex] = useState(-1);

  const proposeAccordionIndex = (proposedIndex) => {
    // attempting to re-select an accordion index pulls all items into closed state
    accordionIndex == proposedIndex ? setAccordionIndex(-1) : setAccordionIndex(proposedIndex);
  };

  return [accordionIndex, proposeAccordionIndex];
};

export default useAccordionIndex;
