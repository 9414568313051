import React from 'react';
import Container from './Container';

// A consistent horizontal rule that abides to container spacing (app specific per-section margins)
const HorizontalRule = () => {
  return (
    <Container>
      {/* <div className="flex divide-y-2 divide-gray-400" /> */}
      <hr className="border-t-2 border-gray-300" />
    </Container>
  );
};

export default HorizontalRule;
