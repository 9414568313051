import React from 'react';
import { PrimaryHeading, SubHeading } from 'components/common/Textbox';


export default function JoinInfo({ img, title, subtitle, quote, right }) {
  return (
    <div className={`flex py-16 gap-16 items-center ${right && 'flex-row-reverse'} sm:flex-col`}>
      <div className={`flex flex-col ${right ? 'items-end text-right' : 'items-start'} sm:items-center sm:text-center`}>
        <img src={img}/>
        <br></br>
        <PrimaryHeading>{title}</PrimaryHeading>
        <SubHeading>{subtitle}</SubHeading>
      </div>
      <div className="bg-ui-gray-20 p-10 flex flex-col items-end">
        <p className="font-semibold">&quot;{quote.text}&quot;</p>
        <strong>{quote.author}</strong>
        <p>{quote.title}</p>
      </div>
    </div>
  );
}
